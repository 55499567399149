// src/constants/sidebarItems.ts
// import { TbLayoutDashboard } from "react-icons/tb";
import { MdMenuBook } from "react-icons/md";
import { TfiReceipt } from "react-icons/tfi";
import { MdOutlineChair } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { PiLifebuoy } from "react-icons/pi";
import { IoReceiptOutline } from "react-icons/io5";
import { ReactElement } from "react";
import { VscFeedback } from "react-icons/vsc";
// import { BiDish } from "react-icons/bi";

// export const sidebarItems = [
//   // {
//   //   icon: <TbLayoutDashboard size={20} />,
//   //   text: "Dashboard",
//   //   alert: false,
//   //   active: false,
//   //   to: RouterPaths.DASHBOARD,
//   // },
//   {
//     icon: <MdMenuBook size={20} />,
//     text: "Menu",
//     alert: false,
//     active: false,
//     to: RouterPaths.MENU,
//   },
//   // {
//   //   icon: <BiDish size={20} />,
//   //   text: "Add Order",
//   //   alert: false,
//   //   active: false,
//   //   to: RouterPaths.ADD_ORDER,
//   // },
//   {
//     icon: <TfiReceipt size={20} />,
//     text: "Orders",
//     alert: true,
//     active: false,
//     to: RouterPaths.ORDERS,
//   },
//   {
//     icon: <MdOutlineChair size={20} />,
//     text: "Table",
//     alert: false,
//     active: false,
//     to: RouterPaths.TABLE,
//   },
//   {
//     icon: <IoReceiptOutline size={20} />,
//     text: "Bills",
//     alert: false,
//     active: false,
//     to: RouterPaths.BILLS,
//   },
//   {
//     isDivider: true, // use this property to indicate a divider
//     to: "/#",
//   },
//   {
//     icon: <IoSettingsOutline size={20} />,
//     text: "Settings",
//     alert: false,
//     active: false,
//     to: RouterPaths.SETTINGS,
//   },
//   {
//     icon: <PiLifebuoy size={20} />,
//     text: "Help",
//     alert: false,
//     active: false,
//     to: RouterPaths.HELP,
//   },
// ];
type SidebarIconMap = {
  [key: string]: ReactElement;
};

export const sidebarIconMap: SidebarIconMap = {
  Menu: <MdMenuBook size={20} />,
  Orders: <TfiReceipt size={20} />,
  Table: <MdOutlineChair size={20} />,
  Bills: <IoReceiptOutline size={20} />,
  Settings: <IoSettingsOutline size={20} />,
  Help: <PiLifebuoy size={20} />,
  Feedback: <VscFeedback size={20} />,
};
