import { Edit } from "lucide-react";
import { Switch } from "../ui/switch";
import { Card } from "../ui/card";
import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "@/hooks/useAxios"; // Adjust path as per your project structure
import { AxiosError } from "axios";
import { capitalizeFirstLetterOfEachWord, parseAxiosError } from "@/utils/common"; // Adjust path as per your project structure
import { toast } from "sonner";
import { ReactQueryEnum } from "@/constans/enum";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { RouterPaths } from "@/types/RouterPaths";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import clsx from "clsx";

interface MenuDishCardProps {
  name: string;
  price: number;
  imgUrl: string;
  available: boolean;
  dishId: string;
  timeAvailability: boolean;
}

export default function MenuDishCard({
  name,
  price,
  imgUrl,
  available,
  dishId,
  timeAvailability,
}: MenuDishCardProps) {
  const [toggle, setToggle] = useState(available);
  const queryClient = useQueryClient();
  const { patchWithAuth } = useAxios(); // Assuming useAxios is correctly defined in your custom hook
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: async ({ available }: { available: boolean }) => {
      const response = await patchWithAuth("/dish/change-dish-availability", {
        dishId,
        availability: available,
      });
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage); // Ensure toast.error is used for error messages
    },
    onSuccess: () => {
      toast.success(`${name} is now ${toggle ? "available" : "unavailable"}`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.MENU] });
    },
  });

  const debouncedMutate = useCallback(
    debounce((newAvailable: boolean) => {
      mutate({ available: newAvailable });
    }, 1000),
    [mutate],
  );

  const handleToggle = () => {
    const newToggleState = !toggle;
    setToggle(newToggleState);
    debouncedMutate(newToggleState);
  };

  const altUrl =
    "https://media.self.com/photos/57d8952946d0cb351c8c50c9/master/w_1600%2Cc_limit/DELICIOUS-1-POT-Lentil-and-Black-Bean-Chili-Smoky-hearty-PROTEIN-and-fiber-packed-vegan-glutenfree-lentils-chili-healthy-recipe2.jpg";

  return (
    <Card className={clsx("flex flex-col w-full p-3", {})}>
      <div className="flex gap-0">
        <Avatar className="rounded-lg w-12 h-12 object-cover shadow-sm">
          <AvatarImage
            className="rounded-lg w-12 h-12 object-cover"
            src={imgUrl ? imgUrl : altUrl}
            alt={name}
          />
          <AvatarFallback className="rounded-lg w-12 h-12 object-contain text-xs p-1 text-center text-muted-foreground z-0">
            Upload Image
          </AvatarFallback>
        </Avatar>
        <div className="pl-2 leading-tight text-left">
          <p className="">{capitalizeFirstLetterOfEachWord(name)}</p>
          {!timeAvailability && <p className="text-xs text-primary">Unavailable Now</p>}
        </div>
      </div>
      <div className="flex justify-between items-center pt-2">
        <div className="flex justify-center items-center gap-2">
          <Switch checked={toggle} onCheckedChange={handleToggle} />

          <Edit
            size={16}
            className="hover:cursor-pointer"
            onClick={() => {
              navigate(RouterPaths.EDIT_DISH, {
                state: { dishId: dishId },
              });
            }}
          />
        </div>
        <div>
          <span>
            <span>&#8377;</span>
            {price}
          </span>
        </div>
      </div>
    </Card>
  );
}
