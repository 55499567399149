import { useEffect, useState } from "react";
import { Separator } from "../ui/separator";
import { Link } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import {
  FileInput,
  FileUploader,
  // FileUploaderContent,
  // FileUploaderItem,
} from "../extension/FileUploader";
import { DropzoneOptions } from "react-dropzone";
import { Input } from "../ui/input";
import MultipleSelector, { Option } from "../extension/MultiSelect";
import { cuisine_list } from "@/constans/cuisine";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Switch } from "../ui/switch";
import {
  DayOfWeek,
  IAmenities,
  OpeningHours,
  OpeningHoursDate,
  RestaurantType,
} from "@/interfaces/Restaurant.interface";
import {
  capitalizeFirstLetter,
  convertTimeStringToDate,
  createInitialTime,
  parseAxiosError,
} from "@/utils/common";
import { TimePicker } from "../common/TimePicker";
import clsx from "clsx";
// import { Card } from "../ui/card";
// import { MdDeleteForever } from "react-icons/md";
// import { FileSvgDraw } from "../common/FileDrawSvg";
// import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ReactQueryEnum } from "@/constans/enum";
import useAxios from "@/hooks/useAxios";
import { toast } from "sonner";
import Loading from "../common/Loading";

interface editRestaurantProps {
  name: string;
  phoneNo: string;
  email: string;
  street: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  cuisine: string[];
  amenities: IAmenities;
  pureVeg: boolean;
  openingHours: OpeningHoursDate;
  workingDays: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
}

const RestaurantSettings = () => {
  const [files, setFiles] = useState<File[] | null>([]);
  const [cuisines, setCuisines] = useState<Option[]>([]);
  const [differentTimings, setDifferentTimings] = useState<boolean>(false);
  const { getWithAuth, patchWithAuth } = useAxios();
  const [pureVeg, setPureVeg] = useState<boolean>(false);
  const [workingHours, setWorkingHours] = useState<OpeningHoursDate>({
    monday: { open: createInitialTime(6, 1), close: createInitialTime(23, 59) },
    tuesday: { open: createInitialTime(6, 0), close: createInitialTime(23, 59) },
    wednesday: { open: createInitialTime(6, 0), close: createInitialTime(23, 59) },
    thursday: { open: createInitialTime(6, 0), close: createInitialTime(23, 59) },
    friday: { open: createInitialTime(6, 0), close: createInitialTime(23, 59) },
    saturday: { open: createInitialTime(6, 0), close: createInitialTime(23, 59) },
    sunday: { open: createInitialTime(6, 0), close: createInitialTime(23, 59) },
  });

  const [consistentOpenTimings, setConsistentOpenTimings] = useState<Date>(createInitialTime(6, 1));
  const [consistentCloseTimings, setConsistentCloseTimings] = useState<Date>(
    createInitialTime(23, 59),
  );
  const [logoUrl, setLogoUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [logoUploadLoading, setLogoUploadLoading] = useState(false);
  const [workingDays, setWorkingDays] = useState<{
    [key in DayOfWeek]: boolean;
  }>({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  });

  const {
    data: restaurantDetails,
    isError: isRestaurantError,
    isLoading: isRetaurantLoading,
    error,
  } = useQuery<RestaurantType, AxiosError>({
    queryKey: [ReactQueryEnum.RESTAURANT],
    queryFn: async () => {
      const response = await getWithAuth("/restaurant/restaurant");
      return response.data;
    },
  });
  const queryClient = useQueryClient();
  const { mutate: updateRestaurantMutate } = useMutation({
    mutationFn: async ({
      name,
      phoneNo,
      email,
      street,
      country,
      state,
      city,
      postalCode,
      cuisine,
      amenities,
      pureVeg,
      workingDays,
    }: editRestaurantProps) => {
      setLoading(true);
      const updatedWorkingHours = convertOpeningHoursToString();
      const updatedWorkingDays = Object.keys(workingDays).filter(
        (day) => workingDays[day as keyof typeof workingDays],
      );
      const body = {
        name,
        phoneNo,
        email,
        address: {
          street,
          country,
          state,
          city,
          postalCode,
        },
        cuisine,
        amenities,
        pureVeg,
        openingHours: updatedWorkingHours,
        workingDays: updatedWorkingDays,
      };
      const response = await patchWithAuth("/restaurant/update-restaurant", body);
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast(errorMessage);
    },
    onSuccess: () => {
      toast(`Restaurant updated successfully`);

      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.RESTAURANT] });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: logoMutate } = useMutation({
    mutationFn: async ({ file }: { file?: File | null }) => {
      if (file) {
        setLogoUploadLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        const response = await patchWithAuth("/restaurant/update-logo", formData);
        return response;
      }
      const formData = new FormData();
      const response = await patchWithAuth("/restaurant/update-logo", formData);
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast(errorMessage);
    },
    onSuccess: () => {
      toast(`Logo updated successfully`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.RESTAURANT] });
    },
    onSettled: () => {
      setLogoUploadLoading(false);
    },
  });

  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const convertOpeningHoursToString = (): OpeningHours => {
    if (differentTimings) {
      return {
        monday: workingHours.monday
          ? {
              open: formatTime(workingHours.monday.open),
              close: formatTime(workingHours.monday.close),
            }
          : { open: "00:00", close: "00:00" },
        tuesday: workingHours.tuesday
          ? {
              open: formatTime(workingHours.tuesday.open),
              close: formatTime(workingHours.tuesday.close),
            }
          : { open: "00:00", close: "00:00" },
        wednesday: workingHours.wednesday
          ? {
              open: formatTime(workingHours.wednesday.open),
              close: formatTime(workingHours.wednesday.close),
            }
          : { open: "00:00", close: "00:00" },
        thursday: workingHours.thursday
          ? {
              open: formatTime(workingHours.thursday.open),
              close: formatTime(workingHours.thursday.close),
            }
          : { open: "00:00", close: "00:00" },
        friday: workingHours.friday
          ? {
              open: formatTime(workingHours.friday.open),
              close: formatTime(workingHours.friday.close),
            }
          : { open: "00:00", close: "00:00" },
        saturday: workingHours.saturday
          ? {
              open: formatTime(workingHours.saturday.open),
              close: formatTime(workingHours.saturday.close),
            }
          : { open: "00:00", close: "00:00" },
        sunday: workingHours.sunday
          ? {
              open: formatTime(workingHours.sunday.open),
              close: formatTime(workingHours.sunday.close),
            }
          : { open: "00:00", close: "00:00" },
      };
    } else {
      const open = formatTime(consistentOpenTimings);
      const close = formatTime(consistentCloseTimings);
      return {
        monday: {
          open: open,
          close: close,
        },
        tuesday: {
          open: open,
          close: close,
        },
        wednesday: {
          open: open,
          close: close,
        },
        thursday: {
          open: open,
          close: close,
        },
        friday: {
          open: open,
          close: close,
        },
        saturday: {
          open: open,
          close: close,
        },
        sunday: {
          open: open,
          close: close,
        },
      };
    }
  };

  const [amenities, setAmenities] = useState<IAmenities>({
    acAvailable: false,
    parkingAvailable: false,
    valetParking: false,
    kidsPlayArea: false,
    privateDining: false,
    outdoorSeating: false,
    restrooms: false,
    rooftopDining: false,
  });

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .max(20, "Name should be less than 20 characters"),
    phoneNo: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Enter a valid phone number"),
    email: yup.string().email("Enter a valid email address"),
    street: yup.string().required("Street is required"),
    country: yup.string().required("Country is required"),
    state: yup.string().required("State is required"),
    city: yup.string().required("City is required"),
    postalCode: yup.string().required("Postal code is required"),
  });

  const restaurantFormik = useFormik({
    initialValues: {
      name: "",
      phoneNo: "",
      email: "",
      street: "",
      country: "india",
      state: "tamil nadu",
      city: "coimbatore",
      postalCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateRestaurantMutate({
        name: values.name,
        phoneNo: values.phoneNo,
        email: values.email,
        street: values.street,
        country: values.country,
        state: values.state,
        city: values.city,
        postalCode: values.postalCode,
        cuisine: cuisines.map((cuisine) => cuisine.value),
        amenities: amenities,
        pureVeg: pureVeg,
        openingHours: workingHours,
        workingDays: workingDays,
      });
      if (files && files[0]) {
        logoMutate({ file: files[0] });
      }
    },
  });

  const dropZoneConfig = {
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    multiple: false,
    maxFiles: 1,
    maxSize: 1 * 1024 * 1024,
  } satisfies DropzoneOptions;

  // const galleryDropZoneConfig = {
  //   accept: {
  //     "image/*": [".jpg", ".jpeg", ".png"],
  //   },
  //   multiple: false,
  //   maxFiles: 3,
  //   maxSize: 1 * 1024 * 1024,
  // } satisfies DropzoneOptions;

  useEffect(() => {
    if (restaurantDetails) {
      restaurantFormik.setValues({
        name: restaurantDetails.name,
        phoneNo: restaurantDetails.phoneNo,
        email: restaurantDetails.email,
        street: restaurantDetails.address.street,
        country: restaurantDetails.address.country,
        state: restaurantDetails.address.state,
        city: restaurantDetails.address.city,
        postalCode: restaurantDetails.address.postalCode,
      });
      const days: DayOfWeek[] = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
      const compareTimings = (openingHours: OpeningHours): boolean => {
        // Find the first day's hours
        const firstDay = openingHours[days[0]];

        if (!firstDay) {
          setDifferentTimings(true);
          return false; // Return false if the first day's hours are not available
        }

        const { open: firstOpen, close: firstClose } = firstDay;

        // Check if all days have the same opening and closing times
        const isConsistent = days.every((day) => {
          const dayHours = openingHours[day];
          return dayHours && dayHours.open === firstOpen && dayHours.close === firstClose;
        });

        if (isConsistent) {
          setDifferentTimings(false);
          setConsistentOpenTimings(convertTimeStringToDate(firstOpen));
          setConsistentCloseTimings(convertTimeStringToDate(firstClose));
        } else {
          setDifferentTimings(true);
        }

        return isConsistent; // Return whether the timings are consistent
      };

      // Usage example:
      let isTimingsConsistent = compareTimings(restaurantDetails.openingHours);
      if (restaurantDetails.workingDays.length != 7) {
        isTimingsConsistent = false;
        setDifferentTimings(true);
      }
      if (!isTimingsConsistent) {
        const workingHours: OpeningHoursDate = {
          monday: restaurantDetails.openingHours.monday
            ? {
                open: convertTimeStringToDate(restaurantDetails.openingHours.monday.open),
                close: convertTimeStringToDate(restaurantDetails.openingHours.monday.close),
              }
            : { open: new Date(0), close: new Date(0) }, // Default values
          tuesday: restaurantDetails.openingHours.tuesday
            ? {
                open: convertTimeStringToDate(restaurantDetails.openingHours.tuesday.open),
                close: convertTimeStringToDate(restaurantDetails.openingHours.tuesday.close),
              }
            : { open: new Date(0), close: new Date(0) }, // Default values
          wednesday: restaurantDetails.openingHours.wednesday
            ? {
                open: convertTimeStringToDate(restaurantDetails.openingHours.wednesday.open),
                close: convertTimeStringToDate(restaurantDetails.openingHours.wednesday.close),
              }
            : { open: new Date(0), close: new Date(0) }, // Default values
          thursday: restaurantDetails.openingHours.thursday
            ? {
                open: convertTimeStringToDate(restaurantDetails.openingHours.thursday.open),
                close: convertTimeStringToDate(restaurantDetails.openingHours.thursday.close),
              }
            : { open: new Date(0), close: new Date(0) }, // Default values
          friday: restaurantDetails.openingHours.friday
            ? {
                open: convertTimeStringToDate(restaurantDetails.openingHours.friday.open),
                close: convertTimeStringToDate(restaurantDetails.openingHours.friday.close),
              }
            : { open: new Date(0), close: new Date(0) }, // Default values
          saturday: restaurantDetails.openingHours.saturday
            ? {
                open: convertTimeStringToDate(restaurantDetails.openingHours.saturday.open),
                close: convertTimeStringToDate(restaurantDetails.openingHours.saturday.close),
              }
            : { open: new Date(0), close: new Date(0) }, // Default values
          sunday: restaurantDetails.openingHours.sunday
            ? {
                open: convertTimeStringToDate(restaurantDetails.openingHours.sunday.open),
                close: convertTimeStringToDate(restaurantDetails.openingHours.sunday.close),
              }
            : { open: new Date(0), close: new Date(0) }, // Default values
        };
        setWorkingHours(workingHours);
      }
      setAmenities(restaurantDetails.amenities);
      setPureVeg(restaurantDetails.pureVeg);
      setCuisines(
        restaurantDetails.cuisine.map((cuisine) => {
          const foundCuisine = cuisine_list.find((c) => c.value === cuisine);
          return foundCuisine ? foundCuisine : { label: cuisine, value: cuisine };
        }),
      );
      days.forEach((day) => {
        if (restaurantDetails.workingDays.includes(day)) {
          setWorkingDays((prevState) => ({ ...prevState, [day]: true }));
        } else {
          setWorkingDays((prevState) => ({ ...prevState, [day]: false }));
        }
      });
      setLogoUrl(restaurantDetails.logoUrl);
    }
  }, [restaurantDetails]);

  if (isRestaurantError) {
    const { errorMessage } = parseAxiosError(error);
    toast.error(errorMessage);
  }

  if (isRetaurantLoading) return <Loading />;

  return (
    <div className="">
      <div>
        <h3 className="text-lg font-medium">Restaurant</h3>
        <p className="text-sm text-muted-foreground">
          This is how customers will see your restaurant on the{" "}
          <Link to="https://order.ciboapp.tech" target="_blank" className="text-primary underline">
            customer app.
          </Link>
        </p>
      </div>
      <Separator />
      <div className="flex flex-col gap-5  max-w-[1000px]">
        <div className="flex gap-10 justify-start items-center mt-4 flex-wrap">
          <Avatar className="h-28 w-28 ">
            <AvatarImage
              src={files && files[0] ? URL.createObjectURL(files[0]) : logoUrl || ""}
              className="object-cover"
            />
            <AvatarFallback className="text-base text-center border  ">
              Restaurant logo
            </AvatarFallback>
          </Avatar>
          <div className=" flex gap-4 flex-wrap">
            <FileUploader
              className=" w-fit"
              value={files}
              onValueChange={setFiles}
              dropzoneOptions={dropZoneConfig}
            >
              <FileInput className="">
                <Button variant="default" className="" isLoading={logoUploadLoading}>
                  Upload Logo
                </Button>
              </FileInput>
            </FileUploader>
          </div>
        </div>
        <form
          onSubmit={restaurantFormik.handleSubmit}
          className="flex flex-col gap-5  max-w-[1000px]"
        >
          <div className="relative border rounded-md p-3">
            <p className="bg-card absolute -top-3 left-3">Restaurant Details</p>
            <div className="flex gap-2 w-full flex-wrap">
              <div className="flex-grow min-w-[200px] max-w-[50%]">
                <p>Name</p>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Restaurant Name"
                  value={restaurantFormik.values.name}
                  onChange={restaurantFormik.handleChange}
                />
                {restaurantFormik.errors.name && (
                  <p className="text-red-500 text-sm">*{restaurantFormik.errors.name}</p>
                )}
              </div>
              <div className="flex-grow min-w-[200px] max-w-[50%]">
                <p>Phone Number</p>
                <div className="flex items-center">
                  <span className="mr-2">+91</span>
                  <Input
                    id="phoneNo"
                    name="phoneNo"
                    type="tel"
                    placeholder="Phone Number"
                    maxLength={10}
                    required
                    className="flex-grow"
                    value={restaurantFormik.values.phoneNo}
                    onChange={restaurantFormik.handleChange}
                  />
                  {restaurantFormik.errors.phoneNo && (
                    <p className="text-red-500 text-sm">*{restaurantFormik.errors.phoneNo}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-2 w-full flex-wrap mt-2">
              <div className="flex-grow min-w-[200px] max-w-[50%]">
                <p>Email ID</p>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Restaurant Email ID"
                  value={restaurantFormik.values.email}
                  onChange={restaurantFormik.handleChange}
                />
                {restaurantFormik.errors.email && (
                  <p className="text-red-500 text-sm">*{restaurantFormik.errors.email}</p>
                )}
              </div>
              <div className="flex-grow min-w-[200px] max-w-[50%]">
                <p>Cuisine</p>
                <MultipleSelector
                  value={cuisines}
                  onChange={setCuisines}
                  placeholder="Restaurant Cuisine"
                  options={cuisine_list}
                  badgeClassName="bg-secondary text-secondary-foreground select-none"
                  emptyIndicator={
                    <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                      no results found.
                    </p>
                  }
                />
              </div>
            </div>
            <div className="flex items-center space-x-2 mt-2">
              <Switch
                id="pure-veg"
                checked={pureVeg}
                onCheckedChange={(checked) => setPureVeg(checked)}
              />
              <p>Pure Veg</p>
            </div>
          </div>
          <div className="relative border rounded-md p-3">
            <p className="bg-card absolute -top-3 left-3">Address</p>
            <p className="bg-card absolute top-3 left-3">Street</p>
            <div className="">
              <p>Street</p>
              <Input
                id="street"
                name="street"
                type="text"
                placeholder="Street"
                value={restaurantFormik.values.street}
                onChange={restaurantFormik.handleChange}
              />
              {restaurantFormik.errors.street && (
                <p className="text-red-500 text-sm">*{restaurantFormik.errors.street}</p>
              )}
            </div>
            <div className="flex gap-2">
              <div className="basis-1/2">
                <p>Country </p>
                <Select
                  value={restaurantFormik.values.country}
                  onValueChange={(value) => restaurantFormik.setFieldValue("country", value)}
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Select country" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="india">India</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
                {restaurantFormik.errors.country && (
                  <p className="text-red-500 text-sm">*{restaurantFormik.errors.country}</p>
                )}
              </div>
              <div className="basis-1/2">
                <p>State</p>
                <Select
                  value={restaurantFormik.values.state}
                  onValueChange={(value) => restaurantFormik.setFieldValue("state", value)}
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Select state" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="tamil nadu">Tamil Nadu</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
                {restaurantFormik.errors.state && (
                  <p className="text-red-500 text-sm">*{restaurantFormik.errors.state}</p>
                )}
              </div>
            </div>

            <div className="flex gap-2">
              <div className="basis-1/2">
                <p>City</p>
                <Select
                  value={restaurantFormik.values.city}
                  onValueChange={(value) => restaurantFormik.setFieldValue("city", value)}
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Select city" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="coimbatore">Coimbatore</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
                {restaurantFormik.errors.city && (
                  <p className="text-red-500 text-sm">*{restaurantFormik.errors.city}</p>
                )}
              </div>
              <div className="basis-1/2">
                <p>Postal Code </p>
                <Input
                  id="postalCode"
                  name="postalCode"
                  type="text"
                  placeholder="Postal Code"
                  value={restaurantFormik.values.postalCode}
                  onChange={restaurantFormik.handleChange}
                />
                {restaurantFormik.errors.postalCode && (
                  <p className="text-red-500 text-sm">*{restaurantFormik.errors.postalCode}</p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-2 border rounded-md relative">
            <p className="bg-card absolute -top-3 left-3 ">Working Hours</p>

            <div className="grid grid-cols-1 gap-5  px-3 py-5 mt-2">
              <div className="flex gap-2 items-center">
                <Switch
                  checked={differentTimings}
                  onCheckedChange={(checked) => setDifferentTimings(checked)}
                />
                <p className="text-lg text-muted-foreground font-medium">Different Timings</p>
              </div>
              {!differentTimings && (
                <div
                  key={"different"}
                  className="flex flex-col gap-2 border rounded-md p-3 relative"
                >
                  <div className=" absolute -top-3.5 left-3 bg-card flex gap-2 justify-center items-center">
                    <p className="text-lg text-muted-foreground font-medium">Timings</p>
                  </div>
                  <div
                    className={clsx(
                      "  flex justify-start items-center flex-wrap gap-2  md:gap-10",
                      {},
                    )}
                  >
                    <div className="">
                      <div className="">
                        <p className="text-muted-foreground">From</p>
                        <TimePicker
                          date={consistentOpenTimings}
                          setDate={(date) => {
                            if (date) setConsistentOpenTimings(date);
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <p className="text-muted-foreground">To</p>
                        <TimePicker
                          date={consistentCloseTimings}
                          setDate={(date) => {
                            if (date) setConsistentCloseTimings(date);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {differentTimings &&
                Object.entries(workingHours).map(([day, { open, close }]) => (
                  <div key={day} className="flex flex-col gap-2 border rounded-md p-3 relative">
                    <div className=" absolute -top-3.5 left-3 bg-card flex gap-2 justify-center items-center">
                      <p className="text-lg text-muted-foreground font-medium">
                        {capitalizeFirstLetter(day)}
                      </p>
                      <Switch
                        checked={workingDays[day as keyof typeof workingDays]}
                        onCheckedChange={(checked) =>
                          setWorkingDays((prevState) => ({ ...prevState, [day]: checked }))
                        }
                      />
                    </div>
                    <div
                      className={clsx(
                        "  flex justify-start items-center flex-wrap gap-2  md:gap-10",
                        {
                          "pointer-events-none opacity-50 select-none":
                            !workingDays[day as keyof typeof workingDays],
                        },
                      )}
                    >
                      <div className="">
                        <div className="">
                          <p className="text-muted-foreground">From</p>
                          <TimePicker
                            date={open}
                            setDate={(date) =>
                              setWorkingHours((prevState) => ({
                                ...prevState,
                                [day]: { ...prevState[day as keyof OpeningHours], open: date },
                              }))
                            }
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          <p className="text-muted-foreground">To</p>
                          <TimePicker
                            date={close}
                            setDate={(date) =>
                              setWorkingHours((prevState) => ({
                                ...prevState,
                                [day]: { ...prevState[day as keyof OpeningHours], close: date },
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="relative border rounded-md p-3">
            <p className="bg-card absolute -top-3 left-3">Amenities</p>
            <div className="flex gap-3 flex-wrap">
              {Object.entries(amenities).map(([key, value]) => (
                <div key={key} className="flex items-center gap-2 min-w-[160px]">
                  <Switch
                    checked={value}
                    onCheckedChange={(checked) =>
                      setAmenities((prevState) => ({ ...prevState, [key]: checked }))
                    }
                  />
                  <p>{capitalizeFirstLetter(key)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <Button
              className="mt-4 w-[40%] max-w-[500px]"
              variant="default"
              type="submit"
              isLoading={loading}
            >
              Update Details
            </Button>
          </div>
        </form>

        {/* <div className="w-full max-h-[300px] overflow-auto">
          <Map />
        </div> */}
      </div>
      {/* <div className="mt-5 border rounded-md relative p-3 py-5 max-w-[1000px]">
        <p className="bg-card absolute -top-3 left-3 ">Gallery</p>
        <div className="flex gap-4 flex-wrap">
          {Array.from({ length: 3 }).map((_, index) => (
            <Card key={index} className="h-[200px] aspect-square relative">
              <img
                // src="https://g1.img-dpreview.com/5E2188B2342D42BB97B53F87A7083058.jpg"
                src="https://cibo-media.s3.ap-south-1.amazonaws.com/dish-images/669908d1f09d66f9a13fc7e4.png"
                alt=""
                className="aspect-square object-contain"
              />

              <Popover>
                <PopoverTrigger className="cursor-pointer bg-red-600 hover:bg-red-500 p-1 rounded-md absolute top-1 right-1 shadow-md">
                  <MdDeleteForever className="h-5 w-5  text-primary-foreground " />
                </PopoverTrigger>
                <PopoverContent className="w-[380px]">
                  <div className="flex flex-col justify-center items-center gap-2">
                    <p className="mx-auto">
                      Are you sure you want to{" "}
                      <span className="text-red-500 font-medium">DELETE</span> the image?
                    </p>
                    <Button variant="destructive" className=" mx-auto">
                      Delete
                    </Button>
                  </div>
                </PopoverContent>
              </Popover>
            </Card>
          ))}
          <p>3/3 Photos Uploaded</p>
        </div>
        <p>20/20 Photos Uploaded</p>
        <div className="mt-2">
          <p>Upload Restaurant Image</p>
          <FileUploader
            className="mt-1"
            value={gallery}
            onValueChange={setGallery}
            dropzoneOptions={galleryDropZoneConfig}
          >
            <div className="">
              <FileInput className="border">
                <div className="flex items-center justify-center flex-col pt-3 pb-4 w-full ">
                  <FileSvgDraw />
                </div>
              </FileInput>
            </div>
            <FileUploaderContent className="flex items-center flex-row gap-2">
              {gallery?.map((file, i) => (
                <FileUploaderItem
                  key={i}
                  index={i}
                  className="size-20 p-0 rounded-md overflow-hidden"
                  aria-roledescription={`file ${i + 1} containing ${file.name}`}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    height={80}
                    width={80}
                    className="size-20 p-0"
                  />
                </FileUploaderItem>
              ))}
            </FileUploaderContent>
          </FileUploader>
        </div>
      </div> */}
    </div>
  );
};

export default RestaurantSettings;
