import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { LocalStorageEnum } from "@/constans/enum";
import { useNavigate } from "react-router-dom";
import { RouterPaths } from "@/types/RouterPaths";

type RequestMethod =
  | "GET"
  | "GET_WITHOUT_AUTH"
  | "POST"
  | "POST_WITHOUT_AUTH"
  | "PUT"
  | "PATCH"
  | "PATCH_WITHOUT_AUTH"
  | "DELETE";

export interface IHttpResponse {
  data: any;
}

function getAuthToken(): string | null {
  const authStorage: string = localStorage.getItem(LocalStorageEnum.AUTH_STORAGE) ?? "";
  const { state } = JSON.parse(authStorage);
  return state?.authToken ?? null;
}

function clearTokens() {
  localStorage.removeItem(LocalStorageEnum.AUTH_STORAGE);
  // Add any additional token clearing logic if necessary
}

export default function useAxios() {
  const navigate = useNavigate();

  const baseURL: string = import.meta.env.VITE_NIGHT_FALL_HOST;

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "",
    },
  });

  axiosInstance.interceptors.request.use((config) => {
    const authToken = getAuthToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    async (response) => {
      return response;
    },
    function (error: AxiosError) {
      if (error.response && error.response.status === 401) {
        navigate(RouterPaths.LOGIN);
        clearTokens(); // Clear tokens on 401 Unauthorized responses
      }
      return Promise.reject(error);
    },
  );

  async function request(method: RequestMethod, endpoint = "", body = {}): Promise<any> {
    const url = `${baseURL}${endpoint}`;
    let data;

    const isFormData = body instanceof FormData;

    const config: AxiosRequestConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
        Authorization: "",
      },
    };

    const reqBody = isFormData
      ? body
      : method !== "GET" && method !== "GET_WITHOUT_AUTH" && JSON.stringify(body);

    if (method === "GET") {
      data = await axiosInstance.get(url, { params: body });
    } else if (method === "GET_WITHOUT_AUTH") {
      data = await axios.get(url, { params: body });
    } else if (method === "DELETE") {
      data = await axiosInstance.delete(url);
    } else if (method === "POST") {
      data = await axiosInstance.post(url, reqBody, config);
    } else if (method === "POST_WITHOUT_AUTH") {
      data = await axios.post(url, reqBody, config);
    } else if (method === "PUT") {
      data = await axiosInstance.put(url, reqBody);
    } else if (method === "PATCH") {
      data = await axiosInstance.patch(url, reqBody, config);
    } else if (method === "PATCH_WITHOUT_AUTH") {
      data = await axios.patch(url, reqBody, config);
    }
    return data;
  }

  async function getWithAuth(url: string, data?: any): Promise<any> {
    return await request("GET", url, data);
  }

  async function getWithoutAuth(url: string, data?: any): Promise<any> {
    return await request("GET_WITHOUT_AUTH", url, data);
  }

  async function postWithAuth(url: string, data?: any): Promise<any> {
    return await request("POST", url, data);
  }

  async function postWithoutAuth(url: string, data?: any): Promise<any> {
    return await request("POST_WITHOUT_AUTH", url, data);
  }

  async function putWithAuth(url: string, data?: any): Promise<any> {
    return await request("PUT", url, data);
  }

  async function patchWithoutAuth(url: string, data?: any): Promise<any> {
    return await request("PATCH_WITHOUT_AUTH", url, data);
  }

  async function patchWithAuth(url: string, data?: any): Promise<any> {
    return await request("PATCH", url, data);
  }

  async function deleteWithAuth(url: string): Promise<any> {
    return await request("DELETE", url);
  }

  return {
    getWithAuth,
    getWithoutAuth,
    postWithAuth,
    postWithoutAuth,
    putWithAuth,
    patchWithAuth,
    patchWithoutAuth,
    deleteWithAuth,
  };
}
