import React from "react";

const Checkbox = ({
  label,
  checked,
  onChange,
}: {
  label?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="appearance-auto h-4 w-4 accent-primary transition duration-150 ease-in-out border rounded-full"
      />
      {label && <label className="text-gray-900">{label}</label>}
    </div>
  );
};

export default Checkbox;
