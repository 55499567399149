import ProfileSettings from "@/components/Settings/Profile";
import RestaurantSettings from "@/components/Settings/Restaurant";
import { ReactNode } from "react";

interface SettingsItem {
  text: string;
  component: () => ReactNode;
}

export const settingsItems: SettingsItem[] = [
  {
    text: "Restaurant",
    component: RestaurantSettings,
  },
  {
    text: "Profile",
    component: ProfileSettings,
  },
];

export const ADMIN = "admin";
