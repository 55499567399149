import { create } from "zustand";
import { persist } from "zustand/middleware";
import { findDishIndex, getAuthToken } from "../utils/common";
import { Socket, io } from "socket.io-client";
import { LocalStorageEnum, NotificationType, socketCallEnum } from "@/constans/enum";
import { CartInterface } from "@/interfaces/Order.interface";
import { toast } from "sonner";
import { TPlaceOrder } from "@/interfaces/PlaceOrder.interface";
import { v4 as uuidv4 } from "uuid";
import { RouterPaths } from "@/types/RouterPaths";
import { MdMenuBook, MdOutlineChair } from "react-icons/md";
import { TfiReceipt } from "react-icons/tfi";
import { IoReceiptOutline, IoSettingsOutline } from "react-icons/io5";
import { PiLifebuoy } from "react-icons/pi";
import { VscFeedback } from "react-icons/vsc";

interface SidebarItemBase {
  to: string;
  active?: boolean; // Make this optional
}

interface RegularSidebarItem extends SidebarItemBase {
  icon: JSX.Element;
  text: string;
  alert: boolean;
  isDivider?: false;
}

interface DividerSidebarItem extends SidebarItemBase {
  isDivider: true;
  icon?: never;
  text?: never;
  alert?: never;
}

export type SidebarItem = RegularSidebarItem | DividerSidebarItem;

export enum ActivityType {
  ADD_TO_CART = "add-to-cart",
  REMOVE_FROM_CART = "remove-from-cart",
  UPDATE_CART = "update-cart",
  ORDER = "order",
  USER_JOIN = "user-join",
  USER_LEAVE = "user-leave",
}
export enum OrderStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export interface Notification {
  type: NotificationType;
  time: Date;
  tableName: string;
}

type CartStoreType = {
  cart: Array<CartInterface>;
  socket: Socket | null;
  users: Array<{ name: string; socketId: string }>;
  loading: boolean;
  cookingInstructions: string;
  sidebarItems: SidebarItem[];
  socketMessage: string;
  notifications: Notification[];

  setAlert: (text: string, alert: boolean) => void;
  initializeSocket: () => () => void;
  connectToServer: () => void;
  joinSession: (userName: string) => void;
  leaveSession: () => void;
  updateSession: (dish: CartInterface) => void;
  addToCart: (dish: CartInterface) => void;
  setCookingInstructions: (text: string) => void;
  setNotifications: (notification: Notification) => void;
  clearNotifications: () => void;
  updateCart: (
    dishs: Array<{
      uuid: string;
      quantity: number;
    }>,
  ) => void;
  removeFromCart: (dishId: string) => void;
  clearCart: () => void;
  getCart: () => Array<CartInterface>;
  getNotifications: () => Notification[];
  placeOrder: (
    order: TPlaceOrder,
    sessionId: string,
  ) => Promise<{ message: string; status: OrderStatus; tableId: string | null }>;
};

export const useCartStore = create<CartStoreType>()(
  persist(
    (set, get) => ({
      cart: [],
      socket: null,
      users: [],
      loading: false,
      socketMessage: "",
      cookingInstructions: "",
      notifications: [],
      sidebarItems: [
        {
          icon: <MdMenuBook size={20} />,
          text: "Menu",
          alert: false,
          active: false,
          to: RouterPaths.MENU,
          isDivider: false,
        },
        {
          icon: <TfiReceipt size={20} />,
          text: "Orders",
          alert: false,
          active: false,
          to: RouterPaths.ORDERS,
          isDivider: false,
        },
        {
          icon: <MdOutlineChair size={20} />,
          text: "Table",
          alert: false,
          active: false,
          to: RouterPaths.TABLE,
          isDivider: false,
        },
        {
          icon: <IoReceiptOutline size={20} />,
          text: "Bills",
          alert: false,
          active: false,
          to: RouterPaths.BILLS,
          isDivider: false,
        },
        {
          isDivider: true,
          to: "/#",
        },
        {
          icon: <IoSettingsOutline size={20} />,
          text: "Settings",
          alert: false,
          active: false,
          to: RouterPaths.SETTINGS,
          isDivider: false,
        },
        {
          icon: <VscFeedback size={20} />,
          text: "Feedback",
          alert: false,
          active: false,
          to: RouterPaths.FEEDBACK,
          isDivider: false,
        },
        {
          icon: <PiLifebuoy size={20} />,
          text: "Help",
          alert: false,
          active: false,
          to: RouterPaths.HELP,
          isDivider: false,
        },
      ],

      setAlert: (text, alert) =>
        set((state) => ({
          sidebarItems: state.sidebarItems.map((item) =>
            item.isDivider || item.text !== text ? item : { ...item, alert },
          ),
        })),

      setCookingInstructions: (text) => {
        set({
          cookingInstructions: text,
        });
      },
      setNotifications: (notification: Notification) => {
        set((state) => ({
          notifications: [...state.notifications, notification],
        }));
      },

      initializeSocket: () => {
        const authToken = getAuthToken();
        if (!authToken) {
          throw new Error("Auth token not found");
        }
        const URL = import.meta.env.VITE_WSS_URL;

        const socket: Socket = io(URL, {
          transports: ["websocket", "polling"],
          auth: { token: `Bearer ${authToken}` },
          query: { restaurantId: "restaurant-id" },
          reconnection: true, // Enable automatic reconnection
          reconnectionAttempts: 5, // Try to reconnect up to 5 times
          reconnectionDelay: 1000, // Initial delay between reconnection attempts (in ms)
          reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts (in ms)
          timeout: 20000,
        });

        socket.connect();

        set({
          socket: socket,
        });
        socket.on(socketCallEnum.EXCEPTION, ({ message }: { message: string }) => {
          toast.error(message);
          set({
            loading: false,
          });
          set({
            users: [],
            cart: [],
          });
        });

        socket.on(socketCallEnum.REFFRESH_CART, ({ dishes }: { dishes: Array<CartInterface> }) => {
          set({
            cart: dishes,
          });
        });

        socket.on(socketCallEnum.REQUEST_BILL, ({ message }: { message: string }) => {
          toast.info(message);
          const tableName = message.split(" ")[0];
          set((state) => ({
            notifications: [
              {
                type: NotificationType.BILL_REQUESTED,
                time: new Date(),
                tableName: tableName,
              },
              ...state.notifications,
            ],
            socketMessage: `${NotificationType.BILL_REQUESTED}-${new Date().getTime()}`,
          })); // Update sidebar alert state
        });

        socket.on(socketCallEnum.HELP, ({ message }: { message: string }) => {
          console.log("🚀 ~ socket.on ~ message:", message);
          toast.info(message);
          const tableName = message.split(" ")[0];
          set((state) => ({
            notifications: [
              {
                type: NotificationType.CALL_SERVER,
                time: new Date(),
                tableName: tableName,
              },
              ...state.notifications,
            ],
          })); // Update sidebar alert state
        });

        socket.on(socketCallEnum.TABLE_STATUS, ({ message }: { message: string }) => {
          toast.info(message);
          const tableName = message.split(" ")[0];
          set((state) => ({
            notifications: [
              {
                type: NotificationType.TABLE_STATUS,
                time: new Date(),
                tableName: tableName,
              },
              ...state.notifications,
            ],
            socketMessage: `${NotificationType.TABLE_STATUS}-${new Date().getTime()}`,
          })); // Update sidebar alert state
        });

        socket.on(
          socketCallEnum.ORDER_STATUS,
          ({ message, status }: { message: string; status: OrderStatus }) => {
            //add code here
            switch (status) {
              case OrderStatus.ERROR:
                toast.error(message);
                break;
              case OrderStatus.SUCCESS: {
                toast.success(message);
                const tableName = message.split(" ")[0];
                set((state) => ({
                  sidebarItems: state.sidebarItems.map((item) =>
                    item.isDivider || item.text !== "Orders" ? item : { ...item, alert: true },
                  ),
                  notifications: [
                    {
                      type: NotificationType.PLACE_ORDER,
                      time: new Date(),
                      tableName: tableName,
                    },
                    ...state.notifications,
                  ],
                  socketMessage: `${NotificationType.PLACE_ORDER}-${new Date().getTime()}`,
                })); // Update sidebar alert state

                break;
              }
              default:
                toast.info(message);
                break;
            }
            socket.emit(socketCallEnum.REFFRESH_CART);

            set({
              loading: false,
            });
          },
        );

        socket.on(
          socketCallEnum.GET_USERS,
          ({ users }: { users: Array<{ name: string; socketId: string }> }) => {
            set({
              users: users,
            });
          },
        );

        socket.on(
          socketCallEnum.USERS_ON_TABLE,
          ({
            users,
            message,
          }: {
            users: Array<{ name: string; socketId: string }>;
            message: string;
          }) => {
            toast.info(message);
            set({
              users: users,
            });
          },
        );

        socket.on(
          socketCallEnum.DISHES_ON_TABLE,
          ({ dishes, message }: { dishes: Array<CartInterface>; message: string }) => {
            toast.info(message);
            set({
              cart: dishes,
            });
          },
        );

        socket.on(socketCallEnum.CART_DISH, (cart: Array<CartInterface>) => {
          set({
            cart: cart,
          });
        });

        return () => socket.close();
      },

      connectToServer: () => {
        const socket = get().socket;
        if (socket) {
          socket.emit("connectToServer");
        } else {
          console.error("Socket not initialized");
        }
      },

      joinSession: (userName: string) => {
        const socket = get().socket;
        if (socket) {
          socket.emit("joinSession", { userName });
        } else {
          console.error("Socket not initialized");
        }
      },

      leaveSession: () => {
        const socket = get().socket;
        if (socket) {
          socket.disconnect();
        } else {
          console.error("Socket not initialized");
        }
      },

      updateSession: (dish: CartInterface) => {
        const socket = get().socket;
        if (socket) {
          socket.emit("addDish", { dish });
        } else {
          console.error("Socket not initialized");
        }
      },

      addToCart: (dish: CartInterface) => {
        const cart = get().cart;
        const uuid = uuidv4();
        const dishIndex = findDishIndex(cart, dish);
        if (dishIndex === -1) {
          dish.uuid = uuid;
          set({
            cart: [...cart, dish],
          });
        } else {
          cart[dishIndex].quantity += dish.quantity;
          set({
            cart: [...cart],
          });
        }
      },

      updateCart: (
        dishes: Array<{
          uuid: string;
          quantity: number;
        }>,
      ) => {
        const socket = get().socket;
        if (!socket) {
          console.error("Socket not initialized");
          return;
        }
        socket.emit(socketCallEnum.UPDATE_DISH_V2, { dishes: dishes });
      },

      removeFromCart: (uuid: string) => {
        const cart = get().cart;
        const dishIndex = cart.findIndex((dish) => dish.uuid === uuid);
        if (dishIndex === -1) {
          console.error("Dish not found");
          return;
        }
        cart[dishIndex].quantity -= 1;
        if (cart[dishIndex].quantity === 0) {
          cart.splice(dishIndex, 1);
        }
        set({
          cart: [...cart],
        });
      },

      placeOrder: (order: TPlaceOrder, sessionId: string) => {
        set({ loading: true });
        const socket = get().socket;
        if (!socket) {
          return Promise.resolve({
            message: "Socket not initialized",
            status: OrderStatus.ERROR,
            tableId: null,
          });
        }
        return new Promise((resolve, reject) => {
          const responseHandler = (response: {
            message: string;
            status: OrderStatus;
            tableId: string | null;
          }) => {
            set({ loading: false });
            resolve(response);
          };

          socket.emit(socketCallEnum.RESTAURANT_PLACE_ORDER, { order, sessionId }, responseHandler);
          setTimeout(() => {
            set({ loading: false });
            reject({
              message: "Request timed out",
              status: OrderStatus.ERROR,
              tableId: null,
            });
          }, 5000); // 5 seconds timeout
        });
      },

      clearNotifications: () => {
        set({ notifications: [] });
      },

      clearCart: () => set({ cart: [], cookingInstructions: "" }),

      getCart: () => get().cart,
      getNotifications: () => get().notifications,
    }),
    {
      name: LocalStorageEnum.CART,
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => !["socket"].includes(key))),
      getStorage: () => sessionStorage,
    },
  ),
);
