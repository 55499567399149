import { useEffect } from "react"; // Import useEffect
import BreadCrumb from "@/components/common/Breadcrumb";
import Loading from "@/components/common/Loading";
import CategoryCard from "@/components/menu-page/CategoryCard";
import TableCard, { SheetBody, TableTrigger } from "@/components/tables-page/TableCard";
import { Button } from "@/components/ui/button";
import { NotificationType, ReactQueryEnum, SessionStatus } from "@/constans/enum";
import useAxios from "@/hooks/useAxios";
import { ITable } from "@/interfaces/table.interface";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSearchStore } from "@/store/searchStore";
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { toast } from "sonner";
import { AxiosError } from "axios";
import { parseAxiosError } from "@/utils/common";
import { useCartStore } from "@/store/cartStore";
import CreateTable from "@/components/tables-page/CreateTable";

export default function TablesPage() {
  const [searchParams] = useSearchParams();
  const tableId = searchParams.get("tableId");
  const [activeStatus, setActiveStatus] = useState("All");
  const statuses = ["All", SessionStatus.OPEN, SessionStatus.ONGOING, SessionStatus.CHECKOUT];
  const [selectedTable, setSelectedTable] = useState<ITable | null>(null);
  const { value, setPlaceholder, setValue } = useSearchStore();
  const { socketMessage } = useCartStore((state) => ({
    socketMessage: state.socketMessage,
  }));

  const { getWithAuth } = useAxios();

  const {
    data: tableData,
    isLoading: tableDataLoading,
    isError: tableDataIsError,
  } = useQuery({
    queryKey: [ReactQueryEnum.TABLES],
    retry: 1,
    queryFn: async () => {
      const response = await getWithAuth("/table/restaurant");
      return response.data;
    },
  });

  const [resetTablePopOver, setResetTablePopOver] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { patchWithAuth } = useAxios();
  const queryClient = useQueryClient();

  const { mutate: resetTableMutate } = useMutation({
    mutationFn: async ({ tableId }: { tableId: string }) => {
      setLoading(true);
      console.log("tableId", tableId);
      const response = await patchWithAuth(`/dining-session/${tableId}/reset`);
      return response.data;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage); // Ensure toast.error is used for error messages
    },
    onSuccess: () => {
      toast.success(`table has been reset`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
      setResetTablePopOver(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    console.log("socketMessage", socketMessage);
    if (
      socketMessage &&
      (socketMessage.startsWith(NotificationType.PLACE_ORDER) ||
        socketMessage.startsWith(NotificationType.BILL_REQUESTED) ||
        socketMessage.startsWith(NotificationType.TABLE_STATUS))
    ) {
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
    }
  }, [socketMessage]);

  useEffect(() => {
    setPlaceholder("Search for tables...");
    setValue("");
  }, [setPlaceholder]);

  // Use effect to update selectedTable when tableData changes
  useEffect(() => {
    if (tableData && selectedTable === null) {
      if (tableId) {
        const table = tableData.find((table: ITable) => table._id === tableId);
        table && setSelectedTable(table);
      } else {
        setSelectedTable(tableData[0]);
      }
    } else if (tableData && selectedTable !== null) {
      const table = tableData.find((table: ITable) => table._id === selectedTable?._id);
      table && setSelectedTable(table);
    }
  }, [tableData]);

  const filteredTables = useMemo((): ITable[] => {
    if (!tableData) return [];

    return tableData.filter((table: ITable) => {
      const tableStatus = table.sessionDetails ? table.sessionDetails.status : SessionStatus.OPEN;
      const matchesStatus = activeStatus === "All" || tableStatus === activeStatus;
      const matchesName = table.tableName.toLowerCase().includes(value.toLowerCase());
      return matchesStatus && matchesName;
    });
  }, [tableData, activeStatus, value]);

  if (tableDataIsError) {
    return <div>Error</div>;
  }
  if (tableDataLoading) return <Loading />;

  return (
    <div className="md:grid md:grid-cols-[3fr_minmax(300px,1fr)] gap-4 h-full max-h-full w-full items-start bg-secondary">
      <div className="p-4 md:px-6 h-full overflow-auto">
        <div className="flex justify-between items-center">
          <div className="w-full bg-secondary my-2">
            <BreadCrumb />
            <span className="text-primary font-bold text-3xl">Table</span>
          </div>
          <CreateTable />
        </div>

        <div className="w-full flex gap-2 py-4 pb-6">
          {statuses.map((status) => (
            <CategoryCard
              key={status}
              name={status}
              isActive={activeStatus === status}
              onClick={() => setActiveStatus(status)}
            />
          ))}
        </div>
        <div className="hidden w-full gap-2 md:gap-4 py-4 items-start md:grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
          {filteredTables.length > 0 &&
            filteredTables.map((table: ITable) => (
              <div key={table._id} onClick={() => setSelectedTable(table)} className="">
                <TableTrigger tableData={table} />
              </div>
            ))}
        </div>
        <div className="md:hidden w-full gap-2 md:gap-4 py-4 items-start grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
          {filteredTables.length > 0 &&
            filteredTables.map((table: ITable) => <TableCard key={table._id} tableData={table} />)}
        </div>
      </div>
      <div className="hidden md:flex flex-col h-full max-h-full w-full max-w-full bg-white overflow-auto">
        <div className="flex justify-between items-center border-b p-3">
          <p className="text-2xl font-semibold text-primary">
            Table {selectedTable ? selectedTable.tableName : tableData[0].tableName}
          </p>
          <p>
            {selectedTable && selectedTable.sessionDetails && (
              <Popover open={resetTablePopOver} onOpenChange={setResetTablePopOver}>
                <PopoverTrigger className="w-full mx-auto" asChild>
                  <Button
                    className="mx-auto  min-w-[80px] "
                    // variant="outline"
                    isLoading={isLoading}
                  >
                    Reset Table
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="z-20 bg-background rounded-md p-4">
                  <div className="z-20">
                    <p>This will delete all orders and reset the table</p>
                    <div className="flex gap-2 justify-end mt-3">
                      <PopoverClose asChild>
                        <Button variant="secondary">No</Button>
                      </PopoverClose>
                      <PopoverClose asChild>
                        <Button
                          variant="default"
                          onClick={() => {
                            resetTableMutate({ tableId: selectedTable._id });
                          }}
                        >
                          Yes
                        </Button>
                      </PopoverClose>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            )}
          </p>
        </div>
        <SheetBody tableData={selectedTable ?? tableData[0]} />
      </div>
    </div>
  );
}
