import { BillResponse } from "@/interfaces/Bill.interface";
import { Card } from "../ui/card";
import { capitalizeFirstLetter, getFormattedDate } from "@/utils/common";

export default function BillDrawer({ bill }: { bill: BillResponse }) {
  const formatTime = (dateString: Date) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true });
  };

  return (
    <div className="p-4 flex flex-col gap-2">
      <p className="font-semibold text-lg">Session Details</p>
      <Card className="p-3">
        <p className="text-muted-foreground">
          Starting Time{" "}
          <span className="text-secondary-foreground font-medium">
            {formatTime(bill.sessionStartTime)}
          </span>
        </p>
        <p className="text-muted-foreground">
          Ending Time{" "}
          <span className="text-secondary-foreground font-medium">
            {formatTime(bill.billCreationTime)}
          </span>
        </p>
        <p className="text-muted-foreground">
          Captain{" "}
          <span className="text-secondary-foreground font-medium">
            {capitalizeFirstLetter(bill.captainName)}
          </span>
        </p>
      </Card>
      <p className="font-semibold text-lg">Bill Details</p>
      <BillCard bill={bill} />
    </div>
  );
}

export function BillCard({ bill }: { bill: BillResponse }) {
  const [day, time] = getFormattedDate(bill.billCreationTime.toString());
  return (
    <Card className="flex flex-col gap-2 pb-3 ">
      <div className="bg-primary w-sfull rounded-xl text-white p-4 flex justify-between items-start">
        <div className="flex flex-col">
          <span className="font-semibold text-lg">{bill.tableName}</span>
          <span className="text-xs text-secondary">{`${day} • ${time}`}</span>
        </div>
        <div className="flex flex-col text-xs items-center">
          <span className=" text-secondary">Bill No</span>
          <div className="rounded-md p-2 bg-white bg-opacity-15 w-full text-center">
            {bill.billNo}
          </div>
        </div>
      </div>
      <div className="flex flex-col px-4">
        <div className="flex text-secondary-foreground font-medium pb-1 text-sm">
          <div className="basis-8/12">Item</div>
          <div className="basis-1/12 flex justify-center">Qty</div>
          <div className="basis-3/12 flex justify-end">Amount</div>
        </div>
        <div className="overflow-auto">
          {bill.orders.map((order, index) => (
            <div key={`${order.dishId}-${index}`} className="flex py-1 w-full text-sm">
              <div className="flex flex-col items-start basis-8/12">
                <div className="">{order.name}</div>
                <div className="text-xs">
                  <span className="font-medium">
                    {!!order.customisations?.size &&
                      `${order.customisations?.size.size}${order.customisations.ingredients ? " • " : ""}`}
                  </span>
                  <span>
                    {!!order.customisations?.ingredients &&
                      order?.customisations.ingredients.map((item) => item.ingredient).join(" • ")}
                  </span>
                </div>
              </div>

              <div className="basis-1/12 flex items-start justify-center">{order.quantity}</div>
              <div className="basis-3/12 flex items-start justify-end">
                ₹ {order.amount.toFixed(2)}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-2">
          <hr />
        </div>
        <div className="flex justify-between pt-2 font-medium text-sm ">
          <div className="flex-1 text-secondary-foreground">Subtotal</div>
          <div className="flex-1 flex justify-end ">₹ {bill.subTotal.toFixed(2)}</div>
        </div>

        <div className="flex justify-between pt-1 font-medium text-sm">
          <div className="flex-1 text-secondary-foreground">CGST(2.5%)</div>
          <div className="flex-1 flex justify-end">₹ {bill.tax.cgst.amount.toFixed(2)}</div>
        </div>
        <div className="flex justify-between pt-1 font-medium text-sm">
          <div className="flex-1 text-secondary-foreground ">SGST(2.5%)</div>
          <div className="flex-1 flex justify-end">₹ {bill.tax.sgst.amount.toFixed(2)}</div>
        </div>

        <div className="mt-2">
          <hr />
        </div>
        <div className="flex justify-between pt-1 font-bold ">
          <div className="flex-1 text-secondary-foreground ">Total</div>
          <div className="flex-1 flex justify-end ">₹ {bill.total.toFixed(2)}</div>
        </div>
      </div>
    </Card>
  );
}
