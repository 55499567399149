import React from "react";
import clsx from "clsx";
import { FoodType } from "@/interfaces/Order.interface";
function FoodTypeIndicator({ type, size = 2 }: { type: string; size?: number }) {
  return (
    <div
      className={clsx(" w-fit", {
        "border-[1.5px] rounded-[4px]": size === 2,
        "border-[1px] rounded-[3px]": size === 1,
        "border-red-700": type === "non_veg",
        "border-green-700": type === FoodType.VEG,
      })}
    >
      <div
        className={clsx("rounded-full m-[2px]", {
          " h-2 w-2": size === 2,
          " h-1 w-1": size === 1,
          "bg-red-700": type === "non_veg",
          "bg-green-700": type === FoodType.VEG,
        })}
      ></div>
    </div>
  );
}

export default FoodTypeIndicator;
