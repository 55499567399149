import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { parseAxiosError } from "@/utils/common";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { ReactQueryEnum } from "@/constans/enum";
import useAxios from "@/hooks/useAxios";

export default function CreateTable() {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    tableName: Yup.string()
      .max(5, "Table Name must be at least 5 characters long")
      .required("Table Name is required"),
    tableSize: Yup.number()
      .min(1, "Table Size must be greater than 0")
      .max(20, "Table Size must be less than or equal to 20")
      .required("Table Size is required")
      .typeError("Table Size must be a number"),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { postWithAuth } = useAxios();

  const { mutate: createTableMutate } = useMutation({
    mutationFn: async ({
      tableName,
      seatingCapacity,
    }: {
      tableName: string;
      seatingCapacity: number;
    }) => {
      setLoading(true);
      const response = await postWithAuth(`/table`, {
        tableName: tableName,
        seatingCapacity: seatingCapacity,
      });
      return { response, tableName, seatingCapacity };
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
    onSuccess: ({ tableName }) => {
      toast.success(`${tableName} added successfully`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
      setOpen(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className=""
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <Button variant="outline">Create Table</Button>
      </DialogTrigger>

      {/* Wrap the Formik component around DialogContent */}
      <Formik
        initialValues={{ tableName: "", tableSize: 0 }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          createTableMutate({
            tableName: values.tableName,
            seatingCapacity: Number(values.tableSize),
          });
        }}
      >
        {({ errors, touched }) => (
          <DialogContent className="">
            <DialogHeader>
              <DialogTitle className="">Create Table</DialogTitle>
              <DialogDescription>Add the information for your new table</DialogDescription>
            </DialogHeader>

            {/* Formik Form component wrapping the form fields */}
            <Form className="flex flex-col gap-3">
              <div className="">
                <p>Table Name</p>
                <Field
                  name="tableName"
                  as={Input}
                  placeholder="Table Name"
                  className={errors.tableName && touched.tableName ? "border-red-500" : ""}
                />
                <ErrorMessage name="tableName" component="div" className="text-red-500 text-sm" />
              </div>

              <div className="">
                <p>Table Size</p>
                <Field
                  name="tableSize"
                  as={Input}
                  placeholder="Table Size"
                  className={errors.tableSize && touched.tableSize ? "border-red-500" : ""}
                />
                <ErrorMessage name="tableSize" component="div" className="text-red-500 text-sm" />
              </div>

              <DialogFooter>
                <Button variant="ghost" type="reset" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button variant="default" type="submit" isLoading={loading}>
                  Create
                </Button>
              </DialogFooter>
            </Form>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
}
