import { RouterPaths } from "@/types/RouterPaths";
import { isAuthenticated } from "@/utils/common";
import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  return isAuthenticated() ? children : <Navigate to={RouterPaths.LOGIN} />;
};

export const HomeProtectedRoute = () => {
  return isAuthenticated() ? (
    <Navigate to={RouterPaths.TABLE} />
  ) : (
    <Navigate to={RouterPaths.LOGIN} />
  );
};

export const LoginProtectedRoute = ({ children }: { children: ReactNode }) => {
  return isAuthenticated() ? <Navigate to={RouterPaths.TABLE} /> : children;
};

export default ProtectedRoute;
