import "./App.css";
// import SideBar from "./components/layout/Sidebar/Sidebar";
import { POSRoutes } from "./router/Routes";
import { ThemeProvider } from "@/components/theme-provider";
import { Toaster } from "@/components/ui/sonner";
import { useAuthStore } from "./store/authStore";
import { useCartStore } from "./store/cartStore";
import { useEffect } from "react";

function App() {
  const { authToken } = useAuthStore((state) => ({
    authToken: state.authToken,
  }));

  const { initializeSocket, socket, connectToServer } = useCartStore((state) => ({
    initializeSocket: state.initializeSocket,
    socket: state.socket,
    connectToServer: state.connectToServer,
  }));

  useEffect(() => {
    if (authToken && !socket) {
      const cleanup = initializeSocket();

      connectToServer();
      return () => {
        cleanup();
      };
    }
  }, [authToken]);

  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <POSRoutes />
      <Toaster expand={false} richColors position="top-right" />
    </ThemeProvider>
  );
}

export default App;
