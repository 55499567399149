import create from "zustand";
import { persist } from "zustand/middleware";

type SearchStoreType = {
  value: string;
  placeholder: string;

  setValue: (newValue: string) => void;
  setPlaceholder: (newPlaceholder: string) => void;
};

export const useSearchStore = create<SearchStoreType>()(
  persist(
    (set) => ({
      value: "",
      placeholder: "Search...",

      setValue: (newValue: string) => {
        set({ value: newValue });
      },

      setPlaceholder: (newPlaceholder: string) => {
        set({ placeholder: newPlaceholder });
      },
    }),
    {
      name: "search-store", // name of the item in the storage
      getStorage: () => sessionStorage, // you can change this to localStorage if needed
    },
  ),
);
