import { useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useEffect, useState } from "react";
import useAxios from "@/hooks/useAxios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { parseAxiosError } from "@/utils/common";
import { useFormik } from "formik";
import * as yup from "yup";
import clsx from "clsx";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { PasswordInput } from "@/components/ui/password-input";
import { toast } from "sonner";

export default function ChangePasswordPage() {
  const location = useLocation();
  const { phoneNo, session } = location.state;
  // const phoneNo = "1234567890";
  // const session = "1234567890";
  const { postWithoutAuth } = useAxios();

  const validationSchema = yup.object({
    newPass: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
        "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number",
      ),
    confirmNewPass: yup
      .string()
      .oneOf([yup.ref("newPass"), undefined], "Passwords must match")
      .required("Confirm password is required"),
  });
  const passFormik = useFormik({
    initialValues: {
      newPass: "",
      confirmNewPass: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutate({ newPassword: values.newPass });
    },
  });

  const [passwordError, setPasswordError] = useState([
    {
      name: "password should be at least 8 characters long",
      condition: false,
    },
    {
      name: "password should contain at least one number",
      condition: false,
    },
    {
      name: "passwords do not match",
      condition: false,
    },
    {
      name: "password should contain at least one uppercase letter",
      condition: false,
    },
  ]);

  const updatePasswordErrors = () => {
    setPasswordError([
      {
        name: "one uppercase character",
        condition: !/(?=.*[A-Z])/.test(passFormik.values.newPass),
      },

      {
        name: "one number",
        condition: !/\d/.test(passFormik.values.newPass),
      },
      {
        name: "one lowercase character",
        condition: !/(?=.*[a-z])/.test(passFormik.values.newPass),
      },

      {
        name: "do not match",
        condition: !(
          passFormik.values.newPass === passFormik.values.confirmNewPass &&
          passFormik.values.newPass.length != 0
        ),
      },
      {
        name: "8 characters minimum",
        condition: passFormik.values.newPass.length < 8,
      },
    ]);
  };

  useEffect(() => {
    updatePasswordErrors();
  }, [passFormik.values]);

  const [loading,setLoading] = useState(false);

  const { mutate } = useMutation({
    mutationFn: async ({ newPassword }: { newPassword: string }) => {
      setLoading(true);
      const response = await postWithoutAuth("/auth-pos/force-change-password", {
        phoneNo: phoneNo,
        newPassword: newPassword,
        session: session,
      });
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success("Password changed successfully");
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  return (
    <div className="w-full lg:grid  lg:grid-cols-2  h-screen ">
      <div className="flex items-center justify-center py-12 px-2  h-screen">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <img
              src="/images/cibo_logo_primary.svg"
              alt="Logo"
              width="64"
              height="64"
              className="mx-auto"
            />
            <h1 className="text-3xl font-bold">Cibo</h1>
            <p className="text-balance text-muted-foreground">Set your new password</p>
          </div>
          <form onSubmit={passFormik.handleSubmit}>
            <div className="grid gap-4 ">
              <div className="grid gap-2">
                <Label htmlFor="newPass">New password</Label>
                <PasswordInput
                  id="newPass"
                  type="password"
                  placeholder="************"
                  required
                  name="newPass"
                  value={passFormik.values.newPass}
                  onChange={passFormik.handleChange}
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="confirmNewPass">Confirm new password</Label>
                </div>

                <PasswordInput
                  id="confirmNewPass"
                  type="password"
                  placeholder="************"
                  required
                  name="confirmNewPass"
                  value={passFormik.values.confirmNewPass}
                  onChange={passFormik.handleChange}
                />
              </div>

              <div className="flex flex-wrap gap-1 w-full ">
                {passwordError.map((error, index) => {
                  return (
                    <div
                      className={clsx("flex items-center", {
                        "w-48": index % 2 === 0,
                      })}
                    >
                      <div
                        className={clsx("w-3 h-3 rounded-full flex items-center justify-center", {
                          "bg-red-500": error.condition,
                          "bg-green-500": !error.condition,
                        })}
                      >
                        {!error.condition && <FaCheck className="w-1.5 h-1.5 text-white" />}
                        {error.condition && <ImCross className="w-1.5 h-1.5 text-white" />}
                      </div>
                      <p
                        className={clsx("pl-1 pb-1", {
                          "text-red-500": error.condition,
                          "text-green-500": !error.condition,
                        })}
                      >
                        {error.name}
                      </p>
                    </div>
                  );
                })}
              </div>

              <Button
                type="submit"
                className="w-full"
                isLoading = {loading}
                disabled={!passFormik.isValid || !passFormik.dirty}
              >
                Change password
              </Button>
            </div>
          </form>

          {/* <div className="mt-4 text-center text-sm">
            Don&apos;t have an account?{" "}
            <Link to={RouterPaths.LOGIN} className="underline">
              Sign up
            </Link>
          </div> */}
        </div>
      </div>
      <div className="hidden  lg:flex lg:justify-center lg:items-center rounded-l-xl  h-screen">
        <img
          src="/images/login_abstract.jpg"
          alt="Image"
          className="m-3 place-items-center rounded-3xl h-[98%] w-[98%] object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  );
}
