import DishCard from "@/components/add-order-page/DishCard";
import BreadCrumb from "@/components/common/Breadcrumb";
import Loading from "@/components/common/Loading";
import CategoryCard from "@/components/menu-page/CategoryCard";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { ReactQueryEnum } from "@/constans/enum";
import useAxios from "@/hooks/useAxios";
import { DishInterface, MenuType } from "@/interfaces/Dish.interface";
import { ITable } from "@/interfaces/table.interface";
import { useCartStore } from "@/store/cartStore";
import { RouterPaths } from "@/types/RouterPaths";
import {
  capitalizeFirstLetter,
  getIngredients,
  getSize,
  parseAxiosError,
  parseCart,
} from "@/utils/common";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import { Textarea } from "@/components/ui/textarea";
import { useSearchStore } from "@/store/searchStore";
import { Card } from "@/components/ui/card";
import NoDishSvg from "../assets/no-data.svg";

function TableSheetBody() {
  const { cart, addToCart, removeFromCart, cookingInstructions, setCookingInstructions } =
    useCartStore((state) => ({
      cart: state.cart,
      addToCart: state.addToCart,
      updateCart: state.updateCart,
      removeFromCart: state.removeFromCart,
      cookingInstructions: state.cookingInstructions,
      setCookingInstructions: state.setCookingInstructions,
    }));
  const [orderDetails, setOrderDetails] = useState<{
    total: number;
    quantity: number;
    items: number;
  }>({
    total: 0,
    quantity: 0,
    items: 0,
  });
  useEffect(() => {
    const total = cart.reduce((acc, item) => acc + item.quantity * item.price, 0);
    const quantity = cart.reduce((acc, item) => acc + item.quantity, 0);
    const items = cart.length;
    setOrderDetails({ total, quantity, items });
  }, [cart]);

  if (cart.length === 0) {
    return (
      <div className="flex-1 flex flex-col justify-center items-center gap-3  overflow-auto pr-2">
        <p className="text-lg text-muted-foreground">No items in cart</p>
      </div>
    );
  }
  return (
    <div className="flex-1 flex flex-col gap-3  overflow-auto pr-2">
      <p className="text-lg text-muted-foreground">Order Details</p>
      <Card className="flex flex-col gap-3 p-3">
        {cart.map((cartItem, index) => {
          const sizeStr = cartItem.customisation ? getSize(cartItem.customisation) : "";
          const ingrStr = cartItem.customisation ? getIngredients(cartItem.customisation) : "";
          return (
            <div key={cartItem.uuid} className="flex flex-col gap-0">
              <div className="flex gap-2 w-full items-start justify-between">
                <p>{cartItem.dishName}</p>
              </div>
              <div className="text-sm">
                <span className="font-medium">{sizeStr}</span>
                {sizeStr.length > 0 && <span>{" • "}</span>}
                <span className="text-muted-foreground">{ingrStr}</span>
              </div>
              {/* <div className="text-muted-foreground text-sm">
                {cartItem.customisation && getIngredients(cartItem.customisation)}
              </div> */}
              <div className="flex gap-2 w-full justify-between items-start mt-2">
                <div className="flex gap-1 border p-1 rounded-md w-fit">
                  <Button
                    variant="secondary"
                    className="rounded-full  aspect-square p-0  h-6 w-6"
                    onClick={() => {
                      removeFromCart(cartItem.uuid);
                    }}
                  >
                    <IoMdRemove className="text-muted-foreground h-4 w-4  hover:text-primary" />
                  </Button>
                  <p className=" px-2">{cartItem.quantity}</p>
                  <Button
                    variant="secondary"
                    className="rounded-full aspect-square p-0  h-6 w-6"
                    onClick={() => {
                      addToCart({
                        ...cartItem,
                        quantity: 1,
                      });
                    }}
                  >
                    <IoMdAdd className="text-muted-foreground h-4 w-4  hover:text-primary" />
                  </Button>
                </div>
                <div className="">
                  <p className="text-right font-medium">
                    <span>&#8377;</span>
                    {cartItem.quantity * cartItem.price}
                  </p>
                  <div className="flex gap-1">
                    <p className="text-right text-xs text-muted-foreground">{cartItem.quantity}</p>
                    <p className="text-right text-xs text-muted-foreground">x</p>
                    <p className="text-right text-xs text-muted-foreground">
                      <span>&#8377;</span>
                      {cartItem.price}
                    </p>
                  </div>
                </div>
              </div>
              {index === orderDetails.items - 1 ? null : <Separator className="my-3" />}
            </div>
          );
        })}
      </Card>
      <div className="mx-0.5">
        <Textarea
          value={cookingInstructions}
          onChange={(e) => {
            setCookingInstructions(e.target.value);
          }}
          placeholder="Cooking Instructions"
          className=""
        />
      </div>
      <Card className="p-3">
        <div className="">
          <div className="flex justify-between gap-2 items-center">
            <p className="text-muted-foreground">Total</p>
            <p className="font-medium">
              <span>&#8377;</span>
              {orderDetails.total}
            </p>
          </div>
          <div className="flex justify-between gap-2 items-center">
            <p className="text-muted-foreground">Items</p>
            <p className="font-medium">{orderDetails.quantity}</p>
          </div>
        </div>
      </Card>
    </div>
  );
}

interface LocationState {
  tableData: ITable;
}

function AddOrderSheet({
  tableData,
  handlePlaceOrder,
  isPending,
}: {
  tableData: ITable;
  handlePlaceOrder: () => any;

  isPending: boolean;
}) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className="">
          <Button>View Order Details</Button>
        </div>
      </SheetTrigger>
      <SheetContent aria-describedby="" className="flex flex-col overflow-hidden">
        <div className="mt-5 flex justify-between items-center">
          <SheetTitle className="text-xl">Table {tableData.tableName}</SheetTitle>
        </div>
        <TableSheetBody />
        <Button
          onClick={() => {
            handlePlaceOrder();
          }}
          disabled={isPending}
        >
          Place Order
        </Button>
      </SheetContent>
    </Sheet>
  );
}
const AddOrder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const tableData = state?.tableData;
  const [restaurantCategoryMap, setRestaurantCategoryMap] = useState<{ [key: string]: string }>({});
  const [filteredDishes, setFilteredDishes] = useState<DishInterface[]>([]);
  const { getWithAuth, postWithAuth } = useAxios();
  const queryClient = useQueryClient();
  const { value, setPlaceholder, setValue } = useSearchStore();

  const { cart, cookingInstructions, clearCart, loading } = useCartStore((state) => ({
    cart: state.cart,
    cookingInstructions: state.cookingInstructions,
    clearCart: state.clearCart,
    placeOrder: state.placeOrder,
    loading: state.loading,
  }));

  const {
    data: menu,
    isError: isSessionError,
    isLoading: isSessionLoading,
    error,
  } = useQuery<MenuType, AxiosError>({
    queryKey: [ReactQueryEnum.MENU, ReactQueryEnum.RESTAURANT_CATEGORIES],
    queryFn: async () => {
      const response = await getWithAuth("/dish/menu");
      return response.data;
    },
  });

  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      const response = await postWithAuth(
        `/dining-session/${tableData.sessionDetails?._id}/place-order`,
        {
          orders: parseCart(cart),
          cookingInstructions,
        },
      );
      return response;
    },
    onSuccess: () => {
      toast.success("Order placed successfully happy");

      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });

      clearCart();
      navigate(`${RouterPaths.TABLE}?tableId=${tableData._id}`);
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
  });

  const [activeCategory, setActiveCategory] = useState<string>("all");

  useEffect(() => {
    setPlaceholder("Search for dishes...");
    setValue("");
  }, [setPlaceholder]);

  useEffect(() => {
    if (menu) {
      const categoryMap = Object.fromEntries(
        menu.map((category) => [category.restaurantCategoryId, category.restaurantCategoryName]),
      );
      setRestaurantCategoryMap(categoryMap);
    }
  }, [menu]);

  useEffect(() => {
    if (menu) {
      const filteredDishes =
        activeCategory === "all"
          ? menu.flatMap((category) =>
              category.dishes.filter((dish) =>
                dish.name.toLowerCase().includes(value.toLowerCase()),
              ),
            )
          : menu
              .find((category) => category.restaurantCategoryId === activeCategory)
              ?.dishes.filter((dish) => dish.name.toLowerCase().includes(value.toLowerCase())) ||
            [];

      setFilteredDishes(filteredDishes);
    }
  }, [menu, value, activeCategory]);

  if (isSessionError && error) {
    const { errorMessage } = parseAxiosError(error);
    toast.error(errorMessage);
  }

  if (isSessionLoading) return <Loading />;
  if (!tableData) {
    navigate(RouterPaths.TABLE);
    return null;
  }
  return (
    <div className="md:grid md:grid-cols-[3fr_minmax(300px,1fr)] gap-4 h-full max-h-full w-full items-start bg-secondary">
      <div className="p-4 md:px-6 h-full overflow-auto">
        <div className="w-full bg-secondary z-[100] my-4">
          <BreadCrumb />
          <div className="flex gap-2 flex-wrap w-full justify-between items-center">
            <span className="text-primary font-bold text-3xl">Add Order</span>
            <div className="block md:hidden">
              <AddOrderSheet tableData={tableData} handlePlaceOrder={mutate} isPending={loading} />
            </div>
          </div>
        </div>
        <p className="text-xl font-bold text-red-800 mt-3">Categories</p>
        <div className="w-full flex gap-2 sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-10 overflow-y-auto my-4 pb-2">
          <CategoryCard
            key="all"
            name="all"
            isActive={activeCategory === "all"}
            onClick={() => {
              setActiveCategory("all");
            }}
          />
          {Object.entries(restaurantCategoryMap).map(([id, name]) => (
            <CategoryCard
              key={id}
              name={name}
              isActive={activeCategory === id}
              onClick={() => {
                setActiveCategory(id);
              }}
            />
          ))}
        </div>
        <div className="gap-2 flex flex-col items-start">
          <div className="w-full flex justify-between items-center">
            <span className="text-xl font-bold text-red-800">
              {activeCategory === "all"
                ? "All"
                : capitalizeFirstLetter(restaurantCategoryMap[activeCategory])}
            </span>
          </div>
          {activeCategory === "all" && (
            <div>
              {menu?.map((category) => {
                // Filter dishes based on the search value
                const filteredDishes = category.dishes.filter((dish) =>
                  dish.name.toLowerCase().includes(value.toLowerCase()),
                );

                // Only display the category if there are matching dishes
                if (filteredDishes.length > 0) {
                  return (
                    <div key={category.restaurantCategoryId}>
                      <span className="text-xl font-bold text-red-800">
                        {capitalizeFirstLetter(category.restaurantCategoryName)}
                      </span>
                      <div className="w-full grid gap-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 overflow-y-auto my-4 pb-2">
                        {filteredDishes.map((dish: DishInterface) => (
                          <DishCard key={dish._id} dish={dish} />
                        ))}
                      </div>
                    </div>
                  );
                }

                // Return null if there are no matching dishes
                return null;
              })}
            </div>
          )}
          {activeCategory !== "all" && filteredDishes.length !== 0 && (
            <div className="w-full grid gap-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 overflow-y-auto my-4 pb-2">
              {filteredDishes.map((dish: DishInterface) => (
                <DishCard key={dish._id} dish={dish} />
              ))}
            </div>
          )}
          {activeCategory !== "all" && filteredDishes.length === 0 && (
            <div className="flex items-center justify-center w-full flex-1">
              <img src={NoDishSvg} alt="no dishes found" className="h-64" />
            </div>
          )}
        </div>
      </div>
      <div className="hidden md:flex flex-col h-full max-h-full w-full max-w-full bg-white overflow-auto p-3  gap-3">
        <div className="flex justify-between items-center    gap-2">
          {/* <p className="text-2xl font-semibold break-all">{tableData.sessionDetails?.users[0]}</p> */}
          <p className="text-2xl font-semibold break-all">Table {tableData.tableName}</p>
        </div>
        <Separator className="" />
        <TableSheetBody />
        <Button
          onClick={() => {
            mutate();
          }}
          disabled={isPending}
        >
          Place Order
        </Button>
      </div>
    </div>
  );
};

export default AddOrder;
