export enum LocalStorageEnum {
  AUTH_TOKEN = "authToken",
  REFRESH_TOKEN = "refreshToken",
  ID_TOKEN = "idToken",
  AUTH_STORAGE = "auth-storage",
  CART = "cart",
}

export enum OrderStatus {
  REJECTED = "rejected",
  PENDING = "pending",
  CONFIRMED = "confirmed",
  SERVED = "served",
}

export enum OrderedDishStatus {
  REJECTED = "rejected",
  PENDING = "pending",
  CONFIRMED = "confirmed",
  SERVED = "served",
}

export enum SpiceLevel {
  SWEET = "sweet",
  MILD = "mild",
  MEDIUM = "medium",
  SPICY = "spicy",
  NONE = "none",
}

export enum DishType {
  VEG = "veg",
  NON_VEG = "non veg",
  EGG = "egg",
  JAIN = "jain",
}
export enum SessionStatus {
  OPEN = "open",
  ONGOING = "ongoing",
  CHECKOUT = "checkout",
}

export enum ReactQueryEnum {
  BILLS = "bills",
  BILL_HISTORY = "billHistory",
  DINING_SESSIONS = "diningSessions",
  TABLES = "tables",
  MENU = "menu",
  RESTAURANT_CATEGORIES = "restaurantCategories",
  RESTAURANT = "restaurant",
  ORDER_HISTORY = "orderHistory",
  FEEDBACK = "feedback",
}

export enum socketCallEnum {
  JOIN_SESSION = "joinSession",
  CONNECT = "connect",
  ADD_DISH = "addDish",
  UPDATE_CART = "updateCart",
  CART_DISH = "cartDish",
  DISHES_ON_TABLE = "dishesOnTable",
  UPDATE_DISH = "updateDish",
  UPDATE_DISH_V2 = "updateDishV2",
  USERS_ON_TABLE = "usersOnTable",
  ACTIVITY = "activity",
  GET_USERS = "getUsers",
  PLACE_ORDER = "placeOrder",
  RESTAURANT_PLACE_ORDER = "restaurantPlaceOrder",
  ORDER_STATUS = "orderStatus",
  EXCEPTION = "exception",
  REFFRESH_CART = "refreshCart",
  HELP = "help",
  REQUEST_BILL = "requestBill",
  TABLE_STATUS = "tableStatus",
}

export enum NotificationType {
  CALL_SERVER = "call_server",
  PLACE_ORDER = "place_order",
  BILL_REQUESTED = "bill_requested",
  TABLE_STATUS = "table_status",
}
