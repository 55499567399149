import { useState, useEffect, useCallback, useMemo } from "react";
import { Card } from "../ui/card";
import { Switch } from "../ui/switch";
import { ITable, SessionStatus } from "@/interfaces/table.interface";
import { Badge } from "../ui/badge";
import { FaEdit } from "react-icons/fa";
import clsx from "clsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { capitalizeFirstLetter, parseAxiosError, timeSince } from "@/utils/common";
import { Link } from "react-router-dom";
import { Order } from "@/interfaces/OrderResponse.interface";
import { OrderStatus, ReactQueryEnum } from "@/constans/enum";
import OrderCard from "../orders-page/OrderCard";
import PendingOrderCard from "../orders-page/PendingOrderCard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { PopoverClose } from "@radix-ui/react-popover";
import PrintBill from "./PrintBill";
import useAxios from "@/hooks/useAxios";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { BillResponse } from "@/interfaces/Bill.interface";
import { RouterPaths } from "@/types/RouterPaths";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

export function EditTable({
  tableName,
  tableId,
  seatingCapacity,
}: {
  tableId: string;
  tableName: string;
  seatingCapacity: number;
}) {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    tableName: Yup.string()
      .max(5, "Table Name must be at least 5 characters long")
      .required("Table Name is required"),
    tableSize: Yup.number()
      .min(1, "Table Size must be greater than 0")
      .max(20, "Table Size must be less than or equal to 20")
      .required("Table Size is required")
      .typeError("Table Size must be a number"),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { patchWithAuth, deleteWithAuth } = useAxios();

  const { mutate: deleteTableMutate } = useMutation({
    mutationFn: async ({ tableName, tableId }: { tableName: string; tableId: string }) => {
      setDeleteLoading(true);
      const response = await deleteWithAuth(`/table/${tableId}`);
      return { response, tableName, seatingCapacity };
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
    onSuccess: ({ tableName }) => {
      toast.success(`${tableName} deleted successfully`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
      setOpen(false);
    },
    onSettled: () => {
      setDeleteLoading(false);
    },
  });

  const { mutate: editTableMutate } = useMutation({
    mutationFn: async ({
      tableName,
      seatingCapacity,
    }: {
      tableName: string;
      seatingCapacity: number;
    }) => {
      setLoading(true);
      const response = await patchWithAuth(`/table/${tableId}`, {
        tableName: tableName,
        seatingCapacity: seatingCapacity,
      });
      return { response, tableName, seatingCapacity };
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
    onSuccess: ({ tableName }) => {
      toast.success(`${tableName} updated successfully`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
      setOpen(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className=""
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <FaEdit className="text-muted-foreground " />
      </DialogTrigger>

      <Formik
        initialValues={{
          tableName: tableName || "",
          tableSize: seatingCapacity || 0,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          editTableMutate({
            tableName: values.tableName,
            seatingCapacity: Number(values.tableSize),
          });
        }}
      >
        {({ errors, touched }) => (
          <DialogContent className="">
            <DialogHeader>
              <DialogTitle className="">Edit Table</DialogTitle>
              <DialogDescription>Edit the information for your table</DialogDescription>
            </DialogHeader>

            <Form className="flex flex-col gap-3">
              <div className="">
                <p>Table Name</p>
                <Field
                  name="tableName"
                  as={Input}
                  placeholder="Table Name"
                  className={errors.tableName && touched.tableName ? "border-red-500" : ""}
                />
                <ErrorMessage name="tableName" component="div" className="text-red-500 text-sm" />
              </div>

              <div className="">
                <p>Table Size</p>
                <Field
                  name="tableSize"
                  as={Input}
                  placeholder="Table Size"
                  className={errors.tableSize && touched.tableSize ? "border-red-500" : ""}
                />
                <ErrorMessage name="tableSize" component="div" className="text-red-500 text-sm" />
              </div>

              <DialogFooter>
                <div className="flex w-full">
                  <Button
                    variant="destructive"
                    onClick={() => {
                      deleteTableMutate({ tableName, tableId });
                    }}
                    className="mr-auto"
                    isLoading={deleteLoading}
                  >
                    Delete
                  </Button>
                  <Button variant="ghost" type="reset" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button variant="default" type="submit" isLoading={loading}>
                    Save Changes
                  </Button>
                </div>
              </DialogFooter>
            </Form>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
}

export function SheetBody({ tableData }: { tableData: ITable }) {
  const [generateBillPopOver, setGenerateBillPopOver] = useState(false);

  const [printBillOpen, setPrintBillOpen] = useState(false);
  const queryClient = useQueryClient();
  const sortOrdersByTime = useCallback((orders: Order[]) => {
    return orders.sort(
      (a, b) => new Date(b.timeOrdered).getTime() - new Date(a.timeOrdered).getTime(),
    );
  }, []);
  const { postWithAuth } = useAxios();
  const [bill, setBill] = useState<BillResponse>();
  const [loading, isLoading] = useState<boolean>(false);
  const { mutate: billGenereateMutate } = useMutation({
    mutationFn: async ({ sessionId }: { sessionId: string }) => {
      isLoading(true);
      const response = await postWithAuth(`/bill/${sessionId}/generate-bill`);
      return response.data;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage); // Ensure toast.error is used for error messages
    },
    onSuccess: (data) => {
      setBill(data);
      toast.success(`bill generated`);
      // queryClient.invalidateQueries({queryKey : [ReactQueryEnum.BILLS, ReactQueryEnum.DINING_SESSIONS]});
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
      setGenerateBillPopOver(false);
      setPrintBillOpen(true);
    },
    onSettled: () => {
      isLoading(false);
    },
  });

  const { mutate: startSessionMutate } = useMutation({
    mutationFn: async ({ tableId, tableName }: { tableId: string; tableName: string }) => {
      isLoading(true);
      await postWithAuth(`/dining-session/${tableId}/start-session`);
      return tableName;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage); // Ensure toast.error is used for error messages
    },
    onSuccess: (tableName) => {
      toast.success(`table ${tableName} is now ongoing`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
    },
    onSettled: () => {
      isLoading(false);
    },
  });

  const total = useMemo(() => {
    if (!tableData.sessionDetails) {
      return {
        subTotal: 0,
        sgst: 0,
        cgst: 0,
        total: 0,
      };
    }
    const servedOrders = tableData.sessionDetails.confirmedOrders.filter(
      (order) => order.orderStatus === OrderStatus.SERVED,
    );
    const subTotal = servedOrders.reduce((total, order) => {
      return (
        total +
        order.dishes.reduce((orderTotal, dish) => orderTotal + dish.amount * dish.quantity, 0)
      );
    }, 0);
    const sgst = (subTotal * 9) / 100;
    const cgst = (subTotal * 9) / 100;
    const total = Math.round(subTotal);
    return {
      subTotal,
      sgst,
      cgst,
      total,
    };
  }, [tableData.sessionDetails]);
  const combinedOrders = useMemo(() => {
    if (!tableData.sessionDetails) {
      return [];
    }
    const allOrders = [
      ...tableData.sessionDetails.confirmedOrders,
      ...tableData.sessionDetails.pendingOrders,
    ];
    return sortOrdersByTime(allOrders);
  }, [tableData.sessionDetails, sortOrdersByTime]);

  if (!tableData.sessionDetails)
    return (
      <div className="flex-1 flex justify-center items-center px-8">
        <Popover>
          <PopoverTrigger className="w-full mx-auto" asChild>
            <Button isLoading={loading}>Start Session</Button>
          </PopoverTrigger>
          <PopoverContent className="z-[9999999999]">
            <div className="">
              <p>Are you sure you want to start a new session?</p>
              <div className="flex gap-2 justify-end mt-3">
                <PopoverClose asChild>
                  <Button variant="secondary">No</Button>
                </PopoverClose>
                <PopoverClose asChild>
                  <Button
                    variant="default"
                    onClick={() => {
                      startSessionMutate({
                        tableId: tableData._id,
                        tableName: tableData.tableName,
                      });
                    }}
                  >
                    Yes
                  </Button>
                </PopoverClose>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    );

  return (
    <div className="flex-1 flex flex-col gap-2  overflow-auto px-3">
      <div className="flex-1  overflow-auto flex flex-col gap-3 pr-2 py-3">
        <p className="font-semibold text-lg">Session Details</p>
        <Card className="p-3">
          <p className="text-muted-foreground">
            Session Pin{" "}
            <span className="text-secondary-foreground font-medium">
              {tableData.sessionDetails.pin}
            </span>
          </p>
          <p className="text-muted-foreground">
            Starting Time{" "}
            <span className="text-secondary-foreground font-medium">
              {new Date(tableData.sessionDetails.createdAt).toLocaleTimeString()}
            </span>
          </p>
          <p className="text-muted-foreground">
            Captain{" "}
            <span className="text-secondary-foreground font-medium">
              {capitalizeFirstLetter(tableData.sessionDetails.users[0])}
            </span>
          </p>
        </Card>
        <p className="font-semibold text-lg">Orders</p>
        {combinedOrders.map((order: Order) => {
          return order.orderStatus === OrderStatus.PENDING ? (
            <PendingOrderCard
              key={order.orderId}
              tableName={tableData.tableName}
              order={order}
              sessionId={tableData.sessionDetails ? tableData.sessionDetails._id : ""}
              callback={() => {
                queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
              }}
            />
          ) : (
            <OrderCard
              key={order.orderId}
              tableName={tableData.tableName}
              order={order}
              sessionId={tableData.sessionDetails ? tableData.sessionDetails._id : ""}
              callback={() => {
                queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
              }}
            />
          );
        })}

        <Card>
          <div className="p-3 flex flex-col ">
            <div className="flex justify-between ">
              <div className="text-muted-foreground">Sub Total</div>
              <div className="">₹ {total.subTotal}</div>
            </div>
            {/* <div className="flex justify-between ">
              <div className="text-muted-foreground">SGST (9.00%)</div>
              <div className="">₹ {total.sgst}</div>
            </div>
            <div className="flex justify-between ">
              <div className="text-muted-foreground">CGST (9.00%)</div>
              <div className="">₹ {total.cgst}</div>
            </div> */}
            <div className="border-t border-gray-200 my-2"></div>
            {/* <div className="flex justify-between  font-semibold">
              <div className="">Total</div>
              <div className="">₹ {total.total}</div>
            </div> */}
            <p className="mt-3 text-sm font-medium text-primary  px-2 py-2 border border-primary rounded-md">
              <span className="block">Heads up!</span>
              Total only includes the served dishes.
            </p>
          </div>
        </Card>
      </div>
      <div className="flex justify-between gap-4 pb-2 pt-1">
        <Popover open={generateBillPopOver} onOpenChange={setGenerateBillPopOver}>
          <PopoverTrigger className="w-full mx-auto" asChild>
            <Button className="mx-auto  min-w-[80px] " variant="default" isLoading={loading}>
              Generate Bill
            </Button>
          </PopoverTrigger>
          <PopoverContent className="z-40">
            <div className="">
              <p>Do you want to Generate bill?</p>
              <div className="flex gap-2 justify-end mt-3">
                <PopoverClose asChild>
                  <Button variant="secondary">No</Button>
                </PopoverClose>
                <PopoverClose asChild>
                  <Button
                    variant="default"
                    onClick={() => {
                      billGenereateMutate({
                        sessionId: tableData.sessionDetails ? tableData.sessionDetails._id : "",
                      });
                    }}
                  >
                    Yes
                  </Button>
                </PopoverClose>
              </div>
            </div>
          </PopoverContent>
        </Popover>
        {tableData.sessionDetails && (
          <Link
            className="text-primary text-base hover:underline"
            to={{
              pathname: RouterPaths.ADD_ORDER,
            }}
            state={{ tableData }}
          >
            <Button variant="outline">Add Order</Button>
          </Link>
        )}
      </div>
      {bill && !loading && (
        <PrintBill
          modalOpen={printBillOpen}
          setModaOpen={setPrintBillOpen}
          sessionId={tableData.sessionDetails ? tableData.sessionDetails._id : ""}
          bill={bill}
        />
      )}
    </div>
  );
}

export function TableTrigger({ tableData }: { tableData: ITable }) {
  const [time, setTime] = useState<string>("");
  useEffect(() => {
    if (!tableData.sessionDetails) return;
    const date = new Date(tableData.sessionDetails?.createdAt);

    const updateTime = () => {
      setTime(timeSince(date));
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [tableData.sessionDetails]);

  return (
    <Card
      // className="p-3 min-h-[150px] flex flex-col  justify-between items-start cursor-pointer"
      className={clsx(
        "p-3 min-h-[150px] flex flex-col  justify-between items-start cursor-pointer hover:scale-[105%] transition-all",
        {
          "border-green-500 ": tableData.sessionDetails
            ? tableData.sessionDetails.status === SessionStatus.OPEN
            : true,
          "border-orange-500":
            tableData.sessionDetails && tableData.sessionDetails.status === SessionStatus.ONGOING,
          "border-red-500 ":
            tableData.sessionDetails && tableData.sessionDetails.status === SessionStatus.CHECKOUT,
          "border-blue-500 ": tableData.sessionDetails && tableData.sessionDetails.billRequested,
        },
      )}
    >
      <div className="flex w-full justify-between">
        <div className="">
          {tableData.sessionDetails && tableData.sessionDetails.billRequested && (
            <Badge className="bg-blue-200 text-blue-600 mr-2 break-all  font-normal">
              Bill Requested
            </Badge>
          )}
          <Badge
            // variant="outline"
            className={clsx({
              "bg-green-200 text-green-600 font-normal": tableData.sessionDetails
                ? tableData.sessionDetails.status === SessionStatus.OPEN
                : true,
              "bg-orange-200 text-orange-600 font-normal":
                tableData.sessionDetails &&
                tableData.sessionDetails.status === SessionStatus.ONGOING,
              "bg-red-200 text-red-600 font-normal":
                tableData.sessionDetails &&
                tableData.sessionDetails.status === SessionStatus.CHECKOUT,
            })}
          >
            {capitalizeFirstLetter(
              tableData.sessionDetails ? tableData.sessionDetails.status : "Open",
            )}
          </Badge>
        </div>
        <Switch
          // checked={true}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
      {tableData.sessionDetails && tableData.sessionDetails.status !== SessionStatus.OPEN && (
        <p className=" text-primary font-semibold text-left">{time}</p>
      )}
      <div className="flex w-full justify-between items-center">
        <div className="">
          <p className="text-sm">
            Size <span className="font-semibold">{tableData.seatingCapacity}</span>
          </p>
          <p className="">
            Table <span className="font-semibold">{tableData.tableName}</span>
          </p>
        </div>
        <EditTable
          tableName={tableData.tableName}
          tableId={tableData._id}
          seatingCapacity={tableData.seatingCapacity}
        />
      </div>
    </Card>
  );
}
export default function TableCard({ tableData }: { tableData: ITable }) {
  const [resetTablePopOver, setResetTablePopOver] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { patchWithAuth } = useAxios();
  const queryClient = useQueryClient();

  const { mutate: resetTableMutate } = useMutation({
    mutationFn: async ({ tableId }: { tableId: string }) => {
      setLoading(true);
      const response = await patchWithAuth(`/dining-session/${tableId}/reset`);
      return response.data;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage); // Ensure toast.error is used for error messages
    },
    onSuccess: () => {
      toast.success(`table has been reset`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
      setResetTablePopOver(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  });
  return (
    <Sheet>
      <SheetTrigger
        asChild
        children={
          <div>
            <TableTrigger tableData={tableData} />
          </div>
        }
      />
      <SheetContent className="flex flex-col w-full max-w-full p-0 pt-4 px-3">
        <div className="mt-8 flex justify-between items-end">
          <SheetTitle className="text-xl">Table {tableData.tableName}</SheetTitle>
          <SheetDescription>
            {tableData && tableData.sessionDetails && (
              <div className="flex-1">
                <Popover open={resetTablePopOver} onOpenChange={setResetTablePopOver}>
                  <PopoverTrigger className="w-full mx-auto" asChild>
                    <Button
                      className="mx-auto  min-w-[80px] "
                      // variant="outline"
                      isLoading={isLoading}
                    >
                      Reset Table
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="z-">
                    <div className="">
                      <p>This will delete all orders and reset the table</p>
                      <div className="flex gap-2 justify-end mt-3">
                        <PopoverClose asChild>
                          <Button variant="secondary">No</Button>
                        </PopoverClose>
                        <PopoverClose asChild>
                          <Button
                            variant="default"
                            onClick={() => {
                              resetTableMutate({ tableId: tableData._id });
                            }}
                          >
                            Yes
                          </Button>
                        </PopoverClose>
                      </div>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            )}
          </SheetDescription>
        </div>
        <SheetBody tableData={tableData} />
      </SheetContent>
    </Sheet>
  );
}
