import { DishInterface } from "@/interfaces/Dish.interface";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { Card } from "../ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { capitalizeFirstLetter, isEmpty } from "@/utils/common";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useCartStore } from "@/store/cartStore";
import { ADMIN } from "@/constans/constants";
import RadioButton from "../common/RadioButton";
import { IngredientType, SizeType } from "@/interfaces/Order.interface";
import FoodTypeIndicator from "../common/FoodTypeIndicator";
import Checkbox from "../common/CheckBox";
import clsx from "clsx";

enum EOperationType {
  ADD = "add",
  REMOVE = "remove",
}
const PopupBody = ({
  dish,
  setOpen,
}: {
  dish: DishInterface;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { addToCart } = useCartStore((state) => ({
    addToCart: state.addToCart,
  }));
  const [radioSelected, setRadioSelected] = useState<SizeType>(
    dish.customisations && dish.customisations.sizes && dish.customisations.sizes[0]
      ? {
          size: dish.customisations.sizes[0].size,
          price: dish.customisations.sizes[0].price,
          sizeId: dish.customisations.sizes[0]._id,
        }
      : {
          size: "",
          price: 0,
          sizeId: "",
        },
  );
  const [checkboxSelected, setCheckboxSelected] = useState<Array<IngredientType>>([]);
  const [totalPrice, setTotalPrice] = useState(0);

  function addToCartHandler() {
    const customisation = {
      ...(radioSelected.size !== "" && radioSelected.price !== 0 && { size: radioSelected }),
      ...(checkboxSelected.length !== 0 && { ingredients: checkboxSelected }),
    };
    addToCart({
      userName: ADMIN,
      dishId: dish._id,
      dishName: dish.name,
      quantity: 1,
      price: totalPrice,
      type: dish.dishType,
      uuid: "",
      ...(!isEmpty(customisation) && { customisation }),
    });
    setOpen(false);
  }

  useEffect(() => {
    let total =
      dish.customisations?.sizes && dish.customisations?.sizes.length > 0
        ? radioSelected.price
        : dish.price;
    checkboxSelected.forEach((item) => {
      total += item.extraPrice;
    });
    setTotalPrice(total);
  }, [radioSelected, checkboxSelected]);

  return (
    <div className="flex flex-col gap-4">
      {dish.customisations?.sizes && dish.customisations?.sizes.length > 0 && (
        <div className="relative border rounded-md p-3">
          <p className="absolute -top-3 left-3 bg-white text-muted-foreground">Size</p>
          {dish.customisations?.sizes
            .sort((a, b) => a.price - b.price)
            .map((item, index) => (
              <div className="w-full flex gap-2 justify-between items-center" key={index}>
                <p>{item.size}</p>
                <div className="flex gap-2">
                  <p>&#8377;{item.price}</p>
                  <RadioButton
                    name={item.size}
                    value={item.price}
                    checked={radioSelected.size === item.size}
                    onChange={() => {
                      setRadioSelected({
                        size: item.size,
                        price: item.price,
                        sizeId: item._id,
                      });
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      )}
      {dish.customisations?.ingredients && dish.customisations?.ingredients.length > 0 && (
        <div className="relative border rounded-md p-3">
          <p className="absolute -top-3 left-3 bg-white text-muted-foreground">Add-ons</p>
          {dish.customisations?.ingredients.map((item, index) => (
            <div className="w-full flex gap-2 justify-between items-center" key={index}>
              <div className="flex gap-2 justify-center items-center">
                <FoodTypeIndicator type={item.type} />
                <p>{item.ingredient}</p>
              </div>
              <div className="flex gap-2">
                <p>+&#8377;{item.extraPrice}</p>
                <Checkbox
                  checked={checkboxSelected.some(
                    (selected) => selected.ingredient === item.ingredient,
                  )}
                  onChange={() => {
                    if (
                      checkboxSelected.some((selected) => selected.ingredient === item.ingredient)
                    ) {
                      setCheckboxSelected((prev) =>
                        prev.filter((selected) => selected.ingredient !== item.ingredient),
                      );
                    } else {
                      setCheckboxSelected((prev) => [
                        ...prev,
                        {
                          ingredient: item.ingredient,
                          extraPrice: item.extraPrice,
                          ingredientId: item._id,
                        },
                      ]);
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <Button onClick={addToCartHandler}>Add | &#8377;{totalPrice}</Button>
    </div>
  );
};

const UpdateDialog = ({
  dish,
  trigger,
  children,
  type,
  open,
  setOpen,
}: {
  dish: DishInterface;
  trigger: ReactElement;
  children: ReactNode;
  type: EOperationType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { addToCart, removeFromCart } = useCartStore((state) => ({
    addToCart: state.addToCart,
    removeFromCart: state.removeFromCart,
  }));

  if (
    dish.customisations &&
    isEmpty(dish.customisations.sizes) &&
    isEmpty(dish.customisations.ingredients)
  ) {
    return (
      <div
        onClick={() => {
          if (type === EOperationType.ADD) {
            addToCart({
              dishId: dish._id,
              userName: ADMIN,
              price: dish.price,
              dishName: dish.name,
              quantity: 1,
              type: dish.dishType,
              uuid: "",
            });
          } else {
            removeFromCart(dish._id);
          }
        }}
      >
        {trigger}
      </div>
    );
  }
  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen((prev) => !prev);
      }}
    >
      <DialogTrigger asChild children={trigger} />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{capitalizeFirstLetter(dish.name)}</DialogTitle>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
};
const DishCard = ({ dish }: { dish: DishInterface }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Card
      className={clsx("flex flex-col w-full p-3", {
        "pointer-events-none opacity-50": !dish.availability || !dish.timeAvailability,
      })}
    >
      <div className="flex gap-0">
        <Avatar className="rounded-lg w-12 h-12 object-cover shadow-sm">
          <AvatarImage
            className="rounded-lg w-12 h-12 object-cover"
            src={dish.imageUrl}
            alt={dish.name}
          />
          <AvatarFallback className="rounded-lg w-12 h-12 object-contain text-xs p-1 text-center text-muted-foreground">
            Upload Image
          </AvatarFallback>
        </Avatar>
        <span className="pl-2 leading-tight text-left">{capitalizeFirstLetter(dish.name)}</span>
      </div>
      <div className="flex justify-between items-center pt-2 gap-2 flex-wrap">
        <div>
          <span>
            <span>&#8377;</span>
            {dish.price}
          </span>
        </div>
        <div className="flex gap-2 justify-center items-center">
          <UpdateDialog
            open={open}
            setOpen={setOpen}
            dish={dish}
            trigger={
              <Button variant="default" className="">
                Add
              </Button>
            }
            type={EOperationType.ADD}
          >
            <PopupBody dish={dish} setOpen={setOpen} />
          </UpdateDialog>
        </div>
      </div>
    </Card>
  );
};

export default DishCard;
