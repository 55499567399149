import { LocalStorageEnum } from "@/constans/enum";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type AuthStoreType = {
  refreshToken: string | null;
  authToken: string | null;
  idToken: string | null;

  setAuthToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  setIdToken: (token: string) => void;
  clearTokens: () => void;

  isAuthenticated: () => boolean;

  getAuthToken: () => string | null;
  getRefreshToken: () => string | null;
  getIdToken: () => string | null;
};

export const useAuthStore = create<AuthStoreType>()(
  persist(
    (set, get) => ({
      refreshToken: null,
      authToken: null,
      idToken: null,

      setAuthToken: (token: string) => set({ authToken: token }),
      setRefreshToken: (token: string) => set({ refreshToken: token }),
      setIdToken: (token: string) => set({ idToken: token }),

      clearTokens: () => set({ authToken: null, refreshToken: null, idToken: null }),

      isAuthenticated: () => !!get().authToken,

      getAuthToken: () => get().authToken,
      getRefreshToken: () => get().refreshToken,
      getIdToken: () => get().idToken,
    }),
    {
      name: LocalStorageEnum.AUTH_STORAGE,
    },
  ),
);
