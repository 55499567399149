import { useEffect, useState } from "react";
import BreadCrumb from "@/components/common/Breadcrumb";
import CategoryCard from "@/components/menu-page/CategoryCard";
import MenuDishCard from "@/components/menu-page/MenuDishCard";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { RouterPaths } from "@/types/RouterPaths";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import { DishInterface, MenuType } from "@/interfaces/Dish.interface";
import { AxiosError } from "axios";
import { ReactQueryEnum } from "@/constans/enum";
import useAxios from "@/hooks/useAxios";
import { capitalizeFirstLetter, parseAxiosError } from "@/utils/common";
import { toast } from "sonner";
import Loading from "@/components/common/Loading";
import { useSearchStore } from "@/store/searchStore";
import NoDishSvg from "../assets/no-data.svg";

export default function MenuPage() {
  const [restaurantCategoryMap, setRestaurantCategoryMap] = useState<{ [key: string]: string }>({});
  const [filteredDishes, setFilteredDishes] = useState<DishInterface[]>([]);
  const { getWithAuth } = useAxios();
  const { value, setPlaceholder, setValue } = useSearchStore();

  const {
    data: menu,
    isError: isSessionError,
    isLoading: isSessionLoading,
    error,
  } = useQuery<MenuType, AxiosError>({
    queryKey: [ReactQueryEnum.MENU, ReactQueryEnum.RESTAURANT_CATEGORIES],
    queryFn: async () => {
      const response = await getWithAuth("/dish/menu");
      return response.data;
    },
  });

  const [activeCategory, setActiveCategory] = useState<string>("all");

  useEffect(() => {
    setPlaceholder("Search for dishes...");
    setValue("");
  }, [setPlaceholder]);

  useEffect(() => {
    if (menu) {
      const categoryMap = Object.fromEntries(
        menu.map((category) => [category.restaurantCategoryId, category.restaurantCategoryName]),
      );
      setRestaurantCategoryMap(categoryMap);
    }
  }, [menu]);

  useEffect(() => {
    if (menu) {
      const filteredDishes =
        activeCategory === "all"
          ? menu.flatMap((category) =>
              category.dishes.filter((dish) =>
                dish.name.toLowerCase().includes(value.toLowerCase()),
              ),
            )
          : menu
              .find((category) => category.restaurantCategoryId === activeCategory)
              ?.dishes.filter((dish) => dish.name.toLowerCase().includes(value.toLowerCase())) ||
            [];

      setFilteredDishes(filteredDishes);
    }
  }, [menu, value, activeCategory]);

  if (isSessionError && error) {
    const { errorMessage } = parseAxiosError(error);
    toast.error(errorMessage);
  }

  if (isSessionLoading) return <Loading />;

  return (
    <div className="w-full p-4 flex flex-col md:px-6 bg-secondary h-full">
      <div className=" w-full bg-secondary  my-2">
        <BreadCrumb />
        <span className="text-primary font-bold text-3xl">Menu</span>
      </div>
      <div className="mb-6">
        <div className="flex gap-1 items-center justify-start ">
          <p className="text-xl font-bold text-primary ">Categories</p>
          <Link to={RouterPaths.EDIT_CATEGORY}>
            <FaEdit className="text-primary opacity-50" />
          </Link>
        </div>
        <div className="w-full flex gap-2 sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-10 overflow-y-auto my-4 pb-2">
          <CategoryCard
            key="all"
            name="all"
            isActive={activeCategory === "all"}
            onClick={() => {
              setActiveCategory("all");
            }}
          />
          {Object.entries(restaurantCategoryMap).map(([id, name]) => (
            <CategoryCard
              key={id}
              name={name}
              isActive={activeCategory === id}
              onClick={() => {
                setActiveCategory(id);
              }}
            />
          ))}
        </div>
      </div>
      <div className="gap-2 flex flex-col items-start w-full h-full">
        <div className="w-full flex justify-between items-center">
          <span className="text-xl font-bold text-red-800">
            {activeCategory === "all"
              ? "All"
              : capitalizeFirstLetter(restaurantCategoryMap[activeCategory])}
          </span>
          <Link to={RouterPaths.ADD_DISH}>
            <Button variant="default">Create Dish</Button>
          </Link>
        </div>

        {activeCategory !== "all" && (
          <div className="w-full grid gap-2 grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 overflow-y-auto my-4 pb-2">
            {filteredDishes.length !== 0 &&
              filteredDishes.map((dish: DishInterface) => (
                <MenuDishCard
                  key={dish._id}
                  name={dish.name}
                  price={dish.price}
                  available={dish.availability}
                  dishId={dish._id}
                  imgUrl={dish.imageUrl}
                  timeAvailability={dish.timeAvailability}
                />
              ))}
          </div>
        )}
        {activeCategory !== "all" && filteredDishes.length === 0 && (
          <div className="flex items-center justify-center w-full flex-1">
            <img src={NoDishSvg} alt="no dishes found" className="h-64" />
          </div>
        )}
        {activeCategory === "all" && (
          <div className="w-full">
            {menu?.map((category) => {
              // Filter dishes based on the search value
              const filteredDishes = category.dishes.filter((dish) =>
                dish.name.toLowerCase().includes(value.toLowerCase()),
              );

              // Only display the category if there are matching dishes
              if (filteredDishes.length > 0) {
                return (
                  <div key={category.restaurantCategoryId}>
                    <span className="text-xl font-bold text-red-800">
                      {capitalizeFirstLetter(category.restaurantCategoryName)}
                    </span>
                    <div className="w-full grid gap-2 grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 overflow-y-auto my-4 pb-2">
                      {filteredDishes.map((dish: DishInterface) => (
                        <MenuDishCard
                          key={dish._id}
                          name={dish.name}
                          price={dish.price}
                          available={dish.availability}
                          dishId={dish._id}
                          imgUrl={dish.imageUrl}
                          timeAvailability={dish.timeAvailability}
                        />
                      ))}
                    </div>
                  </div>
                );
              }

              // Return null if there are no matching dishes
              return null;
            })}
          </div>
        )}
      </div>
    </div>
  );
}
