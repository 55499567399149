import { DiningSession } from "./DiningSession.interface";

export enum SessionStatus {
  OPEN = "open",
  ONGOING = "ongoing",
  CHECKOUT = "checkout",
}

export interface ITable {
  sessionDetails?: DiningSession;
  seatingCapacity: number;
  room: string;
  _id: string;
  tableName: string;
}
