import { LuChevronFirst } from "react-icons/lu";
import smallLogo from "../../assets/cibo_small_logo.png";
import { createContext, useContext, useState, ReactNode, useEffect, useRef } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import { useMediaQuery } from "@mantine/hooks";
import { Input } from "../ui/input";
import { useSearchStore } from "@/store/searchStore";
import { IoNotifications } from "react-icons/io5";
import { RouterPaths } from "@/types/RouterPaths";
import NotificationTile from "../notifications/NotificationTile";
import { useCartStore } from "@/store/cartStore";
import { sidebarIconMap } from "@/constans/sidebarItems";
import { Button } from "../ui/button";
interface SidebarItemProps {
  icon: ReactNode;
  to: string;
  text?: string;
  active?: boolean;
  alert?: boolean;
}

interface SidebarContextProps {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create Sidebar context
export const SidebarContext = createContext<SidebarContextProps>({
  expanded: true,
  setExpanded: () => {},
});

// Sidebar component
export default function Sidebar() {
  const location = useLocation();
  const matches = useMediaQuery("(min-width: 1024px)");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(window.innerWidth >= 768);
  const { value, setValue, placeholder } = useSearchStore();
  const { sidebarItems, notifications, clearNotifications } = useCartStore();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showInputPaths: string[] = [RouterPaths.MENU, RouterPaths.ORDERS, RouterPaths.TABLE];

  useEffect(() => {
    if (matches !== undefined) {
      setExpanded(matches);
    }
  }, [matches]);
  return (
    <>
      <div className="flex lg:hidden p-4 items-center transition-all sticky top-0 bg-background z-10 shadow-lg w-full gap-2 justify-between">
        <div className="">
          <MdMenu
            onClick={() => setExpanded(true)}
            size={25}
            className="mr-auto text-gray-800 cursor-pointer"
          />
        </div>
        {showInputPaths.includes(location.pathname) && (
          <Input
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            className="max-w-sm bg-secondary"
          />
        )}
        <div className="flex items-center">
          <IoNotifications
            size={24}
            className="text-primary hover:text-primary hover:opacity-70 transition-opacity duration-200"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className={clsx(
                "absolute top-full mt-2 right-0 w-64 bg-white border border-gray-300 shadow-lg rounded-md",
                { "p-2": notifications.length === 0 },
              )}
              ref={dropdownRef}
            >
              <div className="flex justify-between px-4 pt-2 items-center">
                <span className="text-lg font-medium">Notifications</span>
                <Button
                  variant="outline"
                  onClick={() => {
                    clearNotifications();
                  }}
                  disabled={notifications.length === 0}
                >
                  Clear
                </Button>
              </div>

              <ul className="flex flex-col items-start w-full">
                {notifications.map((notification) => (
                  <NotificationTile
                    tableName={notification.tableName}
                    type={notification.type}
                    time={new Date(notification.time)}
                  />
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* <img src="/images/cibo_logo_primary_full.svg" alt="Big Logo" className="w-20 " /> */}
        {/* <div className=""></div> */}
      </div>

      <div
        onClick={() => setExpanded(false)}
        className={clsx("w-full h-screen overflow-hidden bg-black/25 absolute z-10 lg:hidden", {
          hidden: !expanded,
        })}
      ></div>
      <motion.div
        initial={{ width: expanded ? 230 : "auto" }}
        animate={{ width: expanded ? 230 : "auto" }}
        transition={{ duration: 0.5 }}
        className="absolute lg:relative  z-10 "
      >
        <aside className={clsx("h-screen transform transition-all  lg:block bg-background")}>
          <nav className="h-full flex flex-col items-start bg-white border-0 border-r shadow-sm ">
            <motion.div
              className={clsx("flex items-center w-full relative h-16  border-b  mb-2", {
                "p-3   justify-between": expanded,
                "p-3 justify-center hidden lg:flex": !expanded,
              })}
            >
              <motion.div
                initial={{ opacity: expanded ? 1 : 0 }}
                animate={{ opacity: expanded ? 1 : 0 }}
                transition={{ duration: 0.5 }}
                className={clsx(
                  " justify-start items-center gap-1 transform transition-all duration-500",
                  {
                    flex: expanded,
                    hidden: !expanded,
                  },
                )}
              >
                <img
                  src="/images/cibo_logo_primary.svg"
                  className="overflow-hidden w-10"
                  alt="Big Logo"
                />
                <p className="text-3xl font-extrabold leading-none text-primary">Cibo</p>
              </motion.div>
              <motion.button
                initial={{
                  rotate: expanded ? 0 : 180,
                }}
                animate={{
                  rotate: expanded ? 0 : 180,
                }}
                transition={{ duration: 0.5 }}
                onClick={() => setExpanded((curr) => !curr)}
                className="relative mt-2 rounded-md p-2 hover:bg-secondary transition-all "
              >
                <LuChevronFirst
                  size={20}
                  className={clsx("text-foreground  transform transition-all duration-300")}
                />
              </motion.button>
            </motion.div>

            <SidebarContext.Provider value={{ expanded, setExpanded }}>
              <ul
                className={clsx("flex-1 px-3 w-full", {
                  "hidden lg:block": !expanded,
                })}
              >
                {sidebarItems.map((item, index) => {
                  return item.isDivider ? (
                    <hr className="my-3 w-full" key={index} />
                  ) : (
                    <SidebarItem
                      key={index}
                      icon={sidebarIconMap[item.text]}
                      text={item.text}
                      alert={item.alert}
                      to={item.to}
                    />
                  );
                })}
              </ul>
            </SidebarContext.Provider>

            <div
              className={clsx("border-t flex p-3 items-center w-full", {
                "hidden lg:block": !expanded,
              })}
            >
              <img src={smallLogo} className="w-10 h-10 rounded-md" alt="Small Logo" />
              <motion.div
                initial={{ opacity: expanded ? 1 : 0 }}
                animate={{ opacity: expanded ? 1 : 0 }}
                transition={{ duration: 0.5 }}
                className={clsx("ml-3 px-2 flex justify-between items-center", {
                  flex: expanded,
                  hidden: !expanded,
                })}
              >
                <div className="leading-4 flex flex-col items-start">
                  <h4 className="font-semibold text-foreground">
                    Cibo
                    <span className="text-sm text-foreground"></span>
                  </h4>
                  <span className="text-xs text-foreground">+91&nbsp;9886444150</span>
                </div>
              </motion.div>
            </div>
          </nav>
        </aside>
      </motion.div>
    </>
  );
}

// SidebarItem component
export function SidebarItem({ icon, text, alert, to }: SidebarItemProps) {
  const { expanded, setExpanded } = useContext(SidebarContext);
  const location = useLocation();
  // const isActive = location.pathname === to;
  const isActive = location.pathname.startsWith(to);
  const matches = useMediaQuery("(max-width: 1024px)");
  return (
    <Link
      to={to}
      onClick={() => matches && setExpanded(false)}
      className={clsx(
        "rounded-md cursor-pointer transform transition-all relative flex items-center justify-start my-1 group font-medium py-2 px-3 ",
        {
          "bg-gradient-to-r from-gradient_primary_start/25 to-gradient_primary_end/0 text-primary":
            isActive,
          "hover:bg-gradient-to-r hover:from-gradient_primary_start/10 hover:to-gradient_primary_end/0 text-secondary-foreground":
            !isActive,
        },
      )}
    >
      {icon}
      <p
        className={clsx("overflow-hidden transition-all text-secondary-foreground font-medium", {
          "ml-3": expanded,
          "w-0": !expanded,
        })}
      >
        {text}
      </p>
      {alert && (
        <div
          className={clsx("absolute ml-1 right-2 w-2 h-2 rounded bg-red-400", {
            "top-2": !expanded,
          })}
        />
      )}
      {!expanded && (
        <div
          className={clsx(
            "z-10 absolute left-full rounded-md px-2 py-1 ml-6 bg-red-100 text-primary/85 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 shadow-2xl",
          )}
        >
          {text}
        </div>
      )}
    </Link>
  );
}
