import BreadCrumb from "@/components/common/Breadcrumb";
import { Input } from "@/components/ui/input";
import { RouterPaths } from "@/types/RouterPaths";
import React, { useEffect, useState } from "react";
import { IoChevronBackSharp } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TimePicker } from "@/components/common/TimePicker";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import {
  FileUploader,
  FileUploaderContent,
  FileUploaderItem,
  FileInput,
} from "@/components/extension/FileUploader";
import { DropzoneOptions } from "react-dropzone";
import { useFormik } from "formik";
import * as yup from "yup";
import { DishType, ReactQueryEnum, SpiceLevel } from "@/constans/enum";
import clsx from "clsx";
import { capitalizeFirstLetter, parseAxiosError } from "@/utils/common";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useAxios from "@/hooks/useAxios";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { RestaurantCategory } from "@/interfaces/RestaurantCategory.interface";
import Loading from "@/components/common/Loading";
import { DishInterface } from "@/interfaces/Dish.interface";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

interface CreateDishProps {
  name: string;
  price: number;
  restaurantCategoryId: string;
  description: string;
  preparationTime: number;
  discount: number;
  spiceLevel: SpiceLevel;
  dishType: DishType;
}

const FileSvgDraw = () => {
  return (
    <>
      <svg
        className="w-8 h-8 mb-3 text-gray-500 dark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
        />
      </svg>
      <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
        <span className="font-semibold">Click to upload</span>
        &nbsp; or drag and drop
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF</p>
    </>
  );
};

const EditDish = () => {
  const location = useLocation();
  const { dishId } = location.state;
  const [files, setFiles] = useState<File[] | null>([]);

  const dropZoneConfig = {
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    multiple: false,
    maxFiles: 1,
    maxSize: 1 * 1024 * 1024,
  } satisfies DropzoneOptions;

  const [fromTime, setFromTime] = useState<Date | undefined>(new Date());
  const [toTime, setToTime] = useState<Date | undefined>(new Date());
  const [availableTimings, setAvailableTimings] = useState<
    {
      from: string;
      to: string;
    }[]
  >([]);

  const [sizes, setSizes] = useState<{ size: string; price: number }[]>([]);
  const [sizeName, setSizeName] = useState<string>("");
  const [sizePrice, setSizePrice] = useState<number>(0);
  const [ingridients, setIngridients] = useState<
    { ingredient: string; extraPrice: number; type: DishType }[]
  >([]);
  const [ingridientName, setIngrididentName] = useState<string>("");
  const [ingridientPrice, setIngridientPrice] = useState<number>(0);
  const [ingridientType, setIngridientType] = useState<DishType>(DishType.VEG);

  const [isLoading, setLoading] = useState(false);

  const { patchWithAuth, getWithAuth, deleteWithAuth } = useAxios();

  const [alwaysAvailable, setAlwaysAvailable] = useState<boolean>(false);
  const [customisationsAvailable, setCustomisationsAvailable] = useState<boolean>(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [restaurantCategoryMap, setRestaurantCategoryMap] = useState<{ [key: string]: string }>({});
  const {
    data: restaurantCategories,
    isError: isCategoryError,
    isLoading: isCategoryLoading,
    error: categroyError,
  } = useQuery<RestaurantCategory[], AxiosError>({
    queryKey: [ReactQueryEnum.RESTAURANT_CATEGORIES],
    queryFn: async () => {
      const response = await getWithAuth("/restaurant-category/categories");
      return response.data;
    },
  });

  const {
    data: dishData,
    isError: isDishError,
    isLoading: isDishLoading,
    error: dishError,
  } = useQuery<DishInterface, AxiosError>({
    queryKey: [ReactQueryEnum.MENU, dishId],
    queryFn: async () => {
      const response = await getWithAuth(`/dish/dish/${dishId}`);
      return response.data;
    },
    // enabled: Object.keys(restaurantCategoryMap.length) > 0,
  });

  if (isCategoryError && categroyError) {
    const { errorMessage } = parseAxiosError(categroyError);
    toast.error(errorMessage);
  }
  if (isDishError && dishError) {
    const { errorMessage } = parseAxiosError(dishError);
    toast.error(errorMessage);
  }

  useEffect(() => {
    if (restaurantCategories) {
      const categoryMap = Object.fromEntries(
        restaurantCategories.map((category) => [category._id, category.categoryName]),
      );
      setRestaurantCategoryMap(categoryMap);
    }
  }, [restaurantCategories]);

  const maxDescriptionLength = 100;
  const maxNameLength = 30;
  const spiceLevelValues = Object.values(SpiceLevel);
  const dishTypeValues = Object.values(DishType);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .max(maxNameLength, `Name must be at most ${maxNameLength} characters`),
    price: yup.number().required("Price is required").min(0, "Price must be greater than 0"),
    restaurantCategoryId: yup.string().required("Category is required"),
    description: yup
      .string()
      .required("Description is required")
      .max(maxDescriptionLength, `Description must be at most ${maxDescriptionLength} characters`),
    preparationTime: yup
      .number()
      .required("Preparation time is required")
      .min(0, "Preparation time must be greater than 0"),
    discount: yup.number(),
    spiceLevel: yup
      .string()
      .required("Spice level is required")
      .oneOf(spiceLevelValues, "must be one of the spice levels"),
    dishType: yup
      .string()
      .required("Dish type is required")
      .oneOf(dishTypeValues, "must be one of the dish types"),
    availableTimings: yup.array().of(
      yup.object({
        from: yup.date().required("From time is required"),
        to: yup.date().required("To time is required"),
      }),
    ),
    customisations: yup.array().of(
      yup.object({
        name: yup.string().required("Customisation name is required"),
        price: yup
          .number()
          .required("Customisation price is required")
          .min(0, "Customisation price must be greater than 0"),
      }),
    ),
  });

  const dishFormik = useFormik({
    initialValues: {
      name: "",
      price: 100,
      restaurantCategoryId: "",
      description: "",
      preparationTime: 10,
      discount: 0,
      spiceLevel: SpiceLevel.NONE,
      dishType: DishType.VEG,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values.spiceLevel !== undefined && values.dishType !== undefined) {
        editMutate({
          name: values.name,
          price: values.price,
          restaurantCategoryId: values.restaurantCategoryId,
          description: values.description,
          preparationTime: values.preparationTime,
          discount: values.discount,
          spiceLevel: values.spiceLevel,
          dishType: values.dishType,
        });
      }
    },
  });

  useEffect(() => {
    if (dishData) {
      dishFormik.setValues({
        name: dishData.name,
        price: dishData.price,
        restaurantCategoryId: dishData.restaurantCategoryId,
        description: dishData.description,
        preparationTime: dishData.preparationTime,
        discount: dishData.discount,
        spiceLevel: dishData.spiceLevel,
        dishType: dishData.dishType,
      });
      setAvailableTimings(dishData.availableTimings);
      setAlwaysAvailable(dishData.availableTimings.length === 0);
      if (dishData.customisations?.sizes) setSizes(dishData.customisations.sizes);
      if (dishData.customisations?.ingredients) setIngridients(dishData.customisations.ingredients);
    }
  }, [dishData, restaurantCategoryMap]);

  const handleDateAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (fromTime && toTime) {
      const fromTimeModified = fromTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      const toTimeModified = toTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      setAvailableTimings((curr) => [...curr, { from: fromTimeModified, to: toTimeModified }]);
      setFromTime(new Date());
      setToTime(new Date());
    }
  };

  const handleRemove = (index: number) => {
    setAvailableTimings((curr) => {
      const temp = [...curr];
      temp.splice(index, 1);
      return temp;
    });
  };

  const handleSizeAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (sizeName && sizePrice) {
      setSizes((curr) => [...curr, { size: sizeName, price: sizePrice }]);
      setSizeName("");
      setSizePrice(0);
    }
  };

  const handleSizeRemove = (index: number) => {
    setSizes((curr) => {
      const temp = [...curr];
      temp.splice(index, 1);
      return temp;
    });
  };

  const handleIngridientAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (ingridientName && ingridientPrice) {
      setIngridients((curr) => [
        ...curr,
        { ingredient: ingridientName, extraPrice: ingridientPrice, type: ingridientType },
      ]);
      setIngrididentName("");
      setIngridientPrice(0);
    }
  };

  const handleIngridentRemove = (index: number) => {
    setIngridients((curr) => {
      const temp = [...curr];
      temp.splice(index, 1);
      return temp;
    });
  };
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate: deleteDishMutate } = useMutation({
    mutationFn: async () => {
      setLoading(true);
      const response = await deleteWithAuth(`/dish/delete/${dishId}`);
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(`${dishData?.name} dish deleted successfully`);
      setDeleteDialogOpen(false);

      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.MENU] });
      navigate(RouterPaths.MENU);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: editMutate } = useMutation({
    mutationFn: async ({
      name,
      price,
      restaurantCategoryId,
      description,
      preparationTime,
      discount,
      spiceLevel,
      dishType,
    }: CreateDishProps) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("price", price.toString());
      formData.append("restaurantCategoryId", restaurantCategoryId);
      formData.append("description", description);
      formData.append("preparationTime", preparationTime.toString());
      if (discount !== undefined) formData.append("discount", discount.toString());
      if (spiceLevel) formData.append("spiceLevel", spiceLevel);
      if (dishType) formData.append("dishType", dishType);

      availableTimings.forEach((timing, index) => {
        formData.append(`availableTimings[${index}][from]`, timing.from);
        formData.append(`availableTimings[${index}][to]`, timing.to);
      });

      sizes.forEach((size, index) => {
        formData.append(`customisations.sizes[${index}][size]`, size.size);
        formData.append(`customisations.sizes[${index}][price]`, size.price.toString());
      });

      ingridients.forEach((ingredient, index) => {
        formData.append(`customisations.ingredients[${index}][ingredient]`, ingredient.ingredient);
        formData.append(
          `customisations.ingredients[${index}][extraPrice]`,
          ingredient.extraPrice.toString(),
        );
        formData.append(`customisations.ingredients[${index}][type]`, ingredient.type);
      });

      if (files?.length === 1) {
        formData.append("file", files[0]);
      }

      const response = await patchWithAuth(`/dish/edit-dish/${dishId}`, formData);
      // return { name };
      return { response, name };
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast(errorMessage);
    },
    onSuccess: ({ name }) => {
      toast(`${name} updated successfully`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.MENU, dishId] });
      navigate(RouterPaths.MENU);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  if (isCategoryLoading || isDishLoading) return <Loading />;

  return (
    <div className="w-full p-4 md:px-6 bg-secondary">
      <div className=" w-full bg-secondary z-[100] my-4">
        <Link to={RouterPaths.MENU}>
          <IoChevronBackSharp className="mb-4" />
        </Link>
        <BreadCrumb />
        <div className="flex justify-between items-center">
          <p className="text-primary font-bold text-3xl mt-4">Edit Dish</p>
          <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="destructive">Delete Dish</Button>
            </DialogTrigger>
            <DialogContent className="">
              <DialogHeader>
                <DialogTitle className="">Delete dish?</DialogTitle>
                <DialogDescription className="pb-4">
                  <p>Are you sure you want to delete the {dishData?.name} dish?</p>
                </DialogDescription>
                <DialogFooter>
                  <Button variant="ghost" onClick={() => setDeleteDialogOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      deleteDishMutate();
                      setDeleteDialogOpen(false);
                    }}
                  >
                    Delete
                  </Button>
                </DialogFooter>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <form className="flex flex-col gap-2" onSubmit={dishFormik.handleSubmit}>
        <div className="flex flex-wrap gap-2 justify-between items-start">
          <div className="flex-grow min-w-[250px]">
            <p className="">Dish Name</p>
            <Input
              placeholder="Dish name"
              className="bg-input-secondary"
              id="name"
              name="name"
              value={dishFormik.values.name}
              onChange={dishFormik.handleChange}
            />
            <p
              className={clsx("text-xs text-gray-500", {
                "text-red-500": dishFormik.values.name.length > maxNameLength,
              })}
            >
              {dishFormik.values.name.length}/{maxNameLength} characters
            </p>
            {dishFormik.errors.name && (
              <p className="text-red-500 text-sm">*{dishFormik.errors.name}</p>
            )}
          </div>
          <div className="flex-grow min-w-[250px]">
            <p className="">Price</p>
            <Input
              type="number"
              placeholder="Dish price"
              className="bg-input-secondary"
              id="price"
              name="price"
              value={dishFormik.values.price}
              onChange={dishFormik.handleChange}
            />
            {dishFormik.errors.price && (
              <p className="text-red-500 text-sm">*{dishFormik.errors.price}</p>
            )}
          </div>
          <div className="flex-grow min-w-[250px]">
            <p className="">Category</p>

            <Select
              value={dishFormik.values.restaurantCategoryId}
              onValueChange={(value) => dishFormik.setFieldValue("restaurantCategoryId", value)}
            >
              <SelectTrigger className=" bg-input-secondary ">
                <SelectValue placeholder="Select dish Category" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(restaurantCategoryMap).map(([id, name]) => (
                  <SelectItem key={id} value={id}>
                    {capitalizeFirstLetter(name)}
                    {/* {id} */}
                  </SelectItem>
                ))}
              </SelectContent>
              {dishFormik.errors.restaurantCategoryId && (
                <p className="text-red-500 text-sm">*{dishFormik.errors.restaurantCategoryId}</p>
              )}
            </Select>
          </div>
        </div>
        <div className="">
          <p className="">Description</p>
          <textarea
            name="description"
            className="w-full h-20 bg-input-secondary rounded-md p-2"
            placeholder="Description of the dish"
            value={dishFormik.values.description}
            onChange={dishFormik.handleChange}
          ></textarea>
          <p
            className={clsx("text-xs text-gray-500", {
              "text-red-500": dishFormik.values.description.length > maxDescriptionLength,
            })}
          >
            {dishFormik.values.description.length}/{maxDescriptionLength} characters
          </p>

          {dishFormik.errors.description && (
            <p className="text-sm text-red-500">*{dishFormik.errors.description}</p>
          )}
        </div>
        <div className="flex flex-wrap gap-2 justify-between items-start">
          <div className="flex-grow min-w-[250px]">
            <p className="">Preparation Time</p>
            <Input
              placeholder="Preparation time in minutes"
              className="bg-input-secondary"
              name="preparationTime"
              value={dishFormik.values.preparationTime}
              onChange={dishFormik.handleChange}
            />
            {dishFormik.errors.preparationTime && (
              <p className="text-red-500 text-sm">*{dishFormik.errors.preparationTime}</p>
            )}
          </div>
          <div className="flex-grow min-w-[250px]">
            <p className="">Discount</p>
            <Input
              type="number"
              placeholder="Discount in percentage if applicable"
              className="bg-input-secondary"
              name="discount"
              value={dishFormik.values.discount}
              onChange={dishFormik.handleChange}
            />
            {dishFormik.errors.discount && (
              <p className="text-red-500 text-sm">*{dishFormik.errors.discount}</p>
            )}
          </div>
          <div className="flex-grow min-w-[250px]">
            <p className="">Spice Level</p>
            <Select
              value={dishFormik.values.spiceLevel}
              onValueChange={(value) => dishFormik.setFieldValue("spiceLevel", value)}
            >
              <SelectTrigger className=" bg-input-secondary ">
                <SelectValue placeholder="Select dish spice level" />
              </SelectTrigger>
              <SelectContent>
                {spiceLevelValues.map((spiceLevel, index) => (
                  <SelectItem key={index} value={spiceLevel}>
                    {capitalizeFirstLetter(spiceLevel)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {dishFormik.errors.spiceLevel && (
              <p className="text-red-500 text-sm">*{dishFormik.errors.spiceLevel}</p>
            )}
          </div>
          <div className="flex-grow min-w-[250px]">
            <p className="">Dish Type</p>
            <Select
              value={dishFormik.values.dishType}
              onValueChange={(value) => dishFormik.setFieldValue("dishType", value)}
            >
              <SelectTrigger className=" bg-input-secondary ">
                <SelectValue placeholder="Select dish type" />
              </SelectTrigger>
              <SelectContent>
                {dishTypeValues.map((dishType, index) => (
                  <SelectItem key={index} value={dishType}>
                    {capitalizeFirstLetter(dishType)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {dishFormik.errors.dishType && (
              <p className="text-red-500 text-sm">*{dishFormik.errors.dishType}</p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-2 justify-between items-center mt-2">
          <div className="flex-grow flex gap-2 justify-start items-center min-w-[250px]">
            <p className="">Always available</p>
            <Switch
              checked={alwaysAvailable}
              onCheckedChange={() => {
                setAlwaysAvailable((curr) => !curr);
              }}
              name="always-available"
            />
          </div>
        </div>

        {!alwaysAvailable && (
          <div className="border p-2 rounded-md">
            <div className="flex flex-wrap gap-2 justify-between items-center">
              <div className="flex-grow min-w-[250px]">
                <div className="mt-1">
                  <p className="">From</p>
                  <TimePicker date={fromTime} setDate={setFromTime} />
                </div>
              </div>
              <div className="flex-grow min-w-[250px]">
                <div className="mt-1">
                  <p className="">To</p>
                  <TimePicker date={toTime} setDate={setToTime} />
                </div>
              </div>
              <Button variant="default" className="mt-6" onClick={handleDateAdd}>
                Add
              </Button>
            </div>
            {availableTimings.length > 0 && (
              <div className=" mt-3 flex flex-col flex-wrap gap-2 justify-between items-start border rounded-md py-2">
                {availableTimings.map((timing, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-[3fr_1fr_3fr_3fr] place-items-center text-sm"
                  >
                    <p>{timing.from}</p>
                    <p> - </p>
                    <p>{timing.to}</p>
                    <Button
                      variant="link"
                      type="button"
                      onClick={() => {
                        handleRemove(index);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <div className="flex flex-wrap gap-2 justify-between items-center mt-2">
          <div className="flex-grow flex gap-2 justify-start items-center min-w-[250px]">
            <p className="">Customisations</p>
            <Switch
              checked={customisationsAvailable}
              onCheckedChange={() => {
                setCustomisationsAvailable((curr) => !curr);
              }}
              name="always-available"
            />
          </div>
        </div>

        {customisationsAvailable && (
          <div className="border p-2 rounded-md">
            <p>Size</p>
            <div className=" flex flex-wrap gap-2 justify-between items-center">
              <div className="flex-grow min-w-[250px]">
                <p className="text-muted-foreground">Name</p>
                <Input
                  value={sizeName}
                  onChange={(e) => {
                    setSizeName(e.target.value);
                  }}
                  type="string"
                  placeholder="Dish size"
                  className="bg-input-secondary"
                />
              </div>
              <div className="flex-grow min-w-[250px]">
                <p className="text-muted-foreground">Price</p>
                <Input
                  value={sizePrice}
                  onChange={(e) => {
                    setSizePrice(parseInt(e.target.value));
                  }}
                  type="number"
                  placeholder="Size price"
                  className="bg-input-secondary"
                />
              </div>
              <Button variant="default" className="mt-6" onClick={handleSizeAdd}>
                Add
              </Button>
            </div>
            {sizes.length > 0 && (
              <div className="border rounded-md p-2 mt-2">
                {sizes.length > 0 &&
                  sizes.map((size, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-[3fr_1fr_3fr_3fr] items-center text-sm  gap-2"
                    >
                      <p className="max-w-[200px]  break-words">{size.size}</p>
                      <p>: </p>
                      <p> &#8377;{size.price}</p>
                      <Button
                        variant="link"
                        type="button"
                        onClick={() => {
                          handleSizeRemove(index);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
              </div>
            )}

            <p className="mt-4">Other Customisations</p>
            <div className=" flex flex-wrap gap-2 justify-between items-center">
              <div className="flex-grow min-w-[250px]">
                <p className="text-muted-foreground">Name</p>
                <Input
                  value={ingridientName}
                  onChange={(e) => {
                    setIngrididentName(e.target.value);
                  }}
                  type="string"
                  placeholder="Customisation Name"
                  className="bg-input-secondary"
                />
              </div>
              <div className="flex-grow min-w-[250px]">
                <p className="text-muted-foreground">Price</p>
                <Input
                  value={ingridientPrice}
                  onChange={(e) => {
                    setIngridientPrice(parseInt(e.target.value));
                  }}
                  type="number"
                  placeholder="Customisation price"
                  className="bg-input-secondary"
                />
              </div>
              <div className="flex-grow min-w-[250px]">
                <p className="">Dish Type</p>
                <Select
                  value={ingridientType}
                  onValueChange={(value: DishType) => setIngridientType(value)}
                >
                  <SelectTrigger className=" bg-input-secondary ">
                    <SelectValue placeholder="Select dish type" />
                  </SelectTrigger>
                  <SelectContent>
                    {dishTypeValues.map((dishType, index) => (
                      <SelectItem key={index} value={dishType}>
                        {capitalizeFirstLetter(dishType)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <Button variant="default" className="mt-6" onClick={handleIngridientAdd}>
                Add
              </Button>
            </div>
            {ingridients.length > 0 && (
              <div className="border rounded-md p-2 mt-2">
                {ingridients.length > 0 &&
                  ingridients.map((ingridient, index) => (
                    <div key={index} className="flex justify-start items-center text-sm  gap-2">
                      <p className="max-w-[200px]  break-words">{ingridient.ingredient}</p>
                      <p>: </p>
                      <p> &#8377;{ingridient.extraPrice}</p>

                      <Button
                        variant="link"
                        type="button"
                        onClick={() => {
                          handleIngridentRemove(index);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}

        <div className="mt-2">
          <p>Upload Dish Image</p>
          <FileUploader
            className="mt-1"
            value={files}
            onValueChange={setFiles}
            dropzoneOptions={dropZoneConfig}
          >
            {/* <FileInput>
              <div className="flex items-center justify-center h-32 w-full border bg-background rounded-md">
                <p className="text-gray-400">Drop files here</p>
              </div>
            </FileInput> */}
            <div className="">
              <FileInput className="border">
                <div className="flex items-center justify-center flex-col pt-3 pb-4 w-full ">
                  <FileSvgDraw />
                </div>
              </FileInput>
            </div>
            <FileUploaderContent className="flex items-center flex-row gap-2">
              {files?.map((file, i) => (
                <FileUploaderItem
                  key={i}
                  index={i}
                  className="size-20 p-0 rounded-md overflow-hidden"
                  aria-roledescription={`file ${i + 1} containing ${file.name}`}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    height={80}
                    width={80}
                    className="size-20 p-0"
                  />
                </FileUploaderItem>
              ))}
            </FileUploaderContent>
          </FileUploader>
        </div>
        <div className="w-full flex justify-center items-center">
          <Button className="mt-4 w-[50%]" variant="default" type="submit" isLoading={isLoading}>
            Edit Dish
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditDish;
