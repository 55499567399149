import { PasswordInput } from "../ui/password-input";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { Button } from "../ui/button";
import clsx from "clsx";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { Label } from "@radix-ui/react-label";
import useAxios from "@/hooks/useAxios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { parseAxiosError } from "@/utils/common";
import { toast } from "sonner";

const ProfileSettings = () => {

  const [loading,setLoading] = useState(false);
  const { patchWithAuth } = useAxios();

  const { mutate : changePasswordMutate } = useMutation({
    mutationFn: async ({ newPassword, oldPassword }: { newPassword: string, oldPassword : string }) => {
      setLoading(true);
      const response = await patchWithAuth("/auth-pos/change-password", {
        newPassword: newPassword,
        oldPassword: oldPassword,
      });
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success("Password changed successfully");
    },
    onSettled: () => {
      setLoading(false);
    }
  });


  const validationSchema = yup.object({
    oldPassword: yup.string().required("Old password is required"),
    newPassword: yup
      .string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
        "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number",
      )
      .notOneOf([yup.ref('oldPassword')], "New password cannot be the same as the old password"),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), undefined], "Passwords must match")
      .required("Confirm password is required"),
  });

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      changePasswordMutate({ newPassword: values.newPassword, oldPassword: values.oldPassword });
    },
  });

  const updatePasswordErrors = () => {
    setPasswordError([
      {
        name: "one uppercase character",
        condition: !/(?=.*[A-Z])/.test(passwordFormik.values.newPassword),
      },
      {
        name: "one number",
        condition: !/\d/.test(passwordFormik.values.newPassword),
      },
      {
        name: "one lowercase character",
        condition: !/(?=.*[a-z])/.test(passwordFormik.values.newPassword),
      },
      {
        name: "do not match",
        condition: !(
          passwordFormik.values.newPassword === passwordFormik.values.confirmNewPassword &&
          passwordFormik.values.newPassword.length !== 0
        ),
      },
      {
        name: "8 characters minimum",
        condition: passwordFormik.values.newPassword.length < 8,
      },
      {
        name: "new password cannot be the same as old password",
        condition: passwordFormik.values.newPassword === passwordFormik.values.oldPassword,
      },
    ]);
  };

  useEffect(() => {
    updatePasswordErrors();
  }, [passwordFormik.values]);

  const [passwordError, setPasswordError] = useState([
    {
      name: "password should be at least 8 characters long",
      condition: false,
    },
    {
      name: "password should contain at least one number",
      condition: false,
    },
    {
      name: "passwords do not match",
      condition: false,
    },
    {
      name: "password should contain at least one uppercase letter",
      condition: false,
    },
    {
      name: "new password cannot be the same as old password",
      condition: false,
    },
  ]);

  return (
    <form onSubmit={passwordFormik.handleSubmit}>
      <h1 className="text-lg font-medium text-primary">Change Password</h1>
      <div className="flex flex-col md:flex-row items-start mt-2">
        <div className="w-full md:basis-1/2">
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="oldPassword">Old password</Label>
            </div>
            <PasswordInput
              id="oldPassword"
              type="password"
              required
              name="oldPassword"
              value={passwordFormik.values.oldPassword}
              onChange={passwordFormik.handleChange}
            />
          </div>
          <div className="grid gap-2 mt-2">
            <div className="flex items-center">
              <Label htmlFor="newPassword">New password</Label>
            </div>
            <PasswordInput
              id="newPassword"
              type="password"
              required
              name="newPassword"
              value={passwordFormik.values.newPassword}
              onChange={passwordFormik.handleChange}
            />
          </div>
          <div className="grid gap-2 mt-2">
            <div className="flex items-center">
              <Label htmlFor="confirmNewPassword">Confirm new password</Label>
            </div>
            <PasswordInput
              id="confirmNewPassword"
              type="password"
              required
              name="confirmNewPassword"
              value={passwordFormik.values.confirmNewPassword}
              onChange={passwordFormik.handleChange}
            />
          </div>
        </div>

        <div className="w-full md:basis-1/2 md:px-4 mt-2 md:pt-0">
          <div className="flex flex-col gap-1 w-full">
            {passwordError.map((error) => {
              return (
                <div
                  key={error.name}
                  className="flex items-center gap-1"
                >
                  <div
                    className={clsx("p-1 rounded-full flex items-center justify-center", {
                      "bg-red-500": error.condition,
                      "bg-green-500": !error.condition,
                    })}
                  >
                    {!error.condition && <FaCheck className="w-1.5 h-1.5 text-white" />}
                    {error.condition && <ImCross className="w-1.5 h-1.5 text-white" />}
                  </div>
                  <p
                    className={clsx("pl-1 pb-1", {
                      "text-red-500": error.condition,
                      "text-green-500": !error.condition,
                    })}
                  >
                    {error.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Button
        type="submit"
        isLoading = {loading}
        className="w-full md:w-1/2 mt-4"
        disabled={!passwordFormik.isValid || !passwordFormik.dirty}
      >
        Change password
      </Button>
    </form>
  );
};

export default ProfileSettings;
