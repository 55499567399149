import CategoryCard from "@/components/menu-page/CategoryCard";
import PendingOrderCard from "@/components/orders-page/PendingOrderCard";
import { useState, useMemo, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAxios from "@/hooks/useAxios";
import { AxiosError } from "axios";
import { parseAxiosError } from "@/utils/common";
import { toast } from "sonner";
import OrderCard from "@/components/orders-page/OrderCard";
import { OrderResponse } from "@/interfaces/OrderResponse.interface";
import Loading from "@/components/common/Loading";
import { NotificationType, OrderStatus, ReactQueryEnum } from "@/constans/enum";
import BreadCrumb from "@/components/common/Breadcrumb";
import { useSearchStore } from "@/store/searchStore";
import NoDishSvg from "../assets/no-data.svg";
import { useCartStore } from "@/store/cartStore";

export default function OrdersPage() {
  const [activeCategory, setActiveCategory] = useState("All");
  const { getWithAuth } = useAxios();
  const queryClient = useQueryClient();
  const { value, setPlaceholder, setValue } = useSearchStore();
  const { setAlert, socketMessage } = useCartStore((state) => ({
    socketMessage: state.socketMessage,
    setAlert: state.setAlert,
  })); // Use the store function to set the alert

  const {
    data: orders,
    isError: isSessionError,
    isLoading: isSessionLoading,
    error,
  } = useQuery<OrderResponse[], AxiosError>({
    queryKey: [ReactQueryEnum.DINING_SESSIONS],
    queryFn: async () => {
      const response = await getWithAuth("/dining-session/orders");
      return response.data;
    },
  });

  useEffect(() => {
    if (orders) {
      // Check if there is at least one pending order
      const hasPendingOrder = orders.some((order) => order.orderStatus === OrderStatus.PENDING);
      setAlert("Orders", hasPendingOrder); // Update sidebar alert state
    }
  }, [orders, setAlert]);

  if (isSessionError && error) {
    const { errorMessage } = parseAxiosError(error);
    toast.error(errorMessage);
  }

  useEffect(() => {
    if (socketMessage && socketMessage.startsWith(NotificationType.PLACE_ORDER)) {
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.DINING_SESSIONS] });
    }
  }, [socketMessage]);
  useEffect(() => {
    setPlaceholder("Search by order id or table name");
    setValue("");
  }, [setPlaceholder]);

  const filteredOrders = useMemo(() => {
    if (!orders) return []; // Handle case where orders are undefined
    return orders
      .filter((order) => {
        if (activeCategory === "All") return true;
        if (activeCategory === "Pending") return order.orderStatus === OrderStatus.PENDING;
        if (activeCategory === "Confirmed") return order.orderStatus === OrderStatus.CONFIRMED;
        if (activeCategory === "Served") return order.orderStatus === OrderStatus.SERVED;
        if (activeCategory === "Rejected") return order.orderStatus === OrderStatus.REJECTED;
        return false;
      })
      .filter(
        (order) => order.orderId.toString().includes(value) || order.tableName.includes(value),
      ); // Filter based on orderNo
  }, [orders, activeCategory, value]);

  if (isSessionLoading) return <Loading />;
  return (
    <div className="w-full h-full flex flex-col p-4 md:px-6 bg-secondary">
      <div className="my-2">
        <BreadCrumb />
        <div className="flex justify-between w-full ">
          <span className="text-primary font-bold text-3xl">Orders</span>
          {/* <Link to={RouterPaths.ORDER_HISTORY}>
            <Button variant="outline">Order History</Button>
          </Link> */}
        </div>
      </div>

      <div className="grid grid-cols-5 gap-2 py-4 w-full">
        {["All", "Pending", "Confirmed", "Served", "Rejected"].map((category) => (
          <CategoryCard
            key={category}
            name={category}
            isActive={activeCategory === category}
            onClick={() => setActiveCategory(category)}
          />
        ))}
      </div>
      {filteredOrders.length > 0 && (
        <div className="w-full grid h-full grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 min-[1700px]:grid-cols-4 gap-4 py-4 items-start">
          {filteredOrders.map((order, index) =>
            order.orderStatus === OrderStatus.PENDING ? (
              <PendingOrderCard
                key={index}
                tableName={order.tableName}
                order={order}
                sessionId={order.sessionId}
              />
            ) : (
              <OrderCard
                key={index}
                tableName={order.tableName}
                order={order}
                sessionId={order.sessionId}
              />
            ),
          )}
        </div>
      )}

      {filteredOrders.length === 0 && (
        <div className="flex items-center justify-center w-full flex-1">
          <img src={NoDishSvg} alt="no dishes found" className="h-64 " />
        </div>
      )}
    </div>
  );
}
