import { LocalStorageEnum } from "@/constans/enum";
import { CartInterface, CustomisationType } from "@/interfaces/Order.interface";
import { CustomisationDto, OrderedDishDto } from "@/interfaces/OrderDto.interface";
import { AxiosError } from "axios";

export function isActiveRoute(route: string): boolean {
  const location = window.location.pathname;
  return location === route;
}

interface ParsedError {
  errorMessage: string;
  statusCode: number | null;
}

export const parseAxiosError = (error: AxiosError): ParsedError => {
  let errorMessage = "An unexpected error occurred.";
  let statusCode: number | null = null;

  if (error.response) {
    const { data, status } = error.response;
    errorMessage = (data as any).message || errorMessage; // assuming the server sends back an error message
    statusCode = status;
  } else if (error.request) {
    errorMessage = "No response received from the server. Please check your network connection.";
  } else {
    errorMessage = error.message;
  }

  return { errorMessage, statusCode };
};

export function capitalizeFirstLetterOfEachWord(str: string): string {
  return str
    .split(" ")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
}

export function capitalizeFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function getFormattedDate(dateString: string) {
  // Parse the input date string into a Date object
  const date = new Date(dateString);

  const optionsFirst: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "short",
  };

  const optionsSecond: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return [date.toLocaleString("en-US", optionsFirst), date.toLocaleString("en-US", optionsSecond)];
}

export function isAuthenticated(): boolean {
  //check for autToken, sessionID
  const auth = localStorage.getItem(LocalStorageEnum.AUTH_STORAGE);
  if (auth) {
    const { state } = JSON.parse(auth);
    const { authToken } = state;
    return !!authToken;
  }
  return false;
}

export function time24HourFormat(date: Date) {
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  const time24HourFormat = `${hours}:${minutes}`;
  return time24HourFormat;
}

export function createInitialTime(hours: number, minutes: number): Date {
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
}

export function convertTimeStringToDate(timeString: string): Date {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
}
6;
export function timeSince(date: Date) {
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours === 0) {
    return `${minutes} ${minutes === 1 ? "min" : "mins"}`;
  }

  const hoursDisplay = hours === 1 ? `${hours} hr` : `${hours} hrs`;
  const minutesDisplay = minutes === 1 ? `${minutes} min` : `${minutes} mins`;

  return `${hoursDisplay} ${minutesDisplay}`;
  // return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

export function getAuthToken() {
  try {
    const auth = localStorage.getItem(LocalStorageEnum.AUTH_STORAGE);
    if (auth) {
      const { state } = JSON.parse(auth);
      const { authToken } = state;
      return authToken;
    }
    return "";
  } catch (e) {
    return "";
  }
}

export function findDishIndex(cart: Array<CartInterface>, dish: CartInterface): number {
  return cart.findIndex(
    (cartItem) =>
      cartItem.dishId === dish.dishId &&
      cartItem.userName === dish.userName &&
      JSON.stringify(cartItem.customisation) === JSON.stringify(dish.customisation),
  );
}

export function isEmpty<T>(input: T): boolean {
  if (input == null) {
    // Checks for null or undefined
    return true;
  }

  if (typeof input === "string") {
    return input.length === 0;
  }

  if (Array.isArray(input)) {
    return input.length === 0;
  }

  if (typeof input === "object") {
    return Object.keys(input as object).length === 0;
  }

  return false;
}

export function getCustomisation(customisations: CustomisationType) {
  const size = customisations.size ? customisations.size.size : "";
  const ingredients = customisations.ingredients
    ? customisations.ingredients.map((item) => item.ingredient).join(" • ")
    : "";

  return [size, ingredients].filter((item) => item.length > 0).join(" • ");
}

export function getIngredients(customisations: CustomisationType) {
  const ingredients = customisations.ingredients
    ? customisations.ingredients.map((item) => item.ingredient).join(" • ")
    : "";

  return ingredients;
}

export function getSize(customisations: CustomisationType) {
  const size = customisations.size ? customisations.size.size : "";
  return size;
}

export function parseCart(cart: Array<CartInterface>): OrderedDishDto[] {
  const orderedDishes: OrderedDishDto[] = [];
  cart.forEach((item) => {
    const customisation: CustomisationDto = {};
    if (item.customisation?.size) {
      customisation.sizeId = item.customisation.size.sizeId;
    }
    if (item.customisation?.ingredients) {
      customisation.ingredientIds = item.customisation.ingredients.map(
        (ingredient) => ingredient.ingredientId,
      );
    }
    orderedDishes.push({
      dishId: item.dishId,
      quantity: item.quantity,
      userName: item.userName,
      ...(Object.keys(customisation).length > 0 && { customisation }),
    });
  });
  return orderedDishes;
}

export function formatTimeAgo(time: Date): string {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - time.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`;
  } else if (diffInSeconds < 6000) {
    // 10 minutes = 600 seconds
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return `${diffInMinutes}m`;
  }

  return "1h+";
}
