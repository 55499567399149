import Sidebar from "@/components/common/Sidebar";
import { Outlet } from "react-router-dom";
import { Input } from "../ui/input";
import { useSearchStore } from "@/store/searchStore";
import { IoNotifications } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import NotificationTile from "../notifications/NotificationTile";
import { useCartStore } from "@/store/cartStore";
import { Button } from "../ui/button";
import clsx from "clsx";

const Layout = ({ showSearch }: { showSearch: boolean }) => {
  const { value, setValue, placeholder } = useSearchStore();
  const { notifications, clearNotifications } = useCartStore();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex w-full bg-background lg:flex-row flex-col h-screen">
      <Sidebar />
      <div className="relative flex-1 overflow-x-auto bg-secondary flex flex-col">
        <div className="h-16 sticky top-0 hidden lg:flex items-center py-4 px-4 w-full bg-background shadow-sm border-b   z-10">
          {showSearch && (
            <Input
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              className="max-w-sm bg-secondary"
            />
          )}
          <div className="fixed right-6 flex items-center">
            <IoNotifications
              size={24}
              className="text-primary hover:text-primary hover:opacity-70 transition-opacity duration-200"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className={clsx(
                  "absolute top-full mt-2 right-0 w-64 bg-white border border-gray-300 shadow-lg rounded-md",
                  { "p-2": notifications.length === 0 },
                )}
                ref={dropdownRef}
              >
                <div className="flex justify-between px-4 pt-2 items-center">
                  <span className="text-lg font-medium">Notifications</span>
                  <Button
                    variant="outline"
                    onClick={() => {
                      clearNotifications();
                    }}
                    disabled={notifications.length === 0}
                  >
                    Clear
                  </Button>
                </div>
                <ul className="flex flex-col items-start w-full">
                  {notifications.map((notification) => (
                    <NotificationTile
                      tableName={notification.tableName}
                      type={notification.type}
                      time={new Date(notification.time)}
                    />
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className=" flex-1 min-h-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
