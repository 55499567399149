import MenuPage from "@/pages/MenuPage";
import { Login } from "@/pages/Login";
import { RouterPaths } from "@/types/RouterPaths";

import { Routes, Route, useLocation } from "react-router-dom";
import ChangePasswordPage from "@/pages/ChangePasswordPage";
import OrdersPage from "@/pages/OrdersPage";
import EditCategory from "@/pages/EditCategoryPage";
import TablesPage from "@/pages/TablesPage";
import ProtectedRoute, { HomeProtectedRoute, LoginProtectedRoute } from "@/router/ProtectedRoute";
import EditDish from "@/pages/EditDishPage";
import Settings from "@/pages/Settings";
import CreateDish from "@/pages/CreateDIshPage";
// import OrderHistoryPage from "@/pages/OrderHistoryPage";
import BillPage from "@/pages/BillPage";
import Layout from "@/components/common/Layout";
import AddOrder from "@/pages/AddOrder";
import { useCartStore } from "@/store/cartStore";
import { useEffect } from "react";
import FeedbackPage from "@/pages/FeedbackPage";

export const POSRoutes = () => {
  const location = useLocation();
  const { clearCart } = useCartStore((state) => ({
    clearCart: state.clearCart,
  }));

  useEffect(() => {
    const pathName = location.pathname;
    if (pathName === RouterPaths.ADD_ORDER) {
      clearCart();
    }
  }, [location.pathname, clearCart]);
  return (
    <Routes>
      <Route path={RouterPaths.HOME} element={<HomeProtectedRoute />} />
      <Route
        path={RouterPaths.LOGIN}
        element={
          <LoginProtectedRoute>
            <Login />
          </LoginProtectedRoute>
        }
      />
      <Route
        path={RouterPaths.CHANGE_PASSWORD}
        element={
          <LoginProtectedRoute>
            <ChangePasswordPage />
          </LoginProtectedRoute>
        }
      />
      <Route
        element={
          <ProtectedRoute>
            <Layout showSearch={true} />
          </ProtectedRoute>
        }
      >
        {/* <Route path={RouterPaths.DASHBOARD} element={<p>dashboard</p>} /> */}
        <Route path={RouterPaths.MENU} element={<MenuPage />} />
        <Route path={RouterPaths.ORDERS} element={<OrdersPage />} />
        <Route path={RouterPaths.TABLE} element={<TablesPage />} />
        <Route path={RouterPaths.ADD_ORDER} element={<AddOrder />} />

        <Route
          path={RouterPaths.HELP}
          element={
            <div className="w-full h-screen grid place-items-center">{RouterPaths.HELP}</div>
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute>
            <Layout showSearch={false} />
          </ProtectedRoute>
        }
      >
        <Route path={RouterPaths.EDIT_CATEGORY} element={<EditCategory />} />
        <Route path={RouterPaths.ADD_DISH} element={<CreateDish />} />
        <Route path={RouterPaths.EDIT_DISH} element={<EditDish />} />
        <Route path={RouterPaths.BILLS} element={<BillPage />} />
        <Route path={RouterPaths.SETTINGS} element={<Settings />} />
        <Route path={RouterPaths.FEEDBACK} element={<FeedbackPage />} />
      </Route>

      <Route path={RouterPaths.ANY} element={<p>404</p>} />
    </Routes>
  );
};
