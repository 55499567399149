export enum RouterPaths {
  BILLS = "/bill",
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
  MENU = "/menu",
  ORDERS = "/orders",
  ORDER_HISTORY = "/orders/order-history",
  CUSTOMERS = "/customers",
  REPORTS = "/reports",
  SETTINGS = "/settings",
  PROFILE = "/profile",
  NOT_FOUND = "/404",
  CHANGE_PASSWORD = "/change-password",
  FORGOT_PASSWORD = "/forgot-password",
  TABLE = "/table",
  HELP = "/help",
  CREATE_DISH = "/create-dish",
  EDIT_CATEGORY = "/menu/category",
  EDIT_DISH = "/menu/edit-dish",
  ADD_DISH = "/menu/add-dish",
  HOME = "/",
  ANY = "*",
  ADD_ORDER = "/table/add-order",
  FEEDBACK = "/feedback",
}
