import React, { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { BillCard } from "../bill-page/bill-card";
import { BillResponse } from "@/interfaces/Bill.interface";
import { Button } from "../ui/button";
import useAxios from "@/hooks/useAxios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { parseAxiosError } from "@/utils/common";
import { toast } from "sonner";
import { ReactQueryEnum } from "@/constans/enum";
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";

const PrintBill = ({
  modalOpen,
  setModaOpen,
  sessionId,
  bill,
}: {
  modalOpen: boolean;
  setModaOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sessionId: string;
  bill: BillResponse;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { patchWithAuth } = useAxios();
  const queryClient = useQueryClient();

  const { mutate: checkoutMutate } = useMutation({
    mutationFn: async () => {
      setLoading(true);
      const response = await patchWithAuth(`/dining-session/${sessionId}/checkout`);
      return response.data;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage); // Ensure toast.error is used for error messages
    },
    onSuccess: () => {
      toast.success(`table ${bill.tableName} has been checked out`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.TABLES] });
      setModaOpen(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  return (
    <Dialog open={modalOpen} onOpenChange={setModaOpen}>
      <DialogContent className="w-full md:max-w-[600px]">
        <DialogHeader className="text-xl font-semibold">
          <DialogTitle>Payment</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col md:flex-row gap-4 max-h-[75vh] overflow-y-auto">
          <div className="flex flex-col gap-4">
            {bill ? <BillCard bill={bill} /> : <p>Loading...</p>}
          </div>
          <div className="flex flex-col gap-2 h-full ">
            {/* <span className="text-tertiary font-medium mt-2">Choose these options to close the table</span> */}
            {/* <span className="text-tertiary font-medium mt-2">Select a payment method</span>
            <Card className="flex p-2 gap-2">
              <PiMoneyLight className="text-green-700" size={25} />
              <div className="h-full border" />
              <span>Cash</span>
            </Card> */}
            <Button variant="outline" className="mx-auto w-[50%] min-w-[180px] ">
              Print Bill
            </Button>
            <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
              <PopoverTrigger className="w-full mx-auto" asChild>
                <Button
                  className="mx-auto w-[50%] min-w-[180px] "
                  variant="default"
                  isLoading={isLoading}
                >
                  Checkout
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="z-40 bg-white rounded-md border p-4 transition-all">
                  <p>Do you want to Generate bill?</p>
                  <div className="flex gap-2 justify-end mt-3">
                    <PopoverClose asChild>
                      <Button variant="secondary">No</Button>
                    </PopoverClose>
                    <PopoverClose asChild>
                      <Button
                        variant="default"
                        onClick={() => {
                          checkoutMutate();
                        }}
                      >
                        Yes
                      </Button>
                    </PopoverClose>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PrintBill;
