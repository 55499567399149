import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import useAxios from "@/hooks/useAxios";
import { useAuthStore } from "@/store/authStore";
import { RouterPaths } from "@/types/RouterPaths";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { parseAxiosError } from "@/utils/common";
import { toast } from "sonner";
import { useFormik } from "formik";
import * as yup from "yup";
import clsx from "clsx";
import { PasswordInput } from "@/components/ui/password-input";
import { Checkbox } from "@/components/ui/checkbox";

export function Login() {
  const { postWithoutAuth } = useAxios();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const validationSchema = yup.object({
    phoneNo: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number should be 10 digits"),
    password: yup.string().required("Password is required"),
  });

  const userFormik = useFormik({
    initialValues: {
      phoneNo: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const editedPhoneNumber = values.phoneNo.replace(/\s/g, "");
      mutate({ phoneNo: editedPhoneNumber, password: values.password });
    },
  });

  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: async ({ phoneNo, password }: { phoneNo: string; password: string }) => {
      setLoading(true);
      const response = await postWithoutAuth("/auth-pos/login", {
        phoneNo: phoneNo,
        password: password,
      });
      if (response.data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        navigate(RouterPaths.CHANGE_PASSWORD, {
          state: { phoneNo: phoneNo, session: response.data.Session },
        });
      } else if (response.data.AuthenticationResult === undefined) {
        return;
      } else {
        // Use the Auth Store for localStorage
        const { setAuthToken, setIdToken, setRefreshToken } = useAuthStore.getState();
        setAuthToken(response.data.AuthenticationResult.AccessToken);
        setIdToken(response.data.AuthenticationResult.IdToken);
        setRefreshToken(response.data.AuthenticationResult.RefreshToken);
      }
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast(errorMessage);
    },
    onSuccess: () => {
      navigate(RouterPaths.MENU);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  return (
    <div className="w-full lg:grid lg:grid-cols-2 h-screen">
      <div className="flex items-center justify-center py-12 px-2 h-screen">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <img
              src="/images/cibo_logo_primary.svg"
              alt="Logo"
              width="64"
              height="64"
              className="mx-auto"
            />
            <h1 className="text-3xl font-bold">Hello Again!</h1>
            <p className="text-balance text-muted-foreground">
              Enter your phone number below to login to your account
            </p>
          </div>
          <form onSubmit={userFormik.handleSubmit}>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="phoneNo">Phone Number</Label>
                <div className="flex items-center">
                  <span className="mr-2">+91</span>
                  <Input
                    id="phoneNo"
                    type="tel"
                    placeholder=""
                    name="phoneNo"
                    value={userFormik.values.phoneNo}
                    onChange={userFormik.handleChange}
                    required
                    className="flex-grow"
                  />
                </div>
                {userFormik.errors.phoneNo && (
                  <p className="text-red-500 text-sm">*{userFormik.errors.phoneNo}</p>
                )}
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>
                </div>
                <PasswordInput
                  id="password"
                  type="password"
                  required
                  name="password"
                  value={userFormik.values.password}
                  onChange={userFormik.handleChange}
                />
                {userFormik.errors.password && (
                  <p className="text-red-500 text-sm">*{userFormik.errors.password}</p>
                )}
                <div className="flex items-center">
                  <div className="gap-1 items-center hidden">
                    <Checkbox checked={rememberMe} onClick={() => setRememberMe(!rememberMe)} />
                    <span>Remember me</span>
                  </div>

                  <Link
                    to={RouterPaths.FORGOT_PASSWORD}
                    className="ml-auto inline-block text-sm underline"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <Button
                type="submit"
                className={clsx("w-full cursor-pointer", {
                  "opacity-50 cursor-not-allowed": !userFormik.isValid || !userFormik.dirty,
                })}
                disabled={!userFormik.isValid || !userFormik.dirty}
                isLoading={loading}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden lg:flex lg:justify-center lg:items-center rounded-l-xl h-screen">
        <img
          src="/images/login_abstract.jpg"
          alt="Image"
          className="m-3 place-items-center rounded-3xl h-[98%] w-[98%] object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  );
}
