import BreadCrumb from "@/components/common/Breadcrumb";
import Loading from "@/components/common/Loading";
import { feedbackColumns } from "@/components/feedback-page/feedback-columns";
import { Card } from "@/components/ui/card";
import { FeedbackTable } from "@/components/ui/feedback-table";
import { ReactQueryEnum } from "@/constans/enum";
import useAxios from "@/hooks/useAxios";
import { FeedbackInterface, FeedbackResponse } from "@/interfaces/Feedback.interface";
import { getFormattedDate, parseAxiosError } from "@/utils/common";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { toast } from "sonner";

export default function FeedbackPage() {
  const [data, setData] = useState<FeedbackInterface[]>([]);
  const { getWithAuth } = useAxios();
  const [totalRatings, setTotalRatings] = useState<number>(0);
  const [totalFeedback, setTotalFeedback] = useState<number>(0);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: feedbacks,
    isError: isSessionError,
    isLoading: isSessionLoading,
    error,
  } = useQuery<FeedbackResponse, AxiosError>({
    queryKey: [ReactQueryEnum.FEEDBACK, pagination.pageIndex, pagination.pageSize],
    queryFn: async () => {
      const response = await getWithAuth(
        `/app-feedback?page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
      );
      return response.data;
    },
  });

  useEffect(() => {
    if (feedbacks) {
      const feedBackData: FeedbackInterface[] = feedbacks.feedbackHistory.map(
        (feedback: FeedbackInterface, index: number) => {
          const [day, time] = getFormattedDate(feedback.createdAt.toString());
          return {
            feedbackNo: index + pagination.pageIndex * pagination.pageSize + 1,
            rating: feedback.rating ?? -1,
            description: feedback.description ?? "",
            createdAt: `${day} ${time}`,
          };
        },
      );
      setData(feedBackData);
      setTotalFeedback(feedbacks.totalFeedbacks);
      setAverageRating(feedbacks.averageRating);
      setTotalRatings(feedbacks.totalRatings);
    }
  }, [feedbacks]);

  useEffect(() => {
    if (isSessionError && error) {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    }
  }, [isSessionError]);

  if (isSessionLoading && pagination.pageIndex === 0) return <Loading />;

  return (
    <div className="p-4 md:px-6 h-full overflow-auto w-full max-w-[1440px]">
      <div className="h-full overflow-auto">
        <div className="w-full bg-secondary z-[100] my-4">
          <BreadCrumb />
          <span className="text-primary font-bold text-3xl">Feedback</span>
        </div>
        <div className="flex gap-4 pb-4">
          <Card className="p-4 ">
            <div className="flex flex-col items-center">
              <p className="text-lg">Average Ratings</p>
              <p className="text-5xl text-primary">{averageRating}</p>
            </div>
          </Card>
          <Card className="p-4 ">
            <div className="flex flex-col items-center">
              <p className="text-lg">Total Ratings</p>
              <p className="text-5xl text-primary">{totalRatings}</p>
            </div>
          </Card>
          <Card className="p-4 ">
            <div className="flex flex-col items-center">
              <p className="text-lg">Total Feedback</p>
              <p className="text-5xl text-primary">{totalFeedback}</p>
            </div>
          </Card>
        </div>
        <FeedbackTable
          columns={feedbackColumns}
          data={data}
          setPagination={setPagination}
          allowNext={totalFeedback > (pagination.pageIndex + 1) * pagination.pageSize}
          allowPrevious={pagination.pageIndex > 0}
          isLoading={isSessionLoading}
        />
      </div>
    </div>
  );
}
