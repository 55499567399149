import { OrderStatus, OrderedDishStatus, ReactQueryEnum } from "@/constans/enum";
import { getFormattedDate, parseAxiosError, timeSince } from "@/utils/common";
import clsx from "clsx";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { useState, useRef, useEffect } from "react";
import { FaArrowDown } from "react-icons/fa6";
import { motion } from "framer-motion";
import { Checkbox } from "../ui/checkbox";
import { Order } from "@/interfaces/OrderResponse.interface";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxios from "@/hooks/useAxios";
import { toast } from "sonner";
import { AxiosError } from "axios";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

interface OrderCardProps {
  tableName: string;
  order: Order;
  sessionId: string;
  callback?: () => void;
}

export default function OrderCard({ tableName, order, sessionId, callback }: OrderCardProps) {
  const timeOfDay = getFormattedDate(order.timeOrdered.toString())[1];
  const [expanded, setExpanded] = useState(false);
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const ordersRef = useRef<HTMLDivElement>(null);
  const [itemsUnavailable, setItemsUnavailable] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);

  const handleOrderStatusChange = (status: OrderStatus) => {
    changeStatusMutate({ status });
    // Handle the status update logic here (e.g., API call)
  };
  const { patchWithAuth } = useAxios();

  const [time, setTime] = useState<string>("");

  const dishes = order.dishes
    .slice() // Create a shallow copy of the dishes array to avoid mutating the original array
    .sort((a, b) => {
      if (a.status === OrderedDishStatus.REJECTED && b.status !== OrderedDishStatus.REJECTED) {
        return 1;
      } else if (
        a.status !== OrderedDishStatus.REJECTED &&
        b.status === OrderedDishStatus.REJECTED
      ) {
        return -1;
      } else {
        return 0;
      }
    });
  const [checkedState, setCheckedState] = useState<boolean[]>(dishes.map(() => false));

  const { mutate: mutateSession } = useMutation({
    mutationFn: async (removeDishArr: string[]) => {
      setLoading(true);
      const response = await patchWithAuth(`/dining-session/edit-confirmed-order`, {
        orderId: order.orderId,
        removeDishArr: removeDishArr,
        sessionId: sessionId,
      });
      return response;
    },
    onSuccess: () => {
      setCheckedState(dishes.map(() => false));
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.DINING_SESSIONS] });
      callback && callback();
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!order) return;
    const date = new Date(order.timeOrdered);

    const updateTime = () => {
      setTime(timeSince(date));
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [order]);

  const { mutate: changeStatusMutate } = useMutation({
    mutationFn: async ({ status }: { status: OrderStatus }) => {
      setLoading(true);
      const response = await patchWithAuth(`/dining-session/change-order-status`, {
        orderId: order.orderId,
        sessionId: sessionId,
        status: status,
      });
      return { response, status };
    },
    onSuccess: ({ status }: { status: OrderStatus }) => {
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.DINING_SESSIONS] });
      callback && callback();
      toast.success(`orderId ${order.orderId} status changed to ${status}`);
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (ordersRef.current) {
      const { scrollHeight, clientHeight } = ordersRef.current;
      setIsOverflowing(scrollHeight > clientHeight);
    }
  }, [order]);

  const handleCheckBoxClick = (index: number) => {
    const updatedCheckedState = checkedState.map((item, i) => (i === index ? !item : item));
    setCheckedState(updatedCheckedState);
    if (updatedCheckedState[index]) {
      setItemsUnavailable([...itemsUnavailable, dishes[index]._id]);
    } else {
      setItemsUnavailable(itemsUnavailable.filter((item) => item !== dishes[index]._id));
    }
  };
  const handleEditClick = () => {
    if (!edit) {
      // Enter edit mode
      setEdit(true);
    } else {
      if (itemsUnavailable.length !== 0) {
        // Mutate the session to mark items as unavailable
        mutateSession(itemsUnavailable);
        // Exit edit mode after mutation
      }
      // Cancel edit mode if no items are selected as unavailable
      setEdit(false);
    }
    setItemsUnavailable([]); // Reset items unavailable after each click
    setCheckedState(dishes.map(() => false)); // Reset checkbox state after each click
  };

  return (
    <Card className=" flex flex-col h-full transition-all w-full">
      <div className="flex flex-col py-4 h-full justify-between">
        <div className="flex flex-col overflow-hidden px-4">
          <div className="flex justify-between items-center">
            <span className="text-2xl font-semibold text-secondary-foreground basis-1/3">
              {tableName.toUpperCase()}
            </span>

            <Select
              value={order.orderStatus}
              onValueChange={(value) => handleOrderStatusChange(value as OrderStatus)}
            >
              <SelectTrigger
                className={clsx(
                  "flex p-2 items-center justify-center text-xs rounded-md bg-input-secondary basis-1/4 h-[90%] border-0",
                  {
                    "bg-red-100 text-red-800": order.orderStatus === OrderStatus.REJECTED,
                    "bg-yellow-100 text-yellow-800": order.orderStatus === OrderStatus.PENDING,
                    "bg-blue-100 text-blue-800": order.orderStatus === OrderStatus.CONFIRMED,
                    "bg-green-100 text-green-800": order.orderStatus === OrderStatus.SERVED,
                  },
                )}
              >
                <SelectValue placeholder="Select Order Status" />
              </SelectTrigger>
              <SelectContent className="z-[909999]">
                <SelectItem value={OrderStatus.REJECTED}>Rejected</SelectItem>
                {order.orderStatus === OrderStatus.CONFIRMED && (
                  <SelectItem value={OrderStatus.CONFIRMED}>Confirmed</SelectItem>
                )}
                {(order.orderStatus === OrderStatus.CONFIRMED ||
                  order.orderStatus === OrderStatus.SERVED) && (
                  <SelectItem value={OrderStatus.SERVED}>Served</SelectItem>
                )}
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-between items-end pb-2">
            <div>
              <span className="text-sm font-medium">Order #{order.orderId}</span>
              {/* <span className="text-sm text-tertiary"> • Dine in</span> */}
            </div>
            <div>
              {/* <span className="text-xs text-tertiary">{`${day} `}</span> */}
              <span className="text-xs text-primary font-semibold">{time}</span>
              <span className="text-xs text-tertiary">{` • ${timeOfDay}`}</span>
            </div>
          </div>
        </div>
        <div className="px-4">
          <hr />
        </div>
        <div className="flex flex-col pb-3 ">
          <div className="flex text-secondary-foreground font-bold px-4 pb-1">
            <div className="basis-3/5">Item</div>
            <div className="basis-1/5 flex justify-center">Qty</div>
            <div className="basis-1/5 flex justify-center">Amount</div>
          </div>
          <div className="relative">
            <motion.div
              ref={ordersRef}
              initial={{ height: "22vh" }}
              animate={{ height: expanded ? "auto" : "22vh" }}
              transition={{ duration: 0.3 }}
              className={clsx("flex flex-col overflow-y-auto pl-4 pr-2", {
                "bg-gray-200 rounded-lg animate-breathing ml-4 mr-2": isLoading,
              })}
            >
              {dishes
                .slice() // Create a shallow copy of the dishes array to avoid mutating the original array
                .sort((a, b) => {
                  if (
                    a.status === OrderedDishStatus.REJECTED &&
                    b.status !== OrderedDishStatus.REJECTED
                  ) {
                    return 1;
                  } else if (
                    a.status !== OrderedDishStatus.REJECTED &&
                    b.status === OrderedDishStatus.REJECTED
                  ) {
                    return -1;
                  } else {
                    return 0;
                  }
                })
                .map((dish, index) => (
                  <div
                    className={clsx("flex text-sem", {
                      "text-red-500": dish.status === OrderedDishStatus.REJECTED,
                    })}
                    key={index}
                  >
                    {!isLoading && (
                      <div className="flex basis-3/5 items-start gap-1">
                        {edit && dish.status !== OrderedDishStatus.REJECTED && (
                          <Checkbox className="mt-1" onClick={() => handleCheckBoxClick(index)} />
                        )}
                        {edit && dish.status === OrderedDishStatus.REJECTED && (
                          <Checkbox className="mt-1" disabled />
                        )}
                        <div className="flex flex-col">
                          <span>{dish.name}</span>
                          <div className="text-xs">
                            <span
                              className={clsx("font-medium", {
                                "text-red-500": dish.status === OrderedDishStatus.REJECTED,
                                "text-secondary-foreground":
                                  dish.status !== OrderedDishStatus.REJECTED,
                              })}
                            >
                              {!!dish.customisations?.size &&
                                `${dish.customisations?.size.size}${dish.customisations.ingredients ? " • " : ""}`}
                            </span>
                            <span
                              className={clsx("", {
                                "text-red-500": dish.status === OrderedDishStatus.REJECTED,
                                "text-tertiary": dish.status !== OrderedDishStatus.REJECTED,
                              })}
                            >
                              {!!dish.customisations?.ingredients &&
                                dish?.customisations.ingredients
                                  .map((item) => item.ingredient)
                                  .join(" • ")}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {!isLoading && (
                      <>
                        <div
                          className={clsx("basis-1/5 flex justify-center", {
                            "line-through": dish.status === OrderedDishStatus.REJECTED,
                          })}
                        >
                          {dish.quantity}
                        </div>
                        <div
                          className={clsx("basis-1/5 flex justify-center", {
                            "line-through": dish.status === OrderedDishStatus.REJECTED,
                          })}
                        >
                          ₹{dish.amount}
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </motion.div>

            {isOverflowing && (
              <motion.div
                initial={{
                  rotate: expanded ? 180 : 0,
                }}
                animate={{
                  rotate: expanded ? 180 : 0,
                }}
                transition={{ duration: 0.5 }}
                onClick={() => setExpanded(!expanded)}
                className="absolute flex items-center justify-center left-[45%] transform -translate-x-1/2 rounded-full w-8 h-8 bg-background border border-1 border-b-secondary shadow-md hover:bg-secondary transition-all cursor-pointer"
              >
                <FaArrowDown className="text-secondary-foreground" />
              </motion.div>
            )}
          </div>
        </div>
        <div className="px-4">
          <hr />
        </div>

        <div className="flex items-end justify-between px-4 pt-2 ">
          <Button
            variant="secondary"
            onClick={handleEditClick}
            className="min-w-[10vw]"
            disabled={isLoading}
          >{`${(!edit && "Edit") || (edit && itemsUnavailable.length !== 0 ? "Unavailable" : "Cancel Edit")}`}</Button>
          {order.orderStatus === OrderStatus.CONFIRMED && (
            <Button disabled={edit || isLoading}>Print Kot</Button>
          )}
        </div>
      </div>
    </Card>
  );
}
