import { ArrowUpDown } from "lucide-react";
import { Button } from "../ui/button";

export const feedbackColumns = [
  {
    accessorKey: "feedbackNo",
    header: ({ column }: { column: any }) => (
      <div className="flex justify-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Feedback No
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      </div>
    ),
  },
  {
    accessorKey: "rating",
    cell: ({ row }: { row: any }) => {
      const rating = row.original.rating; // Accessing the rating value from the row data
      return rating === -1 ? "-" : rating.toString();
    },
    header: ({ column }: { column: any }) => (
      <div className="flex justify-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Ratings
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      </div>
    ),
  },
  {
    accessorKey: "description",
    cell: ({ row }: { row: any }) => {
      const description: string = row.original.description; // Accessing the rating value from the row data
      return description.length === 0 ? "-" : description;
    },
    header: "Description",
  },
  {
    accessorKey: "createdAt",
    header: ({ column }: { column: any }) => (
      <div className="flex justify-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Time
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      </div>
    ),
  },
];
