import BreadCrumb from "@/components/common/Breadcrumb";
import { Card } from "@/components/ui/card";
import { useRaisedShadow } from "@/hooks/use-raised-shadow";
import { Reorder, useDragControls, useMotionValue } from "framer-motion";
import React, { useEffect, useState } from "react";
import { IoReorderFour } from "react-icons/io5";
import Question from "@/components/common/Question";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { RouterPaths } from "@/types/RouterPaths";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useAxios from "@/hooks/useAxios";
import { toast } from "sonner";
import { capitalizeFirstLetter, parseAxiosError } from "@/utils/common";
import { AxiosError } from "axios";
import { ReactQueryEnum } from "@/constans/enum";
import { RestaurantCategory } from "@/interfaces/RestaurantCategory.interface";
import Loading from "@/components/common/Loading";

function Item({ id, name }: { id: string; name: string }) {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();
  const [categoryName, setCategoryName] = useState<string>(name);
  const [loading, setLoading] = useState(false);
  const { patchWithAuth, deleteWithAuth } = useAxios();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { mutate: deleteCategoryMutate } = useMutation({
    mutationFn: async () => {
      setLoading(true);

      const response = await deleteWithAuth(`/restaurant-category/delete/${id}`);
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast(errorMessage);
    },
    onSuccess: () => {
      toast.success(`${name} category deleted successfully`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.RESTAURANT_CATEGORIES] });
      setDeleteDialogOpen(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate } = useMutation({
    mutationFn: async ({ categoryName }: { categoryName: string }) => {
      setLoading(true);
      const response = await patchWithAuth("/restaurant-category/rename", {
        categoryName,
        categoryId: id,
      });
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast(errorMessage);
    },
    onSuccess: () => {
      toast.success(`Category ${name} renamed to ${categoryName} added successfully`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.RESTAURANT_CATEGORIES] });
      setOpen(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  return (
    <Reorder.Item
      value={id}
      id={id}
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
      className="rounded-xl"
    >
      <Card className="mb-1.5 grid grid-cols-[20px_7fr_3fr] px-4 py-2 gap-3 justify-start items-center select-none">
        <IoReorderFour
          className="h-5 w-5 text-gray-400 cursor-grab"
          onPointerDown={(event) => dragControls.start(event)}
        />
        <p>{name}</p>
        <div className="flex gap-2 justify-self-end">
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger className="">Edit</DialogTrigger>
            <DialogContent className=" ">
              <DialogHeader>
                <DialogTitle className="mb-2">Edit Category Name</DialogTitle>
                <DialogDescription className="py-4">
                  <Input
                    placeholder="Category Name"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </DialogDescription>
                <DialogFooter>
                  <Button variant="ghost">Cancel</Button>
                  <Button
                    variant="default"
                    onClick={() => {
                      mutate({ categoryName });
                    }}
                    isLoading={loading}
                  >
                    Save
                  </Button>
                </DialogFooter>
              </DialogHeader>
            </DialogContent>
          </Dialog>
          <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
            <DialogTrigger className="text-red-600">Delete</DialogTrigger>
            <DialogContent className=" ">
              <DialogHeader>
                <DialogTitle className=" ">Delete category?</DialogTitle>
                <DialogDescription className="pb-4">
                  <p>Are you sure you want to delete {name} category?</p>
                </DialogDescription>
                <DialogFooter>
                  <Button variant="ghost">Cancel</Button>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      deleteCategoryMutate();
                    }}
                  >
                    Delete
                  </Button>
                </DialogFooter>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      </Card>
    </Reorder.Item>
  );
}

function AddNewCategory() {
  const [loading, setLoading] = useState(false);
  const { postWithAuth } = useAxios();
  const [categoryName, setCategoryName] = useState("");
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async ({ categoryName }: { categoryName: string }) => {
      setLoading(true);
      const response = await postWithAuth("restaurant-category/create", {
        categoryName,
      });
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast(errorMessage);
    },
    onSuccess: () => {
      toast.success(`Category ${categoryName} added successfully`);
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.RESTAURANT_CATEGORIES] });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleCancel = () => {
    setOpen(false); // Close the dialog when Cancel is clicked
  };

  const handleSave = () => {
    mutate({ categoryName });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="px-3 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90">
        Add Category
      </DialogTrigger>
      <DialogContent className=" ">
        <DialogHeader>
          <DialogTitle className="mb-2">Add New Category</DialogTitle>
          <DialogDescription className="py-4">
            <Input
              placeholder="Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </DialogDescription>
          <DialogFooter>
            <Button variant="ghost" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="default" onClick={handleSave} isLoading={loading}>
              Save
            </Button>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

const EditCategory = () => {
  const [restaurantCategoryMap, setRestaurantCategoryMap] = useState<{ [key: string]: string }>({});
  const [items, setItems] = useState<string[]>([]);
  const {
    data: restaurantCategories,
    isError: isSessionError,
    isLoading: isSessionLoading,
    error,
  } = useQuery<RestaurantCategory[], AxiosError>({
    queryKey: [ReactQueryEnum.RESTAURANT_CATEGORIES],
    queryFn: async () => {
      const response = await getWithAuth("/restaurant-category/categories");
      return response.data;
    },
  });

  if (isSessionError && error) {
    const { errorMessage } = parseAxiosError(error);
    toast.error(errorMessage);
  }

  useEffect(() => {
    if (restaurantCategories) {
      const categoryMap = Object.fromEntries(
        restaurantCategories.map((category) => [category._id, category.categoryName]),
      );
      setRestaurantCategoryMap(categoryMap);
      setItems(Object.keys(categoryMap));
    }
  }, [restaurantCategories]);

  const [loading, setLoading] = useState(false);
  const { patchWithAuth, getWithAuth } = useAxios();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async () => {
      setLoading(true);
      const data = {
        categories: items.map((id, index) => {
          return { categoryId: id, rank: index + 1 };
        }),
      };
      const response = await patchWithAuth("/restaurant-category/arrange", data);
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      toast(errorMessage);
    },
    onSuccess: () => {
      toast.success("Categories arranged successfully");
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.RESTAURANT_CATEGORIES] });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleReorder = () => {
    mutate();
  };

  if (isSessionLoading) return <Loading />;

  return (
    <div className="p-4 md:px-6 flex flex-col items-start w-full bg-secondary">
      <div className="w-full bg-secondary my-4">
        <Link to={RouterPaths.MENU}>
          <IoChevronBackSharp className="mb-4" />
        </Link>
        <BreadCrumb />
        <div className="flex w-full justify-between item mt-4">
          <span className="text-primary font-bold text-3xl">Category</span>
          <AddNewCategory />
        </div>
      </div>
      <div className="flex gap-1 justify-start items-center mb-2">
        <p className="text-lg font-medium">Rearrange the categories</p>
        <Question info="Rearranging the categories will allow you to move them up or down. The consumer app will show the order of these categories." />
      </div>
      <Reorder.Group axis="y" onReorder={setItems} values={items} className="w-full max-w-[600px]">
        {items.map((id) => (
          <Item key={id} id={id} name={capitalizeFirstLetter(restaurantCategoryMap[id])} />
        ))}
      </Reorder.Group>
      <Button className="mt-2" onClick={handleReorder} isLoading={loading}>
        {" "}
        Save Categories
      </Button>
    </div>
  );
};

export default EditCategory;
