import React from "react";
import { MdOutlineQuestionMark } from "react-icons/md";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";

const Question = ({ info }: { info: string }) => {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div className="p-1 rounded-full bg-primary/20 hover:cursor-pointer">
          <MdOutlineQuestionMark className="text-sm" />
        </div>
      </HoverCardTrigger>
      <HoverCardContent className="w-[30vw]">
        <div className="flex justify-between space-x-4">
          <p className="text-sm">{info}</p>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

export default Question;
