import { Card } from "@/components/ui/card";
import React, { Suspense, useState } from "react";
import { Link } from "react-router-dom";

import BreadCrumb from "@/components/common/Breadcrumb";
import { settingsItems } from "@/constans/constants";
import Loading from "@/components/common/Loading";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const SelectedComponent = settingsItems[selectedTab].component;

  return (
    <main className=" flex flex-1  flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:px-6 md:mp-4 md:h-full md:overflow-hidden">
      <div className=" w-full bg-secondary ">
        <BreadCrumb />
        <span className="text-primary font-bold text-3xl">Settings</span>
      </div>
      <div className=" grid w-full h-full max-w-6xl items-start gap-3 md:grid-cols-[1fr_5fr] overflow-auto">
        <Card
          className="p-3 rounded-md grid gap-4  text-muted-foreground"
          x-chunk="dashboard-04-chunk-0"
        >
          {settingsItems.map((item, index) => (
            <Link
              className={selectedTab === index ? "font-semibold text-primary" : ""}
              key={index}
              to="#"
              onClick={() => {
                setSelectedTab(index);
              }}
            >
              {item.text}
            </Link>
          ))}
        </Card>

        <Card className="p-3 w-full md:h-full md:overflow-auto rounded-md">
          <Suspense fallback={<Loading />}>
            <SelectedComponent />
          </Suspense>
        </Card>
      </div>
    </main>
  );
};

export default Settings;
