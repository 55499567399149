import React from "react";
import { RxSlash } from "react-icons/rx";
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from "../ui/breadcrumb";
import { Link, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "@/utils/common";

const BreadCrumb = () => {
  const location = useLocation();
  const path = location.pathname.split("/").slice(1);
  return (
    <Breadcrumb>
      <BreadcrumbList className="!gap-0.5">
        <BreadcrumbItem>
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        {path.map((item, index) => {
          const fullPath = path.slice(0, index + 1).join("/");
          const itemCaps = item
            .split("-")
            .map((word) => capitalizeFirstLetter(word))
            .join(" ");
          return (
            <div className="flex justify-start items-center gap-0.5" key={index}>
              <BreadcrumbSeparator>
                <RxSlash />
              </BreadcrumbSeparator>
              <BreadcrumbItem>
                <Link to={`/${fullPath}`}>{itemCaps}</Link>
              </BreadcrumbItem>
            </div>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default BreadCrumb;
