import { capitalizeFirstLetter } from "@/utils/common";
import clsx from "clsx";

interface CategoryCardProps {
  className?: string;
  name: string;
  isActive: boolean;
  onClick: () => void;
}

export default function CategoryCard({ className, name, isActive, onClick }: CategoryCardProps) {
  return (
    <div
      className={clsx(
        "flex items-center justify-center font-medium p-2 w-full rounded-md hover:cursor-pointer transition-all",
        {
          "bg-background text-secondary-foreground hover:bg-red-100": !isActive,
          "text-red-50 bg-red-800": isActive,
        },
        className,
      )}
      onClick={onClick}
    >
      <p className="text-sm  whitespace-nowrap text-ellipsis overflow-hidden">
        {capitalizeFirstLetter(name)}
      </p>
    </div>
  );
}
