import { NotificationType } from "@/constans/enum";
import { formatTimeAgo } from "@/utils/common";
import { IoReceipt } from "react-icons/io5";
import { MdPerson } from "react-icons/md";
import { PiCookingPotFill } from "react-icons/pi";

interface NotificationTileProps {
  tableName: string;
  type: NotificationType;
  time: Date;
}

export default function NotificationTile({ tableName, type, time }: NotificationTileProps) {
  const timeAgo = formatTimeAgo(time);
  return (
    <div className="w-full ">
      <div className="p-3 flex gap-2 items-center">
        <div className="rounded-md border-2 p-1.5 border-blue-5">{tableName}</div>
        {type === NotificationType.CALL_SERVER && <MdPerson size={25} className="text-tertiary" />}
        {type === NotificationType.PLACE_ORDER && (
          <PiCookingPotFill size={25} className="text-primary" />
        )}
        {type === NotificationType.BILL_REQUESTED && (
          <IoReceipt size={20} className="text-blue-600" />
        )}
        {type === NotificationType.CALL_SERVER && (
          <span className="text-gray-500">Server Called</span>
        )}
        {type === NotificationType.PLACE_ORDER && (
          <span className="text-gray-500">Order Placed</span>
        )}
        {type === NotificationType.BILL_REQUESTED && (
          <span className="text-gray-500">Bill Requested</span>
        )}
        <div className="text-xs text-gray-500">{timeAgo}</div>
      </div>
      <hr />
    </div>
  );
}
