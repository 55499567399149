import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import React, { useEffect, useState } from "react";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Button } from "./button";
import { FeedbackInterface } from "@/interfaces/Feedback.interface";
import { Card } from "./card";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  setPagination: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  allowPrevious: boolean;
  allowNext: boolean;
  isLoading: boolean;
}

export function FeedbackTable<TData, TValue>({
  columns,
  data,
  setPagination,
  allowPrevious,
  allowNext,
  isLoading,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [feedbackData, setFeedbackData] = useState<FeedbackInterface>();
  const [clickedIndex, setClickedIndex] = useState<number>(-1);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  const [nextLoading, setNextLoading] = useState(false);
  const [prevLoading, setPrevLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setPrevLoading(false);
      setNextLoading(false);
    }
  }, [isLoading]);

  const handlePreviousClick = () => {
    setPrevLoading(true);
    setNextLoading(false); // Reset next loading state
    setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
  };

  const handleNextClick = () => {
    setNextLoading(true);
    setPrevLoading(false); // Reset previous loading state
    setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
  };

  return (
    <div className="rounded-md border bg-background w-full">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className="text-center">
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, index) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="text-center cursor-pointer"
                onClick={() => {
                  if (data) {
                    setFeedbackData(row.original as FeedbackInterface);
                  }

                  setSheetOpen(true);
                  setClickedIndex(index);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4 px-2">
        <Button
          variant="default"
          size="sm"
          onClick={handlePreviousClick}
          disabled={!allowPrevious}
          isLoading={isLoading && prevLoading}
        >
          Previous
        </Button>
        <Button
          variant="default"
          size="sm"
          onClick={handleNextClick}
          disabled={!allowNext}
          isLoading={isLoading && nextLoading}
        >
          Next
        </Button>
      </div>
      <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Feedback Details</SheetTitle>
          </SheetHeader>
          <div className="">
            {feedbackData && (
              <div className="flex flex-col p-4">
                {clickedIndex !== -1 && (
                  <div className="flex flex-col gap-2">
                    {feedbackData.description && (
                      <Card className="p-4">{feedbackData.description}</Card>
                    )}
                    {feedbackData.rating && (
                      <Card className="p-4">
                        Rating : <span className="font-semibold">{feedbackData.rating}</span>
                      </Card>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
}
