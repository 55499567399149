import { billColumns } from "@/components/bill-page/bill-columns";
import BreadCrumb from "@/components/common/Breadcrumb";
import Loading from "@/components/common/Loading";
import { BillDataTable } from "@/components/ui/bill-data-table";
import { ReactQueryEnum } from "@/constans/enum";
import useAxios from "@/hooks/useAxios";
import { BillHistoryResponse, BillResponse } from "@/interfaces/Bill.interface";
import { capitalizeFirstLetter, getFormattedDate, parseAxiosError } from "@/utils/common";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { toast } from "sonner";

export interface BillData {
  billNo: string;
  customer: string;
  tableName: string;
  billCreationTime: string;
  total: number;
}

export default function BillPage() {
  const [data, setData] = useState<BillData[]>([]);
  const { getWithAuth } = useAxios();
  const [totalBills, setTotalBills] = useState<number>(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: bills,
    isError: isSessionError,
    isLoading: isSessionLoading,
    error,
  } = useQuery<BillHistoryResponse, AxiosError>({
    queryKey: [ReactQueryEnum.BILL_HISTORY, pagination.pageIndex, pagination.pageSize],
    queryFn: async () => {
      const response = await getWithAuth(
        `/bill/bill-history?page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
      );
      return response.data;
    },
  });

  useEffect(() => {
    if (isSessionError && error) {
      const { errorMessage } = parseAxiosError(error);
      toast.error(errorMessage);
    }
  }, [isSessionError]);

  useEffect(() => {
    if (bills) {
      const billData: BillData[] = bills.billHistory.map((bill: BillResponse) => {
        const [day, time] = getFormattedDate(bill.billCreationTime.toString());
        return {
          billNo: bill.billNo.toString(),
          customer: capitalizeFirstLetter(bill.captainName),
          tableName: bill.tableName,
          billCreationTime: `${day} ${time}`,
          total: Math.round(bill.total),
        };
      });
      setData(billData);
      setTotalBills(bills.totalBills);
    }
  }, [bills]);

  if (isSessionLoading && pagination.pageIndex === 0) return <Loading />;
  // if (isSessionLoading) return <Loading />;
  return (
    <div className="p-4 md:px-6 h-full overflow-auto w-full max-w-[1440px]">
      <div className="h-full overflow-auto">
        <div className="w-full bg-secondary z-[100] my-4">
          <BreadCrumb />
          <span className="text-primary font-bold text-3xl">Bills</span>
        </div>

        <BillDataTable
          columns={billColumns}
          data={data}
          bills={bills ? bills : { billHistory: [], totalBills: 0 }}
          setPagination={setPagination}
          allowNext={totalBills > (pagination.pageIndex + 1) * pagination.pageSize}
          allowPrevious={pagination.pageIndex > 0}
          isLoading={isSessionLoading}
        />
      </div>
    </div>
  );
}
