export const cuisine_list = [
  { value: "american", label: "American" },
  { value: "asian", label: "Asian" },
  { value: "bistro", label: "Bistro" },
  { value: "buffet", label: "Buffet" },
  { value: "cafe", label: "Cafe" },
  { value: "caribbean", label: "Caribbean" },
  { value: "casual", label: "Casual" },
  { value: "casual_dining", label: "Casual dining" },
  { value: "chinese", label: "Chinese" },
  { value: "diner", label: "Diner" },
  { value: "fast_food", label: "Fast food" },
  { value: "food_trucks", label: "Food trucks" },
  { value: "french", label: "French" },
  { value: "greek", label: "Greek" },
  { value: "greek_cuisine", label: "Greek cuisine" },
  { value: "indian", label: "Indian" },
  { value: "italian", label: "Italian" },
  { value: "japanese", label: "Japanese" },
  { value: "lebanese", label: "Lebanese" },
  { value: "mexican", label: "Mexican" },
  { value: "pub", label: "Pub" },
  { value: "spanish", label: "Spanish" },
  { value: "south_indian", label: "South Indian" },
  { value: "thai", label: "Thai" },
];
