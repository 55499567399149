import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();
import * as Sentry from "@sentry/react";

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: "https://dd0016d8cb939d012f83f85ac5d3b6ce@o4507903940624384.ingest.us.sentry.io/4507909632163840",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 0.6,
  tracePropagationTargets: [
    "http://localhost:3000",
    "https://beta.api.ciboeats.in", // Beta API URL
    "https://api.ciboeats.in", // Production API URL
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.MODE !== "development",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);
