import { DishType } from "@/constans/enum";

export enum FoodType {
  VEG = "veg",
  NON_VEG = "non-veg",
  EGG = "egg",
}

export interface OrderInterface {
  dishId: string;
  quantity: number;
  customisations: {
    size: string;
    ingredients: string[];
  };
}
export interface SizeType {
  size: string;
  price: number;
  sizeId: string;
}

export interface IngredientType {
  ingredient: string;
  extraPrice: number;
  ingredientId: string;
}
export interface CustomisationType {
  size?: SizeType;
  ingredients?: Array<IngredientType>;
}

export interface CartInterface {
  dishId: string;
  userName: string;
  dishName: string;
  quantity: number;
  price: number;
  type: DishType;
  uuid: string;
  customisation?: CustomisationType;
}
