import { ArrowUpDown } from "lucide-react";
import { Button } from "../ui/button";

export const billColumns = [
  {
    accessorKey: "billNo",
    header: ({ column }: { column: any }) => (
      <div className="flex justify-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Bill No
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      </div>
    ),
  },
  {
    accessorKey: "tableName",
    header: ({ column }: { column: any }) => (
      <div className="flex justify-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Table No
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      </div>
    ),
  },
  {
    accessorKey: "customer",
    header: "Customer",
  },
  {
    accessorKey: "billCreationTime",
    header: ({ column }: { column: any }) => (
      <div className="flex justify-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Time
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      </div>
    ),
  },
  {
    accessorKey: "total",
    header: "Total",
    cell: ({ cell }: { cell: any }) => (
      <div className="flex justify-center font-medium">₹ {cell.getValue().toFixed(2)}</div>
    ),
  },
];
